import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { Box, Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { DateRangePicker, InlineStyledText } from '../../../../../../../../../../components';
import { staticText } from '../../../../../../../../../../localization/Application/Logs/filtersBar';
import dateUtils from '../../../../../../../Overview/utils/dateUtils';

const DateRangeExportModal = ({
  toggleModal,
  handleSubmit,
  email,
  logRestrictions,
}) => {
  const theme = useTheme();

  const { maxDate } = logRestrictions;
  const minDate = dateUtils.subtract(maxDate, 365, 'days').toISOString();
  const [dateRange, setDateRange] = useState([null, null]);
  const [modalView, setModalView] = useState(0);

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const getReadableDateRange = () => {
    return `${dateRange[0].format('MMM DD, YYYY')} - ${dateRange[1].format('MMM DD, YYYY')}`;
  };

  return (
    <Dialog open onClose={toggleModal}>
      <DialogContent>
        <form>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginBottom={2}
            sx={{
              position: 'absolute',
              top: theme.spacing(3),
              right: 0,
            }}
          >
            <Button
              onClick={toggleModal}
              id="add-redirect-uri-modal-cancel"
              type="button"
              sx={{ padding: 0, svg: { fill: theme.palette.common.black } }}
            ><CloseRoundedIcon fontSize="small" color={theme.palette.common.black} />
            </Button>
          </Box>
          <Typography variant="h2" marginBottom={3}>{staticText.exportModal.title}</Typography>
          {modalView === 0 ? (
            <div>
              <Typography mb={3}>{staticText.exportModal.description}</Typography>
              <DateRangePicker
                label={staticText.conDate}
                onAccept={handleDateRangeChange}
                value={dateRange}
                minDate={minDate}
                maxDate={maxDate}
                fullWidth
                showIcon
                hideActionBar
              />
              <Box display="flex" justifyContent="flex-end" marginTop={4} gap={1}>
                <Button
                  variant="outlined"
                  onClick={toggleModal}
                  id="log-export-modal-cancel"
                >{staticText.exportModal.cancel}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setModalView(1)}
                  id="log-export-modal-next"
                  disabled={!dateRange[0] || !dateRange[1]}
                >{staticText.exportModal.next}
                </Button>
              </Box>
            </div>
          ) : (
            <div>
              <InlineStyledText
                text={staticText.exportModal.confirmation(getReadableDateRange(), email)}
              />
              <Box display="flex" justifyContent="flex-end" marginTop={4} gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => setModalView(0)}
                  id="log-export-modal-back"
                >{staticText.exportModal.back}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSubmit(dateRange)}
                  id="log-export-modal-submit"
                  type="submit"
                >{staticText.exportModal.submit}
                </Button>
              </Box>
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>);
};

export default DateRangeExportModal;

DateRangeExportModal.propTypes = {
  toggleModal: func.isRequired,
  handleSubmit: func.isRequired,
  email: string.isRequired,
  logRestrictions: {
    minDate: string.isRequired,
    maxDate: string.isRequired,
  }.isRequired,
};
