import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Select, MenuItem, useTheme } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import AddRedirectUriModal from '../AddRedirectUriModal/AddRedirectUriModal';
import { SelectIcon } from '../../../../../../../../components';
import { URI_LIMIT } from '../../../../../Configuration/components/RedirectUriList/components/RedirectUriForm/RedirectUriForm';

import { RedirectUriModalButton, getSelectMenuProps } from './styles';
import staticText from '../../../../../../../../localization/Application/connect-playground';

const RedirectUriSelect = ({
  applicationId,
  selectedRedirectUri,
  appRedirectUris,
  handleChange,
  updateApplication,
  disableAddRedirectUri,
}) => {
  const theme = useTheme();
  const { redirectUri: redirectUriText } = staticText.form.basic;

  const [canAddRedirectUri, setCanAddRedirectUri] = useState(
    appRedirectUris.length < URI_LIMIT && !disableAddRedirectUri,
  );

  // We need to track these separately from the passed redirect URIs, otherwise
  // when adding a redirect URI through the form, the MuiSelect will throw an error
  // saying the new value we're trying to set does not match any of the existing options
  const [renderedRedirectUris, setRenderedRedirectUris] = useState(appRedirectUris);

  const [redirectUriModalOpen, setRedirectUriModalOpen] = useState(false);
  const [redirectUriListWidth, setRedirectUriListWidth] = useState(0);

  const addRedirectUri = (uri) => {
    const updatedUris = _.concat(appRedirectUris, uri);
    updateApplication(applicationId, { redirectUris: updatedUris });

    handleChange({ param: 'redirectUri', value: uri });
    setRenderedRedirectUris([...appRedirectUris, uri]);

    reportToSegment(types.TRACK, eventNames.formSubmitted, { label: '[connect playground] redirect uri form', form_content: { redirectUri: uri } });
  };

  const toggleModal = () => {
    reportToSegment(types.TRACK, eventNames[`modal${redirectUriModalOpen ? 'Closed' : 'Opened'}`], {
      label: 'connect url builder - add redirect uri',
      title: redirectUriText.modal.title,
    });
    setRedirectUriModalOpen(!redirectUriModalOpen);
  };

  useEffect(() => {
    setCanAddRedirectUri(appRedirectUris.length < URI_LIMIT && !disableAddRedirectUri);
  }, [appRedirectUris]);

  useEffect(() => {
    const uriSelectElement = document.getElementById('redirect-uri-select');
    setRedirectUriListWidth(uriSelectElement.offsetWidth - 3);
  }, []);

  return (
    <React.Fragment>
      {redirectUriModalOpen && (
        <AddRedirectUriModal
          clientId={applicationId}
          redirectUris={appRedirectUris}
          toggleModal={toggleModal}
          onSubmit={addRedirectUri}
        />
      )}
      <Select
        id="redirect-uri-select"
        name="redirectUri"
        value={selectedRedirectUri || 'none'}
        onChange={e => handleChange({ param: 'redirectUri', value: e.target.value })}
        IconComponent={SelectIcon}
        MenuProps={getSelectMenuProps(theme)}
      >
        <MenuItem value="none" sx={{ display: 'none' }}>
          {redirectUriText.placeholder}
        </MenuItem>
        {renderedRedirectUris.map(uri => (
          <MenuItem
            key={uri}
            value={uri}
            sx={{ width: `${redirectUriListWidth}px` }}
          >
            {uri}
          </MenuItem>
        ))}
        {canAddRedirectUri && (
          <MenuItem sx={{ borderTop: `1px solid ${theme.palette.primary.main}` }}>
            <RedirectUriModalButton
              id="playground-add-redirect-uri"
              onClick={toggleModal}
            >
              <AddRoundedIcon fontSize="small" />
              {redirectUriText.add}
            </RedirectUriModalButton>
          </MenuItem>
        )}
      </Select>
    </React.Fragment>
  );
};

export default RedirectUriSelect;

RedirectUriSelect.propTypes = {
  selectedRedirectUri: string.isRequired,
  appRedirectUris: arrayOf(string).isRequired,
  handleChange: func.isRequired,
  applicationId: string.isRequired,
  updateApplication: func.isRequired,
  disableAddRedirectUri: bool.isRequired,
};
