import { styled } from '@mui/material/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

export const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    height: '2.625rem',
    color: theme.palette.text.primary,
    border: 'none',
  },

  '& input': {
    padding: theme.spacing(1, 2),
  },
  '& label': {
    top: '-6px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
  '& fieldset': {
    borderColor: theme.palette.grey[200],
    transition: 'border 0.25s ease',
  },
}));

export const CalendarIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: '55%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
}));
