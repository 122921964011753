import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { isEqual } from 'lodash';
import { Box, Button, useTheme } from '@mui/material';

import { DateRangePicker } from '../../../../../../components';
import { TextField } from '../../../../../../global-styles/components';
import { FiltersContainer } from './styles';

import staticText, { inputConfig } from '../../../../../../localization/Application/ConnectInsights/filtersBar';
import { getLogRestrictions } from '../../../../utils';
import FEATURES from '../../../../../../services/featureGate/features';
import dateUtils from '../../../Overview/utils/dateUtils';
import { initialState } from '../../reducers';

const FiltersBar = ({
  featureSetId,
  filterValues,
  handleApplyFilters,
  handleResetAll,
}) => {
  const theme = useTheme();
  const [formState, setFormState] = useState(filterValues);

  const logRestrictions = getLogRestrictions(featureSetId, FEATURES.CONNECT_INSIGHTS_DAYS);

  const getHandleChange = filterKey => (e) => {
    const changedValues = {};
    if (filterKey === 'dateRange') {
      const [start, end] = e;
      changedValues.start = start ? new Date(start).toISOString() : null;
      changedValues.end = end ? dateUtils.getEndOfDayUtc(end).toISOString() : null;
    } else {
      changedValues[filterKey] = e.target.value;
    }

    setFormState({
      ...formState,
      ...changedValues,
    });
  };

  const handleResetButton = () => {
    setFormState(initialState.filterValues);
    handleResetAll();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleApplyFilters(formState);
  };

  return (
    <FiltersContainer onSubmit={handleSubmit} >
      <Box display="flex" gap={theme.spacing(1)} flexGrow={0.70}>
        {inputConfig.inputs.map(input => (
          <TextField
            key={input.id}
            id={input.id}
            label={input.label}
            onChange={getHandleChange(input.filterKey)}
            value={formState[input.filterKey]}
            variant="outlined"
            sx={{ width: '100%', ...(input.filterKey === 'vin' && { maxWidth: '250px' }) }}
          />
      ))}
        <DateRangePicker
          label={inputConfig.dateRange.label}
          minDate={logRestrictions.minDate}
          maxDate={logRestrictions.maxDate}
          onChange={getHandleChange('dateRange')}
          value={[formState.start, formState.end]}
          popupMessage={staticText.logDaysAllowance(logRestrictions.logDays)}
        />
      </Box>
      <Box display="flex">
        <Button
          type="button"
          variant="text"
          sx={{
            color: theme.palette.grey[600],
            margin: theme.spacing(0, 1),
            '&:hover': { backgroundColor: 'transparent' },
          }}
          onClick={handleResetButton}
        >
          {staticText.resetAll}
        </Button>
        <Button
          color="primary"
          disabled={isEqual(formState, filterValues)}
          type="submit"
          variant="contained"
          sx={{ padding: theme.spacing(1, 2) }}
        >
          {staticText.apply}
        </Button>

      </Box>
    </FiltersContainer>
  );
};

export default FiltersBar;

FiltersBar.propTypes = {
  featureSetId: string.isRequired,
  filterValues: shape({
    vin: string,
    customerVehicleOwnerId: string,
    sessionId: string,
    start: string,
    end: string,
  }).isRequired,
  handleApplyFilters: func.isRequired,
  handleResetAll: func.isRequired,
};
