import api from '../../../../../../services/api/api';

export const getCountrySelectorMenuOptions = async (allowedCountries) => {
  const { data: countryList } = await api.fetchSupportedCountries();

  const result = countryList
    .filter(country => allowedCountries.includes(country.code))
    .map(country => ({
      code: country.code,
      displayName: country.displayName,
      region: country.region,
    }));


  return result;
};

/**
 * Get supported countries grouped by region.
 * @param {Array} supportedCountries - List of supported country codes.
 * @returns {Promise<Object>} Countries grouped by region.
 */
export const getSupportedCountriesByRegion = async (supportedCountries) => {
  const countriesByRegion = {};
  const countrySelectorMenuOptions = await getCountrySelectorMenuOptions(supportedCountries);


  countrySelectorMenuOptions.forEach((country) => {
    if (countriesByRegion[country.region]) {
      countriesByRegion[country.region].push(country);
    } else {
      countriesByRegion[country.region] = [country];
    }
  });

  return countriesByRegion;
};
