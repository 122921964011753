import React from 'react';
import { arrayOf, func, string } from 'prop-types';

import staticText from '../../../../../../localization/Application/connect-playground';
import { PermissionDescription, PermissionName, PermissionTableBody } from './styles';
import { Checkbox } from '../../../../../../components';

const Permissions = ({ selectedPermissions, handleChange }) => {
  const { permissions: permissionsText } = staticText.form;
  const permissionKeys = Object.keys(permissionsText.permissions);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedPermissions = selectedPermissions;
    if (checked) {
      updatedPermissions.push(name);
    } else {
      updatedPermissions.splice(updatedPermissions.indexOf(name), 1);
    }
    handleChange({ param: 'scope', value: updatedPermissions });
  };

  return (
    <PermissionTableBody>
      <tbody>
        {permissionKeys.map(permission => (
          <tr key={permission}>
            <td>
              <Checkbox
                inputName={permission}
                handleChange={handleCheckboxChange}
                checked={selectedPermissions.includes(permission)}
              />
            </td>
            <td><PermissionName>{permission}</PermissionName> </td>
            <td>
              <PermissionDescription>
                {permissionsText.permissions[permission]}
              </PermissionDescription>
            </td>
          </tr>
        ))}
      </tbody>
    </PermissionTableBody>
  );
};

export default Permissions;

Permissions.propTypes = {
  selectedPermissions: arrayOf(string).isRequired,
  handleChange: func.isRequired,
};
