import { Box, Button, FormControl, FormLabel, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

export const CustomizationsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  margin: theme.spacing(-3, -5), // offset `main` margin
  height: `calc(100vh - ${theme.height.topbar})`,
}));

export const PreviewWrapper = styled('div')(() => ({
  flexGrow: 1,
}));

export const CustomizationsSidebar = styled('form')(({ theme }) => ({
  width: '400px',
  minWidth: '400px',
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.border.boxShadow,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const CustomizationsGroupButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 3),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  display: 'flex',
  justifyContent: 'space-between',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    textDecoration: 'none',
  },
}));

export const CustomizationsGroup = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 3, 6),
  overflowY: 'auto',
  overflowX: 'hidden',
  flexGrow: 1,

  ...theme.scrollShadows,
  ...theme.styledScrollbar,
}));

export const BackButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  padding: 0,

  '&:hover': {
    textDecoration: 'none',
  },

  '& svg': {
    marginTop: '-4px',
  },
}));

export const PublishButtonContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,

  '& button': {
    width: '100%',
    height: '56px',
  },
}));

export const CustomizationGroupHeading = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h1.fontFamily,
  marginBottom: theme.spacing(2),
  fontSize: '1.5rem',
}));

export const CustomizationGroupDescription = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));


export const CustomizationOption = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),

  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const CustomizationOptionLabel = styled(FormLabel)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '1.125rem',
}));

export const FeatureGatedBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.warning.secondary,
  border: `1px solid ${theme.palette.warning.primary}`,
  borderRadius: theme.border.radius2,

  '& img': {
    marginRight: theme.spacing(2),
  },

  '& p': {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.caption.fontSize,
  },
}));
