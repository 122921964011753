import { format } from 'date-fns';

import billingStaticText from '../../../../../localization/Application/Billing/billing';

export const dateFormatter = (date, isFullMonth = false) =>
  format(new Date(date), `${isFullMonth ? 'MMMM' : 'MMM'} d, Y`);

export const pluralFormatter = (num) => {
  return num !== 1
    ? `${num} ${billingStaticText.pluralVehicles}`
    : `${num} ${billingStaticText.singleVehicle}`;
};

export const usageErrorGenerator = (
  planName,
  numberOfVehiclesAboveBase,
  billedByVehicleConnections,
) => {
  const usageError = {
    message: '',
    type: 'warn',
  };

  if (planName === billingStaticText.enterprisePlanName) return usageError;

  if (numberOfVehiclesAboveBase > 0) {
    if (planName === billingStaticText.freePlanName) {
      usageError.message += `${pluralFormatter(numberOfVehiclesAboveBase)} ${
        billingStaticText.activeVehicleLimitExceededFree
      }`;
    } else {
      usageError.message += billingStaticText.activeVehicleLimitExceededPaid(
        numberOfVehiclesAboveBase,
      );
    }
    if (billedByVehicleConnections) {
      usageError.type = 'alert';
    }
  }

  return usageError;
};

export const displayNameFormatter = (planName) => {
  return planName[0].toUpperCase() + planName.slice(1);
};

export const getCurrencySymbol = (currencyCode) => {
  const currencySymbols = {
    usd: '$',
    eur: '€',
    gbp: '£',
  };

  const normalizedCode = currencyCode.toLowerCase();

  return currencySymbols[normalizedCode] || '';
};
