import React, { useState } from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ContentCopy, CheckRounded } from '@mui/icons-material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { CopyButton, UrlViewerContainer, UrlViewerContent } from './styles';

import staticText from '../../../../../../localization/Application/connect-playground';
import { getConnectUrl, urlBase } from '../../utils';

const UrlViewer = ({ urlValues }) => {
  const {
    clientId,
    redirectUri,
    scope,
    mode,
    state,
    user,
    make,
    country,
    singleSelect,
  } = urlValues;

  const disabledCopy = !redirectUri || scope.length === 0;
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(getConnectUrl(urlValues));
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'copy', text: `[connect-url-builder]${staticText.buttons.copy}` });

    setShowCopySuccess(true);
    setTimeout(() => setShowCopySuccess(false), 3000);
  };

  return (
    <UrlViewerContainer>
      <UrlViewerContent>
        <p className="heading">
          <span>{staticText.viewer.heading}</span>
        </p>
        <div className="output">
          <p>{urlBase}</p>
          <p>response_type=code</p>
          <p>{`&client_id=${clientId}`}</p>
          <p className={redirectUri ? '' : 'missing-param'}>{`&redirect_uri=${redirectUri}`}</p>
          <p className={scope.length > 0 ? '' : 'missing-param'}>{`&scope=${scope.join(' ')}`}</p>
          <p>{`&mode=${mode}`}</p>
          {state && <p>{`&state=${state}`}</p>}
          {user && <p>{`&user=${user}`}</p>}
          {make && make !== 'none' && <p>{`&make=${make}`}</p>}
          {country && country !== 'none' && <p>{`&flags=country:${country.code}`}</p>}
          {singleSelect && <p>{`&single_select=${singleSelect}`}</p>}
        </div>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {showCopySuccess && (
            <Box display="flex" alignItems="center" mr={1}>
              <CheckRounded fontSize="16px" sx={{ marginRight: '4px', marginTop: '-2px' }} color="success" />
              <Typography color="success.main" variant="bold">{staticText.buttons.copySuccess}</Typography>
            </Box>
          )}
          <CopyButton variant="contained" onClick={handleCopyClick} disabled={disabledCopy}>
            <ContentCopy fontSize="16px" sx={{ marginRight: '4px', marginTop: '-2px' }} />
            {staticText.buttons.copy}
          </CopyButton>
        </Box>
      </UrlViewerContent>
    </UrlViewerContainer>
  );
};

export default UrlViewer;

UrlViewer.propTypes = {
  urlValues: shape({
    clientId: string.isRequired,
    redirectUri: string,
    mode: string.isRequired,
    scope: arrayOf(string),
    state: string,
    user: string,
    make: string,
    country: string,
    singleSelect: bool,
  }).isRequired,
};
