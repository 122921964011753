import React, { useEffect, useState } from 'react';
import { string, object, shape, func, arrayOf } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Box, useTheme } from '@mui/material';

import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import ApplicationHeader from '../ApplicationHeader';
import staticText from '../../../../localization/Application/webhooks';
import applicationText from '../../../../localization/Application/application';
import { EmptyList, Feedback, InlineRoute, ResourceCards, UpgradeButton, UpsellModal } from '../../../../components';
import { SupportModal } from '../TopbarNav/components';
import {
  VerifyWebhookModal,
  WebhookInformationModal,
  WebhooksModal,
  TestWebhookModal,
  DeleteWebhookModal,
  DeactivateWebhookModal,
  WebhookErrorModal,
  WebhooksStatus,
} from './components';

const Webhooks = ({
  actions: {
    fetchWebhooksRequest,
    toggleWebhookModal,
    selectWebhook,
  },
  currentModal,
  webhooks,
  rolePermissions,
  featureSetId,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const theme = useTheme();

  const canWriteBilling = rolePermissions.includes('write_billing');

  const toggleSupportModal = () => {
    // Support modal currently reports close event to segment within it, so we just send open event
    if (!modalOpen) {
      reportToSegment(types.TRACK, eventNames.modalOpened,
        { label: applicationText.supportModal.label, title: applicationText.supportModal.title });
    }
    setModalOpen(!modalOpen);
    toggleWebhookModal({ nextModal: null, curModal: 'support', trackCloseEvent: true });
  };

  const openUpsellModal = () => {
    toggleWebhookModal({
      curModal: null,
      nextModal: 'upsell',
      nextTitle: 'upsell',
    });
  };

  const closeUpsellModal = () => {
    toggleWebhookModal({
      curModal: 'upsell',
      nextModal: null,
      trackCloseEvent: true,
    });
  };
  const hasWebhooks = Object.keys(webhooks).length > 0;
  const isGated = gatedFeatureData(FEATURES.SCHEDULED_WEBHOOKS, featureSetId);

  const messageByPermission = canWriteBilling ?
    staticText.featureGated.description : staticText.featureGated.contactOwnersToUpgrade;
  const upgradeMessage = isGated ? <Feedback type="info" message={messageByPermission} /> : null;

  let upgradeAgainMessage;
  if (isGated && hasWebhooks) {
    const message = canWriteBilling
      ? <InlineRoute text={staticText.featureGated.regainAccessDescription} segmentLabel={`upsell ${FEATURES.SCHEDULED_WEBHOOKS}`} />
      : staticText.featureGated.contactOwnersToRegainAccess;
    upgradeAgainMessage = <Feedback type="info" message={message} />;
  }

  const upgradeCta = isGated ?
    (<UpgradeButton
      text={staticText.featureGated.buttonText}
      feature={FEATURES.SCHEDULED_WEBHOOKS}
      handleClick={openUpsellModal}
    />)
    : null;

  const modalOpener = (modalName) => {
    const modalMap = {
      verify: <VerifyWebhookModal />,
      activate: <VerifyWebhookModal />,
      information: <WebhookInformationModal />,
      edit: <WebhooksModal mode="edit" />,
      test: <TestWebhookModal />,
      add: <WebhooksModal mode="add" upgradeCta={upgradeCta} upgradeMessage={upgradeMessage} />,
      delete: <DeleteWebhookModal />,
      deactivate: <DeactivateWebhookModal />,
      error: <WebhookErrorModal {...staticText.errorConfig} />,
      support: <SupportModal toggleModal={toggleSupportModal} />,
      upsell: <UpsellModal feature={FEATURES.SCHEDULED_WEBHOOKS} toggleModal={closeUpsellModal} />,
    };
    return modalMap[modalName.toLowerCase()];
  };

  const openWebhookModal = () => {
    selectWebhook('');
    const modalName = Object.keys(webhooks).length >= 10 ? 'error' : 'add';
    toggleWebhookModal({
      nextModal: modalName,
      curModal: null,
      nextTitle: modalName === 'error' ? staticText.errorConfig.title : '',
    });
  };

  useEffect(() => {
    reportToSegment(types.PAGE, 'Webhooks');
  }, []);

  useEffect(() => {
    fetchWebhooksRequest();
  }, [isGated]);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '900px' }}>
      <ApplicationHeader
        dashboardPermission="write_webhooks"
        heading={staticText.heading}
        cta={{
          text: staticText.addWebhookButton,
          onclick: openWebhookModal,
          plusIcon: true,
        }}
      />
      {hasWebhooks
        ? (
          <WebhooksStatus webhooks={webhooks} upgradeMessage={upgradeAgainMessage} />
        ) :
          <EmptyList
            heading={staticText.emptyState.heading}
            upgradeMessage={upgradeMessage}
            details={staticText.emptyState.details}
            cta={{
              onClick: openWebhookModal,
              text: staticText.buttonText,
            }}
            dashboardPermission="write_webhooks"
          />
      }
      {currentModal && modalOpener(currentModal)}
      <ResourceCards cards={staticText.resourceCards} />
    </Box>
  );
};

export default Webhooks;

Webhooks.propTypes = {
  featureSetId: string.isRequired,
  actions: shape({
    fetchWebhooksRequest: func.isRequired,
    toggleWebhookModal: func.isRequired,
    selectWebhook: func.isRequired,
    resetWebhookState: func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  webhooks: object,
  currentModal: string,
  rolePermissions: arrayOf(string).isRequired,
};

Webhooks.defaultProps = {
  webhooks: {},
  currentModal: null,
};
