import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import { Box, Typography } from '@mui/material';

import staticText from '../../../../../../localization/Application/Billing/businessTaxModal';
import api from '../../../../../../../src/services/api/api';
import spinningButtonStyles from '../../../../../../global-styles/animations/spinningButton';
import { Feedback, Modal } from '../../../../../../components';
import { BusinessOption, SubmitButton } from './styles';

const BusinessTaxModal = ({
  organizationId,
  planName,
  toggleModal,
  actions: {
    reportError,
  },
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [error, setError] = useState(false);

  const segmentProperties = {
    label: 'billing modal',
    title: staticText.title,
  };
  reportToSegment(types.TRACK, eventNames.modalOpened, segmentProperties);

  const toggleModalWithTracking = () => {
    reportToSegment(types.TRACK, eventNames.modalClosed, segmentProperties);
    toggleModal();
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const onSubmit = async () => {
    try {
      setIsButtonLoading(true);
      const showTaxId = selectedValue === 'business';
      const URL = await api.fetchStripeUrl(
        organizationId,
        'checkout',
        planName,
        showTaxId,
      );
      window.location.href = URL;
    } catch (err) {
      reportError(err);
      setError(true);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const content = (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="bold" fontSize={20}>{staticText.header}</Typography>
      <Typography fontSize={16}>{staticText.description}</Typography>
      {staticText.options.map(option => (
        <BusinessOption
          key={option.value}
          onClick={handleChange}
          value={option.value}
          selected={selectedValue === option.value}
          disabled={selectedValue === option.value}
        >{option.label}
        </BusinessOption>
      ))}

      {error && (
      <div className="flex-vertical-center">
        {/* <span>{staticText.error}</span> */}
        <Feedback
          message={staticText.error}
          type="warn"
          additionalClassNames="m-none sm-12"
        />
      </div>)}

      <SubmitButton
        color="primary"
        variant="contained"
        onClick={onSubmit}
        disabled={!selectedValue || isButtonLoading}
        sx={isButtonLoading && spinningButtonStyles}
      >
        {staticText.submit}
      </SubmitButton>
    </Box>);
  return (
    <Modal
      title={staticText.downgradeModalTitle}
      content={content}
      toggleModal={toggleModalWithTracking}
      divider={false}
    />
  );
};

export default BusinessTaxModal;

BusinessTaxModal.propTypes = {
  organizationId: string.isRequired,
  planName: string.isRequired,
  toggleModal: func.isRequired,
  actions: shape({
    reportError: func.isRequired,
  }).isRequired,
};
