import React, { useEffect, useState } from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import { FormControl } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { get } from 'lodash';
import appConfig from 'appConfig';

import { CountryOption, CountrySelect, PreviewIframe, PreviewHeaderWrapper, Preview } from './styles';
import { getCountrySelectorMenuOptions } from '../Countries/utils';
import { SelectIcon } from '../../../../../../components';

const ConnectPreview = ({
  application,
  config: {
    pagePath,
    showHeader,
  },
  customizations: {
    appName,
    theme: displayTheme,
    logoUrl,
    supportedCountries,
    supportedMakes,
  },
  defaultSupportedCountries,
}) => {
  const baseUrl = get(appConfig, 'connectPreviewURL');
  const path = pagePath;

  const [selectedCountry, setSelectedCountry] = useState('US');
  const [previewTopOffset, setPreviewTopOffset] = useState(false);
  const [countryMenuOptions, setCountryMenuOptions] = useState([]);

  // query params
  const clientId = `client_id=${application.id}`;
  const name = `&app_name=${appName}`;
  const logo = logoUrl ? `&logo=${logoUrl}` : '';
  const theme = `&theme=${displayTheme}`;
  // Filter the selected makes to only those supported in the selected country.
  // Makes query param must be an array with each make wrapped in double quotes.
  const makes = `&makes=[${supportedMakes
    .map(make => `"${make}"`)}]`;
  // Countries query param must be an array with each country code wrapped in double quotes.
  const countries = `&countries=[${supportedCountries.map(country => `"${country}"`)}]`;
  const country = `&country=${selectedCountry || 'US'}`;

  const iframeSrc =
    `${baseUrl}${path}?${clientId}${name}${makes}${countries}${country}${theme}${logo}`;

  const handleChange = (e) => {
    setSelectedCountry(e.target.value);
    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: `[connect preview] country selection: ${e.target.value}`,
    });
  };

  const darkModeEnabled = () => {
    return displayTheme === 'dark' ||
      (displayTheme === 'system' && window.matchMedia
        && window.matchMedia('(prefers-color-scheme: dark)').matches);
  };

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        let allowedCountries = supportedCountries;
        if (supportedCountries.length === 0) {
          allowedCountries = defaultSupportedCountries;
        }
        const options = await getCountrySelectorMenuOptions(allowedCountries);
        setCountryMenuOptions(options);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch country options:', error);
      }
    };

    fetchCountryOptions();
  }, [supportedCountries, defaultSupportedCountries]);

  useEffect(() => {
    // if selected countries list does not include the current selected country or the default 'US',
    // fallback to displaying the first country in the selected countries list
    if (!supportedCountries.includes(selectedCountry)) {
      setSelectedCountry(supportedCountries[0]);
    }
  }, [supportedCountries]);

  useEffect(() => {
    // adds a top margin to the preview container when it is
    // close to overlapping the preview header country selector
    // on smaller screens
    const previewContainer = document.getElementById('connect-preview-container');
    if (previewContainer && previewContainer.offsetWidth < 858) {
      setPreviewTopOffset(true);
    } else {
      setPreviewTopOffset(false);
    }
  }, [showHeader]);

  return (
    <Preview id="connect-preview-container">
      {showHeader && (
        <PreviewHeaderWrapper>
          <FormControl variant="outlined">
            <CountrySelect
              name="country"
              value={selectedCountry}
              id="country-select"
              onChange={handleChange}
              IconComponent={SelectIcon}
              variant="outlined"
            >
              {countryMenuOptions.map(option => (
                <CountryOption key={option.code} value={option.code}>
                  {option.displayName}
                </CountryOption>
              ))}
            </CountrySelect>
          </FormControl>
        </PreviewHeaderWrapper>
      )}
      <PreviewIframe darkmode={darkModeEnabled()} offset={showHeader && previewTopOffset}>
        <div id="connect-preview-background" />
        <iframe
          id="connect-preview"
          title="connect preview"
          data-testid="connect-preview-iframe"
          src={iframeSrc}
        />
      </PreviewIframe>
    </Preview>
  );
};

export default ConnectPreview;

ConnectPreview.propTypes = {

  application: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  config: shape({
    pagePath: string,
    showHeader: bool,
  }),
  customizations: shape({
    appName: string.isRequired,
    logoUrl: string,
    theme: string.isRequired,
    supportedCountries: arrayOf(string).isRequired,
    supportedMakes: arrayOf(string).isRequired,
  }).isRequired,
  defaultSupportedCountries: arrayOf(string).isRequired,
};

ConnectPreview.defaultProps = {
  config: {
    pagePath: '/preamble',
    showHeader: false,
  },
};
