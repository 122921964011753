export const types = {
  FETCH_APPLICATION_MAKES: 'developer-client/ConnectPlayground/FETCH_APPLICATION_MAKES',
  FETCH_APPLICATION_MAKES_SUCCESS: 'developer-client/ConnectPlayground/FETCH_APPLICATION_MAKES_SUCCESS',
  FETCH_APPLICATION_MAKES_FAILURE: 'developer-client/ConnectPlayground/FETCH_APPLICATION_MAKES_FAILURE',

  FETCH_SUPPORTED_COUNTRIES: 'developer-client/ConnectPlayground/FETCH_SUPPORTED_COUNTRIES_REQUEST',
  FETCH_SUPPORTED_COUNTRIES_SUCCESS: 'developer-client/ConnectPlayground/FETCH_SUPPORTED_COUNTRIES_SUCCESS',
  FETCH_SUPPORTED_COUNTRIES_FAILURE: 'developer-client/ConnectPlayground/FETCH_SUPPORTED_COUNTRIES_FAILURE',

};

export const initialState = {
  applicationMakes: [],
  applicationMakesLoading: false,
  applicationMakesError: null,

  supportedCountries: [],
  isFetchingSupportedCountries: false,
  fetchSupportedCountriesError: null,
};

export const actions = {
  fetchApplicationMakes: () => ({
    type: types.FETCH_APPLICATION_MAKES,
  }),
  fetchApplicationMakesSuccess: makes => ({
    type: types.FETCH_APPLICATION_MAKES_SUCCESS,
    payload: makes,
  }),
  fetchApplicationMakesFailure: error => ({
    type: types.FETCH_APPLICATION_MAKES_FAILURE,
    payload: error,
  }),
  fetchSupportedCountries: () => ({
    type: types.FETCH_SUPPORTED_COUNTRIES,
  }),
  fetchSupportedCountriesSuccess: countries => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_SUCCESS,
    payload: countries,
  }),
  fetchSupportedCountriesFailure: err => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_FAILURE,
    payload: err,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_APPLICATION_MAKES:
      return {
        ...state,
        applicationMakesLoading: true,
        applicationMakesError: null,
      };
    case types.FETCH_APPLICATION_MAKES_SUCCESS:
      return {
        ...state,
        applicationMakes: action.payload,
        applicationMakesLoading: false,
        applicationMakesError: null,
      };
    case types.FETCH_APPLICATION_MAKES_FAILURE:
      return {
        ...state,
        applicationMakes: [],
        applicationMakesLoading: false,
        applicationMakesError: action.payload,
      };
    case types.FETCH_SUPPORTED_COUNTRIES:
      return {
        ...state,
        isFetchingSupportedCountries: true,
      };
    case types.FETCH_SUPPORTED_COUNTRIES_SUCCESS:
      return {
        ...state,
        supportedCountries: action.payload,
        isFetchingSupportedCountries: false,
      };
    case types.FETCH_SUPPORTED_COUNTRIES_FAILURE:
      return {
        ...state,
        isFetchingSupportedCountries: false,
        supportedCountries: [],
        fetchSupportedCountriesError: action.payload,
      };
    default:
      return state;
  }
}
