import React from 'react';
import { bool, node, string } from 'prop-types';
import { Tooltip, Typography } from '@mui/material';
import { InlineStyledText } from '../../../../../../components';
import { FormLabelDescription, StyledFormControl, StyledFormLabel } from './styles';

const FormFieldLabel = ({
  label,
  description,
  disabled,
  children,
  tooltip,
}) => {
  return (
    <StyledFormControl disabled={disabled}>
      <StyledFormLabel disabled={disabled}>
        <Typography variant="bold">{label}</Typography>
      </StyledFormLabel>
      {description && (
        <FormLabelDescription variant="caption" disabled={disabled}>
          <InlineStyledText text={description} />
        </FormLabelDescription>
      )}
      {disabled ? (
        <Tooltip title={tooltip} arrow placement="right">
          <div>{children}</div>
        </Tooltip>
      ) : (
        children
      )}
    </StyledFormControl>
  );
};

export default FormFieldLabel;

FormFieldLabel.propTypes = {
  label: string.isRequired,
  children: node.isRequired,
  description: string,
  disabled: bool,
  tooltip: string,
};

FormFieldLabel.defaultProps = {
  description: null,
  disabled: false,
  tooltip: '',
};
