import { Button, styled } from '@mui/material';

export const RedirectUriModalButton = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 0,

  '& svg': {
    marginTop: '-4px',
    marginRight: theme.spacing(1),
  },

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.grey[200],
  },
}));

// Could not style the Select component directly
// as these props apply to the paper component which exists
// outside of the component
export const getSelectMenuProps = theme => ({
  PaperProps: {
    sx: {
      ...theme.styledScrollbar,
      '::-webkit-scrollbar-track': {
        marginTop: '0',
        marginBottom: '0',
      },

      boxSizing: 'border-box',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      marginTop: '-4px',

      '& .MuiList-root': {
        padding: 0,
      },
      '& li': {
        padding: theme.spacing(1.5),
        color: theme.palette.grey[600],
        whiteSpace: 'normal',
        overflowWrap: 'anywhere',
      },
    },
  },
});
