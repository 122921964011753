import React from 'react';
import { Box, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { Chip } from '../../../../../../../components';
import { CardOption, StyledFormControlLabel } from './styles';

const staticText = {
  available: 'Available',
  earlyAccess: 'Early Access',
};

const CardSelect = ({
  inputName,
  inputValue,
  handleChange,
  disabled,
  children,
}) => {
  const theme = useTheme();

  const webhookTypes = children.reduce((acc, type) => {
    if (type.beta) {
      acc.beta.push(type);
    } else {
      acc.available.push(type);
    }
    return acc;
  }, { available: [], beta: [] });

  const hasBetaType = webhookTypes.beta.length > 0;

  const renderOptions = options => options.map(({
    name, text, description, beta,
  }) => {
    const checked = inputValue === name;
    return (
      <StyledFormControlLabel
        key={name}
        value={name}
        control={<Radio sx={{ display: 'none' }} disabled={disabled} />}
        label={
          <CardOption className={clsx({ checked, disabled })}>
            <Box display="flex" gap={1} alignItems="flex-end">
              <Typography fontWeight={600}>{text}</Typography>
              {beta && <Chip text="BETA" />}
            </Box>
            <Typography color={theme.palette.grey[600]} mt={1}>{description}</Typography>
          </CardOption>
        }
      />
    );
  });

  return (
    <Box sx={{ width: '100%' }}>
      <RadioGroup
        name={inputName}
        value={inputValue}
        onChange={handleChange}
        sx={{ rowGap: 2 }}
      >
        {hasBetaType && <Typography variant="h3" fontSize="0.875rem">{staticText.available}</Typography>}
        {renderOptions(webhookTypes.available)}
        {hasBetaType && <Typography variant="h3" fontSize="0.875rem">{staticText.earlyAccess}</Typography>}
        {renderOptions(webhookTypes.beta)}
      </RadioGroup>
    </Box>
  );
};

export default CardSelect;

CardSelect.propTypes = {
  inputName: string.isRequired,
  children: arrayOf(
    shape({
      name: string,
      text: string,
      description: string,
    }),
  ),
  handleChange: func.isRequired,
  inputValue: string,
  disabled: bool.isRequired,
};

CardSelect.defaultProps = {
  inputValue: '',
  children: [],
};
