import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { bool, func, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { InlineStyledText, Modal } from '../../../../../../../../components';
import staticText from '../../../../../../../../localization/Application/VehicleManagement/unsubscribeModal';

const UnsubscribeModal = ({
  closeModal,
  disabled,
  unsubscribeVehicleFromWebhook,
  webhook,
}) => {
  const { webhookId, webhookName } = webhook;

  const handleUnsubscribe = () => {
    unsubscribeVehicleFromWebhook(webhookId);
    closeModal();
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      {
        label: 'backend action',
        text: `[vehicle details - webhooks] ${staticText.confirm}`,
      },
    );
  };

  const descriptions = (disabled ? staticText.disabledDescription : staticText.description.replaceAll('{{webhookName}}', webhookName)).split('\n');

  const content = (
    <div>
      <Box mt={1} mb={3}>
        {descriptions.map(text => (
          <Typography key={text}>
            <InlineStyledText text={text} />
          </Typography>
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={3} mt={5}>
        <Button
          variant="outlined"
          color="error"
          disabled={disabled}
          size="large"
          onClick={handleUnsubscribe}
          id="confirm"
          sx={{ padding: '10px 16px' }}
        >
          {staticText.confirm}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={closeModal}
          id="cancel"
          sx={{ padding: '10px 16px' }}
        >
          {staticText.cancel}
        </Button>
      </Box>
    </div>
  );
  return (
    <Modal
      title={staticText.title}
      content={content}
      toggleModal={closeModal}
    />
  );
};

export default UnsubscribeModal;

UnsubscribeModal.propTypes = {
  closeModal: func.isRequired,
  disabled: bool,
  unsubscribeVehicleFromWebhook: func.isRequired,
  webhook: shape({
    type: string.isRequired,
    webhookId: string.isRequired,
    webhookName: string.isRequired,
  }).isRequired,
};

UnsubscribeModal.defaultProps = {
  disabled: false,
};
