import React from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { Step, Stepper as MuiStepper, useTheme, StepButton } from '@mui/material';
import { CheckCircleTwoTone, CircleOutlined, CircleTwoTone } from '@mui/icons-material';

import { StepConnector, StepLabel } from '../../../../scenes/Application/components/Webhooks/components/WebhooksForm/styles';

// eslint-disable-next-line react/prop-types
export const StepIcon = ({ active, completed, forceChecked }) => {
  if (completed || forceChecked) return <CheckCircleTwoTone color="primary" />;
  if (active) return <CircleTwoTone color="primary" />;
  return <CircleOutlined color="disabled" />;
};

// Generic/simplified version of Webhook's Stepper component.
// Ideally we combine them into one generic component under Form,
// including the useStepper hook within WebhooksForm.
const Stepper = ({
  steps, activeStep, handleStep, forceChecked,
}) => {
  const theme = useTheme();

  return (
    <MuiStepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StepConnector />}
      sx={{ margin: theme.spacing(4, 12, 0) }}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          {(index === 0 || index === steps.length - 1) && <StepConnector className={index === 0 ? 'first-connector' : 'last-connector'} />}
          <StepButton onClick={handleStep(index)} >
            <StepLabel
              StepIconComponent={props => <StepIcon {...props} forceChecked={forceChecked} />}
              active={activeStep === index}
            >
              {label}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;

Stepper.propTypes = {
  steps: arrayOf(string).isRequired,
  activeStep: number.isRequired,
  handleStep: func.isRequired,
  forceChecked: bool,
};

Stepper.defaultProps = {
  forceChecked: false,
};
