import React from 'react';
import { Box, Typography } from '@mui/material';

import { InlineStyledText } from '../../../../../../components';
import { PermissionHeader, PermissionTableHeader } from '../Permissions/styles';
import staticText from '../../../../../../localization/Application/connect-playground';

const PermissionsHeader = () => {
  return (
    <React.Fragment>
      <Box mt={2} ml={3} mb={2}>
        <Typography>
          <InlineStyledText text={staticText.form.permissions.description} />
        </Typography>
      </Box>
      <Box ml={3} mr={5}>
        <PermissionTableHeader>
          <thead>
            <tr>
              <th />
              <th>
                <PermissionHeader sx={{ width: '205px' }} component="span">
                  {staticText.form.permissions.tableHeaders[0]}
                </PermissionHeader>
              </th>
              <th>
                <PermissionHeader>
                  {staticText.form.permissions.tableHeaders[1]}
                </PermissionHeader>
              </th>
            </tr>
          </thead>
        </PermissionTableHeader>
      </Box>
    </React.Fragment>
  );
};

export default PermissionsHeader;
