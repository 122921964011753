import { styled } from '@mui/material/styles';

export const FiltersContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const LogsContainer = styled('div')(() => ({
  maxWidth: '100% !important',
}));

export const ErrorsList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: theme.spacing(0.5, 0, 1),
  margin: 0,
  listStyle: 'none',
}));
