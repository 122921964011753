import { FormControl, FormLabel, styled, Typography } from '@mui/material';

export const StyledFormControl = styled(FormControl, { shouldForwardProp: prop => prop !== 'disabled' })(({ theme, disabled }) => ({
  marginBottom: theme.spacing(5),
  width: '100%',

  '& .MuiSelect-select': {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.bold.fontFamily,

    ...(disabled && {
      color: theme.palette.primary.main,
      '-webkit-text-fill-color': theme.palette.primary.main,
      opacity: '0.5',
    }),
  },

  '& .MuiTextField-root': {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[200],
      },
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[100],

      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[200],
      },
      '& input': {
        color: theme.palette.text.primary,
        '-webkit-text-fill-color': theme.palette.text.primary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[200],
      },
    },
  },
}));

export const StyledFormLabel = styled(FormLabel, { shouldForwardProp: prop => prop !== 'disabled' })(({ disabled, theme }) => ({
  paddingBottom: theme.spacing(1),
  '&.Mui-focused': {
    color: theme.palette.text.primary,
  },
  ...(disabled && {
    color: theme.palette.text.disabled,
  }),
}));

export const FormLabelDescription = styled(Typography, { shouldForwardProp: prop => prop !== 'disabled' })(({ disabled, theme }) => ({
  marginBottom: theme.spacing(1),
  ...(disabled && {
    color: theme.palette.text.disabled,
  }),
  '& > p': {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
}));
