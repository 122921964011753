import React from 'react';
import { arrayOf, shape, string, bool, oneOfType, func } from 'prop-types';
import {
  Box,
  List,
  ListItem as MuiListItem,
  Typography, Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { CheckCircleIcon } from '../../../PlanComparisonTable/styles';

const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  minHeight: '24px',
  paddingTop: theme.spacing(3),
  paddingBottom: `${theme.spacing(0.75)} !important`, // important to avoid mui last child style
}));

const PlanFeatures = ({ planFeatures, planName, setCurrentModal }) => {
  const { features } = planFeatures;
  const theme = useTheme();

  const handleClick = () => {
    setCurrentModal('upgrade');
  };

  return (
    <div>
      <List disablePadding>
        {features.map(feature => (
          <ListItem
            key={feature.title}
            disableGutters
            disablePadding
          >
            <Box display="flex" flexDirection="column" gap={theme.spacing(0.5)}>
              <Typography variant="bold" fontSize={14}>{feature.title}</Typography>
              {feature.description && (
                <Typography color={theme.palette.grey[600]} fontSize={12}>
                  {feature.description}
                </Typography>
              )}
            </Box>
            <Box>
              {/* eslint-disable-next-line no-nested-ternary */}
              {typeof feature[planName] === 'boolean' ? (
                    feature[planName] ? (
                      <CheckCircleIcon sx={{ height: 18 }} />
                    ) : (
                      <Button
                        id="upgrade-button"
                        variant="text"
                        color="primary"
                        type="button"
                        sx={{
                          padding: 0,
                          fontSize: 14,
                          fontFamily: theme.typography.fontFamily,
                          fontWeight: 600,
                          '&:hover': {
                            color: theme.palette.teal.light,
                            textDecoration: 'none',
                          },

                        }}
                        onClick={handleClick}
                      >
                        Upgrade
                      </Button>
                    )
                  ) : (
                    <Typography fontSize={14}>
                      {feature[planName]}
                    </Typography>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default PlanFeatures;

PlanFeatures.propTypes = {
  planFeatures: shape({
    title: string,
    features: arrayOf(
      shape({
        title: string.isRequired,
        description: string,
        free: oneOfType([bool, string]),
        build: oneOfType([bool, string]),
        scale: oneOfType([bool, string]),
        salesLed: oneOfType([bool, string]),
      }),
    ).isRequired,
  }).isRequired,
  planName: string.isRequired,
  setCurrentModal: func.isRequired,
};

