import { styled } from '@mui/material/styles';
import { DataGridPro as MuiDataGridPro } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';

export const DataGridPro = styled(MuiDataGridPro, {
  shouldForwardProp: prop => prop !== 'stylingOptions',
})(({ stylingOptions, theme }) => ({
  '& .MuiDataGrid-cell': {
    padding: theme.spacing(0, 3),

    '&:focus, :focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeader': {
    color: theme.palette.grey[600],
    fontFamily: theme.typography.bold.monospace,
    padding: theme.spacing(3),
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '8px 0px',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--resizable': {
    display: 'flex',
  },
  '& .MuiTablePagination-toolbar': {
    paddingRight: '36px',
  },

  ...(stylingOptions && stylingOptions.rowHover && {
    '& .MuiDataGrid-row': {
      '&:hover': {
        ...(stylingOptions.rowHover.color === 'primary' && {
          backgroundColor: theme.palette.primary.neutral,

          '& .MuiIconButton-root .MuiSvgIcon-root': {
            fill: theme.palette.common.black,
          },
        }),

        ...(stylingOptions.rowHover.cursor && {
          cursor: stylingOptions.rowHover.cursor,
        }),
      },
    },
  }),
}));

export const DataGridProContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow,
}));

export const IdCellText = styled('div')(() => ({
  display: 'inline-block',
  width: '100%',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
}));

export const VehicleCell = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const ErrorCellText = styled('div')(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  padding: '10px',
  borderRadius: '4px',
  backgroundColor: theme.palette.grey[100],
}));

export const AvatarText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.black,
  width: '42px',
  height: '42px',
  borderRadius: '50%',
  color: theme.palette.common.white,
}));

export const EventContainer = styled('div')(() => ({
  display: 'flex',
}));

export const EventTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  marginLeft: theme.spacing(1),
}));
