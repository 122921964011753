import React from 'react';
import { Box, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { arrayOf, func, shape, string } from 'prop-types';
import { CardLabel, CardOption } from './styles';

// Generic/simplified version of Webhook's CardSelect component.
// Ideally we combine them into one generic component under Form.
const CardSelect = ({
  options, inputName, inputValue, handleChange,
}) => {
  const theme = useTheme();

  const onChange = (event) => {
    const { name, value } = event.target;
    handleChange({ name, value });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <RadioGroup
        name={inputName}
        value={inputValue}
        onChange={onChange}
        sx={{ rowGap: 1.5 }}
      >
        {options.map(({
          text, value, description,
        }) => {
          const checked = inputValue === value;
          return (
            <CardLabel
              key={value}
              value={value}
              control={<Radio sx={{ display: 'none' }} />}
              label={
                <CardOption className={clsx({ checked })}>
                  <Typography variant="bold">{text}</Typography>
                  <Typography color={theme.palette.grey[600]} mt={1}>{description}</Typography>
                </CardOption>
              }
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
};

export default CardSelect;

CardSelect.propTypes = {
  options: arrayOf(
    shape({
      value: string,
      text: string,
      description: string,
    }),
  ).isRequired,
  inputName: string.isRequired,
  inputValue: string.isRequired,
  handleChange: func.isRequired,
};
