import { arrayOf, bool, number, shape, string } from 'prop-types';
import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Spinner } from '../../../../../../components';
import { GatedFunnel, StatsCard } from './components';

import staticText from '../../../../../../localization/Application/ConnectInsights/connectInsights';

const loadingAndEmptyBlur = {
  filter: 'blur(4px)',
  opacity: '50%',
};

const Statistics = ({
  funnel,
  funnelGated,
  isFetching,
  showLoading,
}) => {
  const funnelEmpty = funnel.every(event => event.count === '0');
  const theme = useTheme();

  return (
    <Box my={3}>
      <Typography variant="h2" id="heading-conversion-rate" width="fit-content">
        {staticText.funnel.heading}
      </Typography>
      <Typography my={1}>
        {staticText.funnel.description}
      </Typography>
      {funnelGated && <GatedFunnel />}
      {!funnelGated && (
        <Box display="grid">
          {isFetching && <Spinner additionalClassNames="connect-funnel" delay={300} />}
          {!isFetching && funnelEmpty && (
            <Box
              gridRow={1}
              gridColumn={1}
              zIndex={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop="40px"
            >
              <Box
                backgroundColor={theme.palette.common.white}
                borderRadius={theme.spacing(0.5)}
                padding={6}
                width="40%"
                textAlign="center"
              >
                <Typography variant="h4" color="primary">
                  {staticText.funnel.emptyState.heading}
                </Typography>
                <Typography mt={3}>
                  {staticText.funnel.emptyState.description}
                </Typography>
              </Box>
            </Box>
          )}
          <React.Fragment>
            <Box
              display="flex"
              justifyContent="space-between"
              columnGap={3}
              mt={5}
              gridRow={1}
              gridColumn={1}
              sx={{
                ...(((isFetching && showLoading) || funnelEmpty) && loadingAndEmptyBlur),
              }}
            >
              {funnel.map((event) => {
                return (
                  <StatsCard key={event.stage} event={event} delay={(event.order * 50) + 50} />
                );
              })}
            </Box>
          </React.Fragment>
        </Box>
      )}
    </Box>
  );
};

export default Statistics;

Statistics.propTypes = {
  funnelGated: bool.isRequired,
  funnel: arrayOf(shape({
    count: string.isRequired,
    stage: string.isRequired,
    order: number.isRequired,
    displayName: string.isRequired,
    percent: number.isRequired,
  })).isRequired,
  isFetching: bool.isRequired,
  showLoading: bool.isRequired,
};
