import React from 'react';
import { bool, func, string } from 'prop-types';
import { TableBody, TableHead } from '@mui/material';

import PlanButton from '../PlanButton/PlanButton';

import staticText from '../../../../../../localization/Application/Billing/planComparisonTable';
import billingStaticText from '../../../../../../localization/Application/Billing/billing';
import { Section } from '../../styles';
import {
  TableWrapper,
  Table,
  TableRow,
  HeaderCell,
  HeaderCellPriceLine,
  BodyCell,
  TableText,
  Chip,
  CheckCircleIcon,
  XCircleIcon,
  HeaderCellAddendum,
  HeaderCellTitleLine,
} from './styles';

const PlanComparisonTable = ({
  emailVerifiedAt,
  planName,
  setCurrentModal,
  isCurrentPlanLegacy,
  renderSectionWrapper,
}) => {
  // The recommended plan is the next tier after the org's current plan
  const recommendedPlan = planName === billingStaticText.freePlanName ?
    billingStaticText.buildPlanName :
    billingStaticText.salesLedPlanName;


  const tableContent = (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <HeaderCell variant="head" />
            {Object.keys(staticText.plans).map((key) => {
              const planDetail = staticText.plans[key];
              // eslint-disable-next-line no-nested-ternary
              const chip = key === planName ?
                staticText.chips.current : key === recommendedPlan ?
                staticText.chips.recommended :
                null;
              return (
                <HeaderCell key={key} variant="head" highlighted={key === recommendedPlan}>
                  <HeaderCellTitleLine>
                    <TableText fontSize={20}>{planDetail.planName}</TableText>
                    {chip &&
                      <Chip chip={chip}>{chip}</Chip>
                    }
                  </HeaderCellTitleLine>
                  <HeaderCellPriceLine>
                    {planDetail.price.cost &&
                      <TableText fontSize={36}>
                        {planDetail.price.cost}
                      </TableText>
                    }
                    {planDetail.price.addendum &&
                      <HeaderCellAddendum fontSize={16} color="grey.600" >
                        {planDetail.price.addendum}
                      </HeaderCellAddendum>
                    }
                  </HeaderCellPriceLine>
                  <PlanButton
                    buttonTexts={staticText.buttonTexts}
                    emailVerifiedAt={emailVerifiedAt}
                    columnPlanName={key}
                    key={`${key}-${planDetail.planName}`}
                    setCurrentModal={setCurrentModal}
                    userPlanName={planName}
                    isCurrentPlanLegacy={isCurrentPlanLegacy}
                  />
                </HeaderCell>
            );
          })}
          </TableRow>
        </TableHead>

        <TableBody>
          {staticText.features.map((feature, i) => (
            <TableRow key={feature.title} lastRow={i === staticText.features.length - 1}>
              <BodyCell>{feature.title}</BodyCell>
              {Object.keys(staticText.plans).map(key => (
                <BodyCell
                  key={key}
                  highlighted={key === recommendedPlan}
                  lastRow={i === staticText.features.length - 1}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {typeof feature[key] === 'boolean' ? (
                  feature[key] ? (
                    <CheckCircleIcon />
                  ) : (
                    <XCircleIcon />
                  )
                ) : (
                  feature[key]
                )}
                </BodyCell>
            ))}
            </TableRow>
        ))}
        </TableBody>
      </Table>
    </TableWrapper>);

  return renderSectionWrapper ? <Section sx={{ padding: '1rem 2.5rem' }}>{tableContent}</Section> : tableContent;
};

export default PlanComparisonTable;

PlanComparisonTable.propTypes = {
  emailVerifiedAt: string,
  planName: string.isRequired,
  setCurrentModal: func.isRequired,
  isCurrentPlanLegacy: bool.isRequired,
  renderSectionWrapper: bool,
};

PlanComparisonTable.defaultProps = {
  emailVerifiedAt: null,
  renderSectionWrapper: true,
};
