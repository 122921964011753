const staticText = {
  actions: {
    disable: 'Disable',
    enable: 'Enable',
  },
  status: {
    disabled: 'Disabled',
    enabled: 'Enabled',
  },
  compatibilityApi: {
    heading: 'Compatibility API by VIN',
    description: 'Allows developers to determine if a specific vehicle is compatible with Smartcar. Learn more https://smartcar.com/docs/api-reference/compatibility/by-vin',
  },
};

export default staticText;
