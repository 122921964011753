import React from 'react';
import { func, shape, string } from 'prop-types';

import { Section } from '../../styles';
import staticText from '../../../../../../localization/Application/Billing/myPlan';
import { FeatureSection } from './components';

/**
 * The My Plan tab in the billing page shows the organization's current plan's features
 *
 * @param {object} billingInfo - billing information from the store
 * @param {string} billingInfo.planName
 *
 * @returns {JSX.Element}
 */
const MyPlan = ({ billingInfo, setCurrentModal }) => {
  return (
    staticText.categories.map(category => (
      <Section key={category.title} sx={{ borderRadius: '8px' }}>
        <FeatureSection
          category={category}
          billingInfo={billingInfo}
          setCurrentModal={setCurrentModal}
        />
      </Section>
    ))
  );
};

export default MyPlan;

MyPlan.propTypes = {
  billingInfo: shape({
    planName: string.isRequired,
  }).isRequired,
  setCurrentModal: func.isRequired,
};
