import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const VehiclesTable = styled('div')(({ theme }) => ({
  boxShadow: theme.border.boxShadow,
  borderRadius: theme.border.radius,
  backgroundColor: theme.palette.common.white,
  marginBottom: theme.spacing(4),
}));

export const Headers = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: '64px',

  '& > *': {
    width: '25%',
    padding: theme.spacing(2, 4),
    margin: '0',
  },

  '& > *:first-of-type': {
    width: '40%',
  },
}));

export const Rows = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  paddingLeft: '0',
  margin: '0',
  width: '100%',

  '& li': {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      flexGrow: 1,
    },

    '& button': {
      width: '40px',
      disableRipple: true,
      backgroundColor: 'transparent',

      '& svg': {
        height: '24px',
        width: '24px',
        fill: theme.palette.grey[500],
        transition: '.25s',
      },

      '&:hover': {
        backgroundColor: 'transparent',

        '& svg': {
          fill: theme.palette.error.main,
        },
      },
    },
  },
}));

export const Vehicle = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  color: disabled && theme.palette.grey[500],

  '&:hover': !disabled && {
    backgroundColor: theme.palette.grey[100],
  },

  '& > *': {
    padding: theme.spacing(1.5, 4),
  },

  '& > div:first-of-type': {
    width: '40%',
  },

  '& > p, & > div:last-of-type': {
    width: '25%',
  },
}));

export const VehicleLabel = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const InactiveLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

export const RegionTag = styled(Typography)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  padding: '4px 4px 1px 4px',
  marginLeft: theme.spacing(1),
  marginTop: '-2px',
}));

export const TripStatusLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'status',
})(({ theme, status }) => {
  const getStyles = () => {
    switch (status) {
      case 'playing':
        return {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
        };
      case 'paused':
        return {
          backgroundColor: theme.palette.warning.light,
        };
      case 'complete':
        return {
          backgroundColor: theme.palette.text.disabled,
          color: theme.palette.common.white,
        };
      default:
        return {
          border: `1px solid ${theme.palette.grey[200]}`,
          color: theme.palette.text.disabled,
        };
    }
  };

  return ({
    display: 'inline-block',
    borderRadius: theme.border.radius,
    padding: '6px 10px 4px',
    ...getStyles(),
  });
});
