import React, { useEffect, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';
import LaunchIcon from '@mui/icons-material/Launch';

import { AdvancedSettings, BasicSettings, Permissions, PermissionsHeader, UrlViewer } from './components';
import ApplicationHeader from '../ApplicationHeader';
import { Stepper } from '../../../../components/Form/components';
import { InlineLinks, InlineStyledText } from '../../../../components';
import useStepper from '../Webhooks/components/WebhooksForm/useStepper';

import staticText from '../../../../localization/Application/connect-playground';
import { ButtonsContainer, Footer, FormFields, LaunchLink, PermissionCount, PlaygroundContainer, UrlBuilderContainer } from './styles';
import { getConnectUrl } from './utils';
import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';

const ConnectPlayground = ({
  applicationId,
  redirectUris,
  applicationMakes,
  supportedCountries,
  actions: {
    fetchApplicationMakes,
    fetchSupportedCountries,
    updateApplication,
  },
  rolePermissions,
  featureSetId,
}) => {
  const canWriteBilling = rolePermissions.includes('write_billing');
  const advancedIsGated =
    gatedFeatureData(FEATURES.CONNECT_PLAYGROUND_ADVANCED_SETTINGS, featureSetId);

  const [urlValues, setUrlValues] = useState({
    clientId: applicationId,
    redirectUri: '',
    mode: 'live',
    scope: [],
    state: null,
    user: null,
    make: null,
    country: null,
    singleSelect: false,
  });

  const {
    activeStep,
    isLastStep,
    showBackButton,
    handleBack,
    handleNext,
    handleStep,
  } = useStepper({ sections: staticText.stepperSections, defaultStep: 0 });

  const handleChange = ({ param, value }) => {
    setUrlValues({
      ...urlValues,
      [param]: value,
    });
  };

  const handleStepChange = (direction) => {
    if (direction === 'next') {
      handleNext();
    } else {
      handleBack();
    }
    // scroll form back to top on each step change
    const scrollableElement = document.getElementById('connect-playground-form');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  };

  const handleLaunch = () => {
    reportToSegment(types.TRACK, 'linkClicked', {
      section: 'footer',
      style: 'cta primary',
      text: `[connect-url-builder] ${staticText.buttons.launch}`,
      path: getConnectUrl(urlValues),
    });
  };

  useEffect(() => {
    fetchApplicationMakes();
    fetchSupportedCountries();
    reportToSegment(types.PAGE, 'Connect Playground');
  }, []);

  const disableNextButton = (activeStep === 0 && urlValues.redirectUri === '') ||
    (activeStep === 1 && urlValues.scope.length === 0);

  return (
    <Box display="flex">
      <Box margin="0 auto">
        <ApplicationHeader heading={staticText.heading} />
        <InlineLinks text={staticText.description} externalLink />
        <PlaygroundContainer>
          <UrlBuilderContainer>
            <Box p={3}>
              <Typography variant="h2">{staticText.subheading}</Typography>
              <Stepper
                steps={staticText.stepperSections}
                activeStep={activeStep}
                handleStep={handleStep}
                forceChecked={activeStep === 2}
              />
            </Box>
            {activeStep === 1 && (
              <PermissionsHeader />
            )}
            {activeStep === 2 && (
              <Box mt={2} ml={3} mb={2} width="70%">
                <Typography>
                  <InlineStyledText text={staticText.form.advanced.description} />
                </Typography>
              </Box>
            )}
            <FormFields id="connect-playground-form">
              {activeStep === 0 &&
                <BasicSettings
                  applicationId={applicationId}
                  appRedirectUris={redirectUris}
                  urlValues={urlValues}
                  handleChange={handleChange}
                  updateApplication={updateApplication}
                  canWriteConnectConfig={rolePermissions.includes('write_connect_configuration')}
                />
              }
              {activeStep === 1 &&
                <Permissions selectedPermissions={urlValues.scope} handleChange={handleChange} />
              }
              {activeStep === 2 &&
                <AdvancedSettings
                  urlValues={urlValues}
                  handleChange={handleChange}
                  applicationMakes={applicationMakes}
                  canWriteBilling={canWriteBilling}
                  advancedIsGated={advancedIsGated}
                  supportedCountries={supportedCountries}
                />
              }
            </FormFields>
            <Footer>
              <div>
                {activeStep === 1 && (
                  <PermissionCount>
                    {`${urlValues.scope.length} ${staticText.form.permissions.selected}`}
                  </PermissionCount>
                )}
              </div>
              <ButtonsContainer>
                <Button variant="outlined" onClick={() => handleStepChange('back')} disabled={!showBackButton}>{staticText.buttons.back}</Button>
                {isLastStep ? (
                  <LaunchLink href={getConnectUrl(urlValues)} onClick={handleLaunch} target="_blank" rel="noopener noreferrer">
                    {staticText.buttons.launch}
                    <LaunchIcon sx={{ marginLeft: 1 }} fontSize="small" />
                  </LaunchLink>
                ) : (
                  <Button variant="contained" onClick={() => handleStepChange('next')} disabled={disableNextButton}>{staticText.buttons.next}</Button>
                )}
              </ButtonsContainer>
            </Footer>
          </UrlBuilderContainer>
          <UrlViewer urlValues={urlValues} />
        </PlaygroundContainer>
      </Box>
    </Box>
  );
};

export default ConnectPlayground;

ConnectPlayground.propTypes = {
  applicationId: string.isRequired,
  redirectUris: arrayOf(string).isRequired,
  applicationMakes: arrayOf(shape({
    name: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  supportedCountries: arrayOf(string).isRequired,
  actions: shape({
    updateApplication: func.isRequired,
  }).isRequired,
  rolePermissions: arrayOf(string).isRequired,
  featureSetId: string.isRequired,
};
