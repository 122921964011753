import _ from 'lodash';

export const types = {
  REGISTER_APPLICATION: 'developer-client/applications/REGISTER_APPLICATION',
  REGISTER_APPLICATION_SUCCESS: 'developer-client/applications/REGISTER_APPLICATION_SUCCESS',
  REGISTER_APPLICATION_FAILURE: 'developer-client/applications/REGISTER_APPLICATION_FAILURE',

  FETCH_APPLICATIONS: 'developer-client/applications/FETCH_APPLICATIONS',
  FETCH_APPLICATIONS_SUCCESS: 'developer-client/applications/FETCH_APPLICATIONS_SUCCESS',
  FETCH_APPLICATIONS_FAILURE: 'developer-client/applications/FETCH_APPLICATIONS_FAILURE',

  UPDATE_APPLICATION: 'developer-client/Application/UPDATE_APPLICATION',
  UPDATE_APPLICATION_SUCCESS: 'developer-client/Application/UPDATE_APPLICATION_SUCCESS',
  UPDATE_APPLICATION_FAILURE: 'developer-client/Application/UPDATE_APPLICATION_FAILURE',

  DELETE_APPLICATION: 'developer-client/Application/DELETE_APPLICATION',
  DELETE_APPLICATION_SUCCESS: 'developer-client/Application/DELETE_APPLICATION_SUCCESS',
  DELETE_APPLICATION_FAILURE: 'developer-client/Application/DELETE_APPLICATION_FAILURE',

  INITIALIZE_APPLICATIONS: 'developer-client/Application/INITIALIZE_APPLICATIONS',
  INITIALIZE_APPLICATIONS_SUCCESS: 'developer-client/Application/INITIALIZE_APPLICATIONS_SUCCESS',
  INITIALIZE_APPLICATIONS_FAILURE: 'developer-client/Application/INITIALIZE_APPLICATIONS_FAILURE',

  REGENERATE_MANAGEMENT_TOKEN: 'developer-client/ManagementToken/REGENERATE_MANAGEMENT_TOKEN',
  REGENERATE_MANAGEMENT_TOKEN_SUCCESS: 'developer-client/ManagementToken/REGENERATE_MANAGEMENT_TOKEN_SUCCESS',
  REGENERATE_MANAGEMENT_TOKEN_FAILURE: 'developer-client/ManagementToken/REGENERATE_MANAGEMENT_TOKEN_FAILURE',
  HIDE_MANAGEMENT_TOKEN: 'developer-client/ManagementToken/HIDE_MANAGEMENT_TOKEN',

  UPDATE_LOCKED_APPLICATIONS: 'developer-client/applications/UPDATE_LOCKED_APPLICATIONS',
  UPDATE_LOCKED_APPLICATIONS_SUCCESS: 'developer-client/applications/UPDATE_LOCKED_APPLICATIONS_SUCCESS',
  UPDATE_LOCKED_APPLICATIONS_FAILURE: 'developer-client/applications/UPDATE_LOCKED_APPLICATIONS_FAILURE',

  FETCH_LOCKED_APPLICATIONS: 'developer-client/applications/FETCH_LOCKED_APPLICATIONS',
  FETCH_LOCKED_APPLICATIONS_SUCCESS: 'developer-client/applications/FETCH_LOCKED_APPLICATIONS_SUCCESS',
  FETCH_LOCKED_APPLICATIONS_FAILURE: 'developer-client/applications/FETCH_LOCKED_APPLICATIONS_FAILURE',

  CLEAR_APPLICATIONS: 'developer-client/Application/CLEAR_APPLICATIONS',
  // CAUTION: this action will trigger a reset of all app-specific states
  SELECT_APPLICATION: 'developer-client/Application/SELECT_APPLICATION',
};

export const initialState = {
  isRegistering: false,
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
  isInitializing: false,
  applications: {},
  applicationsErrorMessage: '',
  selectedApplication: null,
  customAppName: '',
  managementToken: null,
  managementTokenErrorMessage: '',
  lockedApplications: [],
  lockedApplicationsErrorMessage: '',
};

export const actions = {
  registerApplication: applicationObj => ({
    type: types.REGISTER_APPLICATION,
    payload: applicationObj,
    meta: null,
  }),
  registerApplicationSuccess: (applicationId, newApplication) => ({
    type: types.REGISTER_APPLICATION_SUCCESS,
    payload: newApplication,
    meta: applicationId,
  }),
  registerApplicationFailure: error => ({
    type: types.REGISTER_APPLICATION_FAILURE,
    payload: error,
    meta: null,
  }),
  fetchApplications: () => ({
    type: types.FETCH_APPLICATIONS,
    payload: null,
    meta: null,
  }),
  fetchApplicationsSuccess: applications => ({
    type: types.FETCH_APPLICATIONS_SUCCESS,
    payload: applications,
    meta: null,
  }),
  fetchApplicationsFailure: error => ({
    type: types.FETCH_APPLICATIONS_FAILURE,
    payload: error,
    meta: null,
  }),
  updateApplication: (applicationId, updatedApp) => ({
    type: types.UPDATE_APPLICATION,
    payload: updatedApp,
    meta: applicationId,
  }),
  updateApplicationSuccess: (applicationId, updatedApplication) => ({
    type: types.UPDATE_APPLICATION_SUCCESS,
    payload: updatedApplication,
    meta: applicationId,
  }),
  updateApplicationFailure: error => ({
    type: types.UPDATE_APPLICATION_FAILURE,
    payload: error,
    meta: null,
  }),
  deleteApplication: applicationId => ({
    type: types.DELETE_APPLICATION,
    payload: {},
    meta: applicationId,
  }),
  deleteApplicationSuccess: applicationId => ({
    type: types.DELETE_APPLICATION_SUCCESS,
    payload: {},
    meta: applicationId,
  }),
  deleteApplicationFailure: (applicationId, error) => ({
    type: types.DELETE_APPLICATION_FAILURE,
    payload: error,
    meta: applicationId,
  }),
  clearApplications: () => ({
    type: types.CLEAR_APPLICATIONS,
    payload: null,
    meta: null,
  }),
  selectApplication: applicationId => ({
    type: types.SELECT_APPLICATION,
    payload: applicationId,
    meta: null,
  }),
  initializeApplications: () => ({
    type: types.INITIALIZE_APPLICATIONS,
    payload: null,
    meta: null,
  }),
  initializeApplicationsSuccess: () => ({
    type: types.INITIALIZE_APPLICATIONS_SUCCESS,
    payload: null,
    meta: null,
  }),
  initializeApplicationsFailure: () => ({
    type: types.INITIALIZE_APPLICATIONS_FAILURE,
    payload: null,
    meta: null,
  }),
  regenerateManagementToken: () => ({
    type: types.REGENERATE_MANAGEMENT_TOKEN,
    payload: null,
    meta: null,
  }),
  regenerateManagementTokenSuccess: managementToken => ({
    type: types.REGENERATE_MANAGEMENT_TOKEN_SUCCESS,
    payload: managementToken,
    meta: null,
  }),
  regenerateManagementTokenFailure: error => ({
    type: types.REGENERATE_MANAGEMENT_TOKEN_FAILURE,
    payload: error,
    meta: null,
  }),
  hideManagementToken: () => ({
    type: types.HIDE_MANAGEMENT_TOKEN,
    payload: null,
    meta: null,
  }),
  updateLockedApplications: updatedLockedApplications => ({
    type: types.UPDATE_LOCKED_APPLICATIONS,
    payload: updatedLockedApplications,
  }),
  updateLockedApplicationsSuccess: updatedLockedApplications => ({
    type: types.UPDATE_LOCKED_APPLICATIONS_SUCCESS,
    payload: updatedLockedApplications,
    meta: null,
  }),
  updateLockedApplicationsFailure: () => ({
    type: types.UPDATE_LOCKED_APPLICATIONS_FAILURE,
    payload: null,
    meta: null,
  }),
  fetchLockedApplications: () => ({
    type: types.FETCH_LOCKED_APPLICATIONS,
    payload: null,
    meta: null,
  }),
  fetchLockedApplicationsSuccess: lockedApplications => ({
    type: types.FETCH_LOCKED_APPLICATIONS_SUCCESS,
    payload: lockedApplications,
    meta: null,
  }),
  fetchLockedApplicationsFailure: error => ({
    type: types.FETCH_LOCKED_APPLICATIONS_FAILURE,
    payload: error,
    meta: null,
  }),
};

export const selectors = {
  getAppName: (state) => {
    return _.get(state, ['applications', 'customAppName']);
  },
  getApplications: (state) => {
    return _.get(state, ['applications', 'applications']);
  },
  getSelectedApplication: (state) => {
    return _.get(state, ['applications', 'selectedApplication']);
  },
  getCreatedAt: (state) => {
    const selectedApplication = _.get(state, [
      'applications',
      'selectedApplication',
    ]);
    return _.get(state, [
      'applications',
      'applications',
      selectedApplication,
      'createdAt',
    ]);
  },
  getLockedApplications: (state) => {
    return _.get(state, ['applications', 'lockedApplications']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REGISTER_APPLICATION: {
      return {
        ...state,
        isRegistering: true,
        applicationsErrorMessage: '',
      };
    }
    case types.REGISTER_APPLICATION_SUCCESS: {
      const oldApplications = _.cloneDeep(state.applications);
      const newApplications = _.set(oldApplications, action.meta, action.payload);
      return {
        ...state,
        isRegistering: false,
        applications: newApplications,
      };
    }
    case types.REGISTER_APPLICATION_FAILURE: {
      return {
        ...state,
        isRegistering: false,
        applicationsErrorMessage: (action.payload && action.payload.message) || 'Error registering application',
      };
    }
    case types.FETCH_APPLICATIONS: {
      return {
        ...state,
        isFetching: true,
        applicationsErrorMessage: '',
      };
    }
    case types.FETCH_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        applications: action.payload.applications,
      };
    }
    case types.FETCH_APPLICATIONS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        applicationsErrorMessage: (action.payload && action.payload.message) || 'Error fetching applications',
      };
    }
    case types.UPDATE_APPLICATION: {
      return {
        ...state,
        isUpdating: true,
        applicationsErrorMessage: '',
      };
    }
    case types.UPDATE_APPLICATION_SUCCESS: {
      const oldApplications = _.cloneDeep(state.applications);
      const newApplications = _.set(oldApplications, action.meta, action.payload);
      return {
        ...state,
        isUpdating: false,
        applications: newApplications,
      };
    }
    case types.UPDATE_APPLICATION_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        applicationsErrorMessage: (action.payload && action.payload.message) || 'Error updating application',
      };
    }
    case types.DELETE_APPLICATION: {
      return {
        ...state,
        isDeleting: true,
        applicationsErrorMessage: '',
      };
    }
    case types.DELETE_APPLICATION_SUCCESS: {
      const oldApplications = _.cloneDeep(state.applications);
      const newApplications = _.omit(oldApplications, [action.meta]);
      return {
        ...state,
        isDeleting: false,
        applications: newApplications,
        applicationsErrorMessage: '',
      };
    }
    case types.DELETE_APPLICATION_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        applicationsErrorMessage: (action.payload && action.payload.message) || 'Error deleting application',
      };
    }
    /**
     * TODO: verify that this action can be removed if not used
     */
    case types.CLEAR_APPLICATIONS:
      return {
        ...initialState,
        selectedApplication: state.selectedApplication,
      };
    case types.INITIALIZE_APPLICATIONS:
      return {
        ...state,
        isInitializing: true,
        applicationsErrorMessage: '',
      };
    case types.INITIALIZE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isInitializing: false,
      };
    case types.INITIALIZE_APPLICATIONS_FAILURE:
      return {
        ...state,
        isInitializing: false,
        applicationsErrorMessage: 'Error initializing applications',
      };
    case types.SELECT_APPLICATION:
      return {
        ...state,
        selectedApplication: action.payload,
      };
    case types.REGENERATE_MANAGEMENT_TOKEN: {
      return {
        ...state,
        managementTokenErrorMessage: '',
      };
    }
    case types.REGENERATE_MANAGEMENT_TOKEN_SUCCESS: {
      return {
        ...state,
        managementToken: action.payload,
      };
    }
    case types.REGENERATE_MANAGEMENT_TOKEN_FAILURE: {
      return {
        ...state,
        managementTokenErrorMessage: (action.payload && action.payload.message) || 'Error regenerating management token',
      };
    }
    case types.HIDE_MANAGEMENT_TOKEN: {
      return {
        ...state,
        managementToken: null,
      };
    }
    case types.UPDATE_LOCKED_APPLICATIONS: {
      return {
        ...state,
        lockedApplicationsErrorMessage: '',
      };
    }
    case types.UPDATE_LOCKED_APPLICATIONS_SUCCESS: {
      const { lockedApplications } = action.payload;
      return {
        ...state,
        lockedApplications,
      };
    }
    case types.UPDATE_LOCKED_APPLICATIONS_FAILURE: {
      return {
        ...state,
        lockedApplicationsErrorMessage: (action.payload && action.payload.message) || 'Error updating locked applications',
      };
    }
    case types.FETCH_LOCKED_APPLICATIONS: {
      return {
        ...state,
        isFetching: true,
        applicationsErrorMessage: '',
      };
    }
    case types.FETCH_LOCKED_APPLICATIONS_SUCCESS: {
      const { lockedApplications } = action.payload;
      return {
        ...state,
        isFetching: false,
        lockedApplications,
      };
    }
    case types.FETCH_LOCKED_APPLICATIONS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        applicationsErrorMessage: (action.payload && action.payload.message) || 'Error fetching applications',
      };
    }
    default:
      return state;
  }
}
