import React from 'react';
import { string } from 'prop-types';
import { styled } from '@mui/material';

import { unallowedBrandLogos } from '../../scenes/Application/utils';
import getBrandLogo from '../../scenes/Application/components/Simulator/utils/brandLogoMap';

const Logo = styled('div', {
  shouldForwardProp: prop => prop !== 'margin',
})(({ margin, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  minWidth: '40px',
  height: '40px',
  borderRadius: '50%',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  margin,

  '& img': {
    maxHeight: '30px',
    maxWidth: '36px',
  },

  '& .logo-fallback': {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    fontFamily: theme.typography.bold.fontFamily,
    marginBottom: '-1px',
  },
}));

const BrandLogo = ({ make, margin }) => {
  return (
    <Logo margin={margin}>
      {unallowedBrandLogos.includes(make) ? (
        <div className="logo-fallback">{make[0]}</div>
      ) : (
        <img src={getBrandLogo(make)} alt={make} />
      )}
    </Logo>
  );
};

export default BrandLogo;

BrandLogo.propTypes = {
  make: string.isRequired,
  margin: string,
};

BrandLogo.defaultProps = {
  margin: '0 8px 0 0',
};
