import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BusinessOption = styled(Button, {
  shouldForwardProp: prop => prop !== 'selected',
})(({ theme, selected }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  color: theme.palette.common.black,
  padding: theme.spacing(1.5, 2),
  width: '100%',
  justifyContent: 'start',

  ...(!selected && {
    '&:hover, &:focus, &:active': {
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
    },
  }),

  ...(selected && {
    border: `2px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.common.black} !important`,
    backgroundColor: '#80C0CE1A',

    '&:hover, &:focus, &:active': {
      textDecoration: 'none',
    },
  }),
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  alignSelf: 'flex-end',
  padding: theme.spacing(1.5, 2),
  fontSize: '1.125rem',

  '&:disabled': {
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
  },
}));
