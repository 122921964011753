import React from 'react';
import { CheckRounded } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { string } from 'prop-types';

import { alertTriangleIconYellow } from '../../../../../../assets/icons';
import {
  Alert,
  Border,
  Card,
  Cardset,
  Container,
  Header,
  Highlights,
  Subtitle,
  Addendum,
} from './styles';
import { Section } from '../../styles';
import staticText from '../../../../../../localization/Application/Billing/legacyCard';

const LegacyCard = ({ metronomePlanName }) => {
  const planStaticText = staticText[metronomePlanName];

  if (!planStaticText) {
    return null;
  }

  return (
    <Section>
      <Container>
        <Header>
          <Typography variant="h2">{planStaticText.heading}</Typography>
          <Tooltip title={planStaticText.tooltip} arrow placement="top">
            <Alert src={alertTriangleIconYellow} alt="alert" />
          </Tooltip>
        </Header>
        <Cardset>
          <Card>
            <Typography variant="h1">
              {planStaticText.pricing}
              <Addendum variant="subtitle2" component="span">
                {planStaticText.pricingAddendum}
              </Addendum>
            </Typography>
          </Card>
          <Border />
          <Card>
            <Highlights>
              {planStaticText.highlights.map(highlight => (
                <Subtitle variant="body1" key={highlight.text}>
                  <CheckRounded color="success" fontSize="medium" />
                  {highlight.text}
                </Subtitle>
            ))}
            </Highlights>
          </Card>
          <Border />
          <Card>
            <Subtitle variant="body1">{planStaticText.feature}</Subtitle>
          </Card>
        </Cardset>
      </Container>
    </Section>
  );
};

export default LegacyCard;

LegacyCard.propTypes = {
  metronomePlanName: string.isRequired,
};
