import dateUtils from '../Application/components/Overview/utils/dateUtils';
import { gatedFeatureData } from '../../services/featureGate';

export const unallowedBrandLogos = ['Audi', 'Volkswagen'];

export const getOrgApps = (applications, organizationId) => {
  return Object.keys(applications).reduce((acc, appId) => {
    if (applications[appId].organizationId === organizationId) {
      acc[appId] = applications[appId];
    }
    return acc;
  }, {});
};

export const capitalizeFirstLetter = (text) => {
  return text[0].toUpperCase() + text.slice(1);
};

const DEFAULT_LOG_RANGE = 2;

export const getLogRestrictions = (featureSetId, logType) => {
  const logDays = Number(gatedFeatureData(logType, featureSetId)) || 1;
  const maxDate = dateUtils.getStartOfDayUtc().toISOString();
  const minDate = dateUtils.subtract(maxDate, logDays, 'days').toISOString();
  let defaultStartDate = dateUtils.subtract(maxDate, DEFAULT_LOG_RANGE, 'days').toISOString();
  if (dateUtils.isBefore(defaultStartDate, minDate)) {
    defaultStartDate = minDate;
  }

  const hasExportAccess = !gatedFeatureData('logExport', featureSetId);

  return {
    logDays,
    maxDate,
    minDate,
    defaultStartDate,
    hasExportAccess,
  };
};

// mainly validates date range for now
const SMARTCAR_LOG_LIMIT = '2023-11-29T00:00:00.000Z';

export const exceededStartLimit = (startDate) => {
  return dateUtils.isBefore(startDate, SMARTCAR_LOG_LIMIT);
};

export const alphabetize = (a, b) => {
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  if (lowerA === lowerB) return 0;
  return lowerA < lowerB ? -1 : 1;
};


// Sets most recent orgId/AppId in local storage for a given dashboardUserId
// This will be used to load the most recent org/app in the dashboard
export const updateLocalStorageWithMostRecentApp = ({
  selectedOrganization,
  applicationId,
  userContext,
}) => {
  const existingRecentApp = localStorage.getItem('recentApp');

  const recentApp = {
    ...(existingRecentApp && JSON.parse(existingRecentApp)),
    [userContext.dashboardUserId]: {
      orgId: selectedOrganization,
      appId: applicationId,
    },
  };
  localStorage.setItem('recentApp', JSON.stringify(recentApp));
};
