import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConnectPlayground from './ConnectPlayground';
import { actions as applicationsActions } from '../../../../services/applications/reducers';
import { actions } from './reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);
  return {
    ...state.connectPlayground,
    applicationId: state.applications.selectedApplication,
    redirectUris:
      state.applications.applications[state.applications.selectedApplication].redirectUris,
    rolePermissions: currentOrganization.rolePermissions,
    featureSetId: currentOrganization.featureSetId,
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchApplicationMakes, fetchSupportedCountries } = actions;
  const { updateApplication } = applicationsActions;

  return {
    actions: bindActionCreators({
      fetchApplicationMakes,
      fetchSupportedCountries,
      updateApplication,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectPlayground);
