import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { func, node, string } from 'prop-types';

const ActionList = ({
  onClear,
  onAccept,
  className,
  message,
  customActionBar,
}) => {
  const actions = [
    { text: 'Clear', method: onClear },
    { text: 'Accept', method: onAccept },
  ];

  return (
    <div className={className}>
      <Box display="flex" width="100%" flexDirection="column">
        {message &&
          <Typography mt={2} mb={2} alignSelf="center">
            {message}
          </Typography>}
        <Box alignSelf={customActionBar ? '' : 'flex-end'}>
          {customActionBar || actions.map(action => (
            <Button
              key={action.text}
              variant="text"
              onClick={action.method}
            >
              {action.text}
            </Button>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default ActionList;

ActionList.propTypes = {
  message: string,
  onClear: func.isRequired,
  onAccept: func.isRequired,
  className: string.isRequired,
  customActionBar: node,
};

ActionList.defaultProps = {
  message: '',
  customActionBar: null,
};
