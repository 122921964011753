import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { bool, func, number, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { InlineStyledText, Modal } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/VehicleManagement/disconnectModal';

const DisconnectModal = ({
  closeModal,
  disabled,
  disconnectVehicle,
  vehicle,
}) => {
  const {
    make, model, year, vehicleId,
  } = vehicle;

  const handleDisconnect = () => {
    disconnectVehicle(vehicleId);
    closeModal();
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      {
        label: 'backend action',
        text: `[vehicles] ${staticText.confirm}`,
      },
    );
  };

  const descriptions = (disabled ? staticText.disabledDescription : staticText.description.replaceAll('{{selectedVehicle}}', `${year} ${make} ${model}`)).split('\n');

  const content = (
    <div>
      <Box mt={1} mb={3}>
        {descriptions.map(text => (
          <Typography key={text}>
            <InlineStyledText text={text} />
          </Typography>
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={3} mt={5}>
        <Button
          variant="outlined"
          color="error"
          disabled={disabled}
          size="large"
          onClick={handleDisconnect}
          id="confirm"
          sx={{ padding: '10px 16px' }}
        >
          {staticText.confirm}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={closeModal}
          id="cancel"
          sx={{ padding: '10px 16px' }}
        >
          {staticText.cancel}
        </Button>
      </Box>
    </div>
  );
  return (
    <Modal
      title={staticText.title}
      content={content}
      toggleModal={closeModal}
    />
  );
};

export default DisconnectModal;

DisconnectModal.propTypes = {
  closeModal: func.isRequired,
  disabled: bool,
  disconnectVehicle: func.isRequired,
  vehicle: shape({
    vehicleId: string.isRequired,
    make: string.isRequired,
    model: string.isRequired,
    year: number.isRequired,
  }).isRequired,
};

DisconnectModal.defaultProps = {
  disabled: false,
};
