import { styled, Typography } from '@mui/material';

const checkboxColWidth = '60px';
const permissionNameColWidth = '237px'; // update to match longest permission

export const PermissionTableHeader = styled('table')(({ theme }) => ({
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
  borderTopLeftRadius: theme.border.radius,
  borderTopRightRadius: theme.border.radius,
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  borderLeft: `1px solid ${theme.palette.grey[200]}`,
  borderRight: `1px solid ${theme.palette.grey[200]}`,

  '& th': {
    padding: theme.spacing(2),
    color: theme.palette.grey[600],
    textAlign: 'left',
    position: 'sticky',
    backgroundColor: theme.palette.common.white,
    top: 0,
    zIndex: 1,
    boxSizing: 'border-box',

    '&:first-child': {
      padding: theme.spacing(2, 0, 2, 3),
      borderTopLeftRadius: theme.border.radius,
      width: checkboxColWidth,
    },

    '&:nth-child(2)': {
      width: permissionNameColWidth,
    },

    '&:last-child': {
      borderTopRightRadius: theme.border.radius,
    },
  },
}));

export const PermissionTableBody = styled('table')(({ theme }) => ({
  marginTop: theme.spacing(-1),
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
  borderBottomLeftRadius: theme.border.radius,
  borderBottomRightRadius: theme.border.radius,
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  borderLeft: `1px solid ${theme.palette.grey[200]}`,
  borderRight: `1px solid ${theme.palette.grey[200]}`,

  '& td': {
    padding: theme.spacing(2),
    color: theme.palette.grey[600],
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    boxSizing: 'border-box',

    '&:first-child': {
      padding: theme.spacing(2, 0, 2, 3),
      width: checkboxColWidth,
    },

    '&:nth-child(2)': {
      width: permissionNameColWidth,
    },
  },

  '& tbody tr:first-child td:first-child': {
    borderTopLeftRadius: 0,
  },

  '& tbody tr:first-child td:last-child': {
    borderTopRightRadius: 0,
  },
  '& tbody tr:last-child td:first-child': {
    borderBottomLeftRadius: theme.border.radius,
  },

  '& tbody tr:last-child td:last-child': {
    borderBottomRightRadius: theme.border.radius,
  },
}));


export const PermissionHeader = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.fontFamily,
  fontSize: theme.typography.tableData.fontSize,
}));

export const PermissionName = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.monospace,
  fontSize: theme.typography.fontSizes.xs,
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.text.primary,
  padding: theme.spacing(0.5, 1),
  display: 'inline-block',
  borderRadius: theme.border.radius,
  border: `1px solid ${theme.palette.grey[200]}`,
}));

export const PermissionDescription = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.tableData.fontSize,
  color: theme.palette.text.primary,
}));
