import React from 'react';
import { func, shape, string } from 'prop-types';
import { styled } from '@mui/material/styles';

import PlanFeatures from '../PlanFeatures/PlanFeatures';
import { Accordion } from '..';
import { Section } from '../../../../styles';

const MultiSectionWrapper = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));


const SingleSectionWrapper = styled('div')(({ theme }) => ({
  borderLeft: `2px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(0, 0, 2, 3),
}));

const FeatureSection = ({ category, billingInfo, setCurrentModal }) => {
  const featuresPlanName = billingInfo.metronomePlanName === 'Business (Legacy)' ?
    'build' :
    billingInfo.planName;

  return (
    <Accordion title={category.title}>
      {category.sections.length > 1 &&
          category.sections.map(section => (
            <Section key={section.title} sx={{ padding: '0 2rem' }}>
              <Accordion
                title={section.title}
                headerStyles={{ h2: { fontSize: '14px' } }}
              >
                <MultiSectionWrapper>
                  <PlanFeatures
                    key={`${section.title}-features`}
                    planName={featuresPlanName}
                    planFeatures={section}
                    setCurrentModal={setCurrentModal}
                  />
                </MultiSectionWrapper>
              </Accordion>
            </Section>
        ))
      }

      {category.sections.length === 1 &&
        <SingleSectionWrapper>
          <PlanFeatures
            key={`${category.title}-features`}
            planName={featuresPlanName}
            planFeatures={category.sections[0]}
            setCurrentModal={setCurrentModal}
          />
        </SingleSectionWrapper>
      }
    </Accordion>
  );
};

export default FeatureSection;

FeatureSection.propTypes = {
  category: shape({
    title: string.isRequired,
  }).isRequired,
  billingInfo: shape({
    planName: string.isRequired,
  }).isRequired,
  setCurrentModal: func.isRequired,
};
