import { FormControlLabel, styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const CardOption = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `2px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  ':not(.disabled):hover, &.checked': {
    backgroundColor: '#f3f8fb', // TODO: extract color
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.black,
  },
  '&:hover': {
    boxShadow: theme.border.boxShadow3,
  },
}));

export const CardLabel = styled(FormControlLabel)(() => ({
  margin: 0,

  '>.MuiFormControlLabel-label': {
    width: '100%',
  },
}));
