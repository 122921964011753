const staticText = {
  heading: 'Configuration',
  authentication: {
    heading: 'Authentication',
    applicationName: 'Application name',
    privacyPolicyUrl: 'Privacy policy URL',
    buttonText: 'Save',
  },
  oauth: {
    heading: 'OAuth',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    clientSecretInfo: 'Please store your client secret in a safe place. For security purposes, we will not show it again.',
    generate: 'Generate',
    regenerate: 'Regenerate',
    redirectUris: {
      heading: 'Redirect URIs',
      emptyState: {
        text: 'You haven’t configured any redirect URIs yet. Add a redirect URI below to enable the Smartcar authorization flow in your application.',
        image: '/assets/graphics/SadCar.svg',
      },
      delete: 'Delete',
      save: 'Save',
      limitMessage: 'Applications are allowed 16 redirect URIs. Please remove a URI from above in order to add a new one.',
    },
  },
  management: {
    heading: 'Management',
    managementApiToken: 'Application management token',
    regenerateModalTitle: 'Regenerate application management token',
    managementTokenInfo: 'Please store your management token in a safe place. For security purposes, we will not show it again.',
  },
  delete: {
    heading: 'Delete Application',
    text: 'Once you delete an application, you will not be able to access it again.',
    buttonText: 'Delete application',
  },
};

export default staticText;
