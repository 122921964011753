import { styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const Section = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius2,
  padding: theme.spacing(3, 5),
  marginBottom: theme.spacing(3),
  boxShadow: 'none',
  border: `1px solid ${theme.palette.grey[200]}`,
}));
