import { Button, styled } from '@mui/material';

export const UrlViewerContainer = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: '#000',
  borderRadius: theme.border.radius2,
  boxShadow: theme.border.boxShadow,
  maxWidth: '450px',
  padding: theme.spacing(2, 3, 3, 3),
  minHeight: '300px',
  color: theme.palette.common.white,
}));

export const UrlViewerContent = styled('div')(({ theme }) => ({
  '& .heading': {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: '.875rem',
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(3),

    '& span': {
      position: 'relative',
      padding: theme.spacing(0, 2, 1.5),

      '&:after': {
        position: 'absolute',
        content: '""',
        width: '100%',
        height: '2px',
        top: 'calc(100% + 1px)',
        left: '0',
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  '& .output': {
    fontFamily: theme.typography.monospace,
    fontSize: theme.typography.fontSizes.xs,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.text.primary}`,

    '& p': {
      lineHeight: '1.5rem',
      margin: '0',
      overflowWrap: 'anywhere',

      '&.missing-param': {
        color: theme.palette.error.main,
      },
    },

  },
}));

export const CopyButton = styled(Button)(({ theme }) => ({
  height: '48px',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.text.disabled,
    opacity: 1,
  },
}));
