const staticText = {
  title: 'Business tax rate',
  header: 'Are you using Smartcar for your business?',
  description: 'If you are using Smartcar for your business, it is recommended to enter your tax ID to calculate the appropriate tax rate.',
  options: [
    {
      label: 'Yes, for business use',
      value: 'business',
    },
    {
      label: 'No, for personal use',
      value: 'personal',
    },
  ],
  submit: 'Continue',
  error: 'There was an issue with your request. Please try again.',
};

export default staticText;
