import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as applicationSelectors } from '../../../../services/applications/reducers';
import { actions, initialState, selectors, types } from './reducers';

import api from '../../../../services/api/api';
import { Toast } from '../../../../components';
import { messages } from '../../../../localization/Application/VehicleManagement/vehicleManagement';
import formatVehicles from './utils/formatVehicles';

export function* fetchAllCompatibleVehicles() {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchAllCompatibleVehicles,
      applicationId,
    );
    const formattedVehicles = formatVehicles(data);
    yield put(actions.fetchAllCompatibleVehiclesSuccess(formattedVehicles));
  } catch (error) {
    yield put(actions.fetchAllCompatibleVehiclesFailure(error));
  }
}

export function* fetchConnectedVehicles(action) {
  try {
    const { filterValues, newPaginationModel } = action.payload;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchConnectedVehicles,
      applicationId,
      filterValues,
    );

    const results = {
      pageInfo: {
        rows: data.rows,
        columns: data.cols,
        rowCount: data.paging.count,
        freePlanActiveVehicle: data.freePlanActiveVehicle,
      },
    };

    const { cursor, mode, ...filters } = filterValues;
    const noFilters = Object.keys(filters).length === 0;
    const pageZero = newPaginationModel.page === 0;

    const shouldConnectVehicle = noFilters && pageZero && data.rows.length === 0;
    yield put(actions.updateProvideConnectCta(shouldConnectVehicle));

    // Reset countDifference and cursorMap whenever we fetch page 0
    if (pageZero) {
      yield put(actions.updateCursorMap(initialState.pageToNextCursorMap));
      yield put(actions.updateCountDifference(initialState.countDifference));
    }

    yield put(actions.fetchConnectedVehiclesSuccess(results));

    const cursorMap = yield select(selectors.getPageToNextCursorMap);
    yield put(actions.updateCursorMap({
      ...cursorMap,
      [newPaginationModel.page]: data.paging.cursor,
    }));
    yield put(actions.updatePaginationModel(newPaginationModel));
  } catch (error) {
    yield put(actions.fetchConnectedVehiclesFailure(error));
  }
}

export function* disconnectVehicle(action) {
  try {
    const { payload: vehicleId } = action;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { data } = yield call(
      api.disconnectVehicle,
      applicationId,
      vehicleId,
    );
    // server sends success code when no vehicle is found, client will treat that as an error
    if (data.message === 'Vehicle not found') {
      throw new Error(data.message);
    }

    yield call(Toast, messages.disconnectVehicleSuccess, 'success');
    const countDifference = yield select(selectors.getCountDifference);
    yield put(actions.updateCountDifference(countDifference + 1));
    yield put(actions.disconnectVehicleSuccess(data.message));
  } catch (error) {
    yield call(Toast, messages.disconnectVehicleFailure, 'warn');
    yield put(actions.disconnectVehicleFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST, fetchAllCompatibleVehicles);
  yield takeLatest(types.FETCH_CONNECTED_VEHICLES_REQUEST, fetchConnectedVehicles);
  yield takeLatest(types.DISCONNECT_VEHICLE_REQUEST, disconnectVehicle);
}
