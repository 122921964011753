import React from 'react';
import { bool, func } from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { LaunchRounded } from '@mui/icons-material';

import { Section } from '../../styles';
import staticText from '../../../../../../localization/Application/Billing/addOns';
import spinningButtonStyles from '../../../../../../global-styles/animations/spinningButton';


const StatusButton = styled(Button)(() => ({
  height: 'fit-content',
  padding: '8px 16px',
  fontSize: '14px',
}));

const Chip = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})(({ theme, disabled }) => ({
  borderRadius: '4px',
  fontSize: '0.75rem',
  padding: theme.spacing(0.25, 1.25),
  alignContent: 'center',
  lineHeight: 'normal',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,

  ...(disabled && {
    border: `1px solid ${theme.palette.text.disabled}`,
  }),
}));

/**
 *** THIS COMPONENT IS NOT CURRENTLY BEING USED (AWAITING ADD-ON FUNCTIONALITY) ***
 *** AND WILL NEED TO BE UPDATED WHEN ADD-ON FUNCTIONALITY IS ADDED ***
 *** FOR NOW, IT IS DISPLAYING ONE ADD-ON WITH NO FUNCTIONALITY ***
 */
const AddOns = ({
  buttonOnClick,
  buttonIconEnabled,
  isButtonLoading,
}) => {
  const theme = useTheme();
  return (
    <Section>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={1}>
            <Typography variant="h2" fontSize={16} fontWeight={500}>
              {staticText.compatibilityApi.heading}
            </Typography>
            <Chip>
              {staticText.status.enabled}
            </Chip>
          </Box>
          <Typography color={theme.palette.grey[600]} fontSize={14}>
            {staticText.compatibilityApi.description}
          </Typography>
        </Box>

        <StatusButton
          onClick={buttonOnClick}
          color="primary"
          variant="outlined"
          endIcon={buttonIconEnabled ?
            <LaunchRounded fontSize="inherit" /> :
          null}
          sx={isButtonLoading && spinningButtonStyles}
        >
          {staticText.actions.enable}
        </StatusButton>
      </Box>
    </Section>
  );
};

export default AddOns;

AddOns.propTypes = {
  buttonOnClick: func,
  buttonIconEnabled: bool,
  isButtonLoading: bool,
};

AddOns.defaultProps = {
  buttonOnClick: null,
  buttonIconEnabled: false,
  isButtonLoading: false,
};
