import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BusinessTaxModal from './BusinessTaxModal';
import { actions as errorActions } from '../../../../../../services/errorReporter/reducers';

function mapDispatchToProps(dispatch) {
  const { reportError } = errorActions;

  return {
    actions: bindActionCreators({
      reportError,
    },
    dispatch,
    ),
  };
}

export default connect(null, mapDispatchToProps)(BusinessTaxModal);
