import React from 'react';
import { bool, func, string } from 'prop-types';
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';

const Switch = ({
  name, label, checked, onChange, disabled,
}) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiSwitch
          name={name}
          checked={checked}
          onChange={onChange}
          inputProps={{ 'aria-label': label }}
          disabled={disabled}
        />
      }
      disabled={disabled}
    />
  );
};

export default Switch;

Switch.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  checked: bool.isRequired,
  onChange: func.isRequired,
  disabled: bool,
};

Switch.defaultProps = {
  disabled: false,
};
