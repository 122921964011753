import React from 'react';
import {
  Box, Button, List,
  ListItem as MuiListItem, Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { LaunchRounded } from '@mui/icons-material';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { Section } from '../../styles';
import spinningButtonStyles from '../../../../../../global-styles/animations/spinningButton';

const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  fontSize: '14px',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  minHeight: '24px',
  paddingTop: theme.spacing(3),
  paddingBottom: `${theme.spacing(0.75)} !important`, // important to avoid mui last child style
}));

const CTAButton = styled(Button)(() => ({
  padding: '4px 16px',
  fontSize: '14px',
}));

/**
 * Renders an information card from an array of key-value pairs and a call-to-action button
 *
 * @param {Object} props - The component props
 * @param {Array<Object>} props.content - An array of key-value pairs
 * @param {string} props.ctaTextContent - The text content for the call-to-action button
 * @param {Function} props.ctaOnClick - The function to be called when the CTA button is clicked
 * @param {boolean} props.ctaIconEnabled - Toggles CTA button icon
 * @param {boolean} props.isButtonLoading - Toggles the button's spinning animation
 * @returns {React.Element}
 */
const InfoCard = ({
  content,
  ctaTextContent,
  ctaOnClick,
  ctaIconEnabled,
  description,
  header,
  isButtonLoading,
}) => {
  const theme = useTheme();
  return (
    <Section
      sx={{
        flex: 1,
        height: 'fit-content',
        margin: 0,
        padding: 3,
      }}
    >
      <Box display="flex" justifyContent="space-between" marginBottom="1.5rem">
        <Box display="flex" flexDirection="column">
          <Typography variant="h2" fontSize="1rem" fontWeight={500}>
            {header}
          </Typography>
          <Typography color={theme.palette.grey[600]} fontSize={12}>
            {description}
          </Typography>
        </Box>

        <CTAButton
          onClick={ctaOnClick}
          color="primary"
          variant="outlined"
          endIcon={ctaIconEnabled ?
            <LaunchRounded fontSize="inherit" /> :
            null}
          sx={isButtonLoading && spinningButtonStyles}
        >
          {ctaTextContent}
        </CTAButton>
      </Box>

      <List disablePadding>
        {content.map(item => (
          <ListItem
            key={item.key}
            disableGutters
            disablePadding
          >
            <Typography
              variant="h2"
              fontSize={14}
              fontWeight={500}
            >
              {item.key}
            </Typography>
            <div>{item.value}</div>
          </ListItem>
      ))}
      </List>
    </Section>
  );
};

export default InfoCard;

InfoCard.propTypes = {
  content: arrayOf(
    shape({
      key: string.isRequired,
      value: string.isRequired,
    }).isRequired,
  ).isRequired,
  ctaTextContent: string.isRequired,
  ctaOnClick: func.isRequired,
  ctaIconEnabled: bool,
  description: string.isRequired,
  header: string.isRequired,
  isButtonLoading: bool.isRequired,
};

InfoCard.defaultProps = {
  ctaIconEnabled: false,
};
