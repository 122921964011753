import React from 'react';
import { bool, func } from 'prop-types';
import { FormControl } from '@mui/material';

import staticText from '../../../../../../../../localization/Application/connect-config';
import { Switch } from '../../../../../../../../components/Form/components';

const BrandOptIn = ({
  selectedNewBrandOptIn, updateCustomizations, disabled,
}) => {
  const label = staticText.brandManagement.displayNewlyAddedBrands;

  const updateBrandOptIn = () => {
    updateCustomizations({ newBrandOptIn: !selectedNewBrandOptIn });
  };

  return (
    <FormControl sx={{ mb: 3 }} disabled={disabled}>
      <Switch
        checked={selectedNewBrandOptIn}
        onChange={updateBrandOptIn}
        name="newBrandOptIn"
        aria-label="newBrandOptIn"
        label={label}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default BrandOptIn;

BrandOptIn.propTypes = {
  selectedNewBrandOptIn: bool.isRequired,
  updateCustomizations: func.isRequired,
  disabled: bool.isRequired,
};
