import React, { useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { Box, Button, Dialog, DialogContent, TextField, Typography, useTheme } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { URI_LENGTH_LIMIT } from '../../../../../Configuration/components/RedirectUriList/components/RedirectUriForm/RedirectUriForm';
import staticText from '../../../../../../../../localization/Application/connect-playground';
import redirectUriValidator from '../../../../../../../../services/validators/redirectUriValidator';

const AddRedirectUriModal = ({
  clientId,
  redirectUris,
  toggleModal,
  onSubmit,
}) => {
  const theme = useTheme();

  const [uriInputState, setUriInputState] = useState({
    value: '',
    isValid: false,
    inputVisited: false,
    errorMessage: '',
  });

  const handleChange = (e) => {
    const { value: uri } = e.target;
    if (uri.length > URI_LENGTH_LIMIT) {
      setUriInputState({
        value: uri,
        isValid: false,
        inputVisited: true,
        errorMessage: `The uri limit is ${URI_LENGTH_LIMIT} characters`,
      });
      return;
    }
    try {
      redirectUriValidator(uri, clientId, redirectUris);
      setUriInputState(prev => ({
        ...prev, value: uri, isValid: true, errorMessage: '',
      }));
    } catch (err) {
      setUriInputState(prev => ({
        ...prev, value: uri, isValid: false, errorMessage: err.message,
      }));
    }
  };

  const handleBlur = () => {
    setUriInputState(prev => ({
      ...prev,
      inputVisited: true,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(uriInputState.value);
    toggleModal();
  };

  return (
    <Dialog open onClose={toggleModal}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginBottom={2}
            sx={{
              position: 'absolute',
              top: theme.spacing(3),
              right: 0,
            }}
          >
            <Button
              onClick={toggleModal}
              id="add-redirect-uri-modal-cancel"
              type="button"
              sx={{ padding: 0, svg: { fill: theme.palette.common.black } }}
            ><CloseRoundedIcon fontSize="small" color={theme.palette.common.black} />
            </Button>
          </Box>
          <Typography variant="h2" marginBottom={3}>{staticText.form.basic.redirectUri.modal.title}</Typography>
          <TextField
            id="add-redirect-uri-modal-input"
            autoFocus
            label={staticText.form.basic.redirectUri.modal.label}
            placeholder={staticText.form.basic.redirectUri.modal.placeholder}
            variant="outlined"
            error={
              !uriInputState.isValid && uriInputState.inputVisited && uriInputState.value.length > 0
            }
            helperText={
              !uriInputState.isValid && uriInputState.inputVisited && uriInputState.errorMessage
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={uriInputState.value}
            fullWidth
          />
          <Box display="flex" justifyContent="flex-end" marginTop={4}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              id="add-redirect-uri-modal-submit"
              disabled={!uriInputState.isValid}
              type="submit"
            >{staticText.form.basic.redirectUri.modal.button}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddRedirectUriModal;

AddRedirectUriModal.propTypes = {
  clientId: string.isRequired,
  redirectUris: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  toggleModal: func.isRequired,
};
