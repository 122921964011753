import React from 'react';
import { objectOf, string, shape } from 'prop-types';
import { Box, Typography } from '@mui/material';

import CopyButton from '../CopyButton';
import { Secret } from './components';
import staticText from '../../localization/Application/configuration';

const Credentials = ({
  applications,
  selectedApplication,
}) => {
  const application = applications[selectedApplication];
  const {
    id: clientId,
    name,
  } = application;

  return (
    <div>
      <Typography variant="h2" mb={2}>{staticText.oauth.heading}</Typography>
      <Typography variant="h3" mb={2}>{staticText.oauth.clientId}</Typography>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography variant="copyable">{clientId}</Typography>
        <CopyButton content={clientId} />
      </Box>
      <Secret
        applicationId={clientId}
        applicationName={name}
      />
    </div>
  );
};

Credentials.propTypes = {
  applications: objectOf(shape({
    name: string.isRequired,
    id: string.isRequired,
  })).isRequired,
  selectedApplication: string.isRequired,
};

export default Credentials;
