import React from 'react';
import { bool, string } from 'prop-types';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import InlineLinks from '../InlineLinks';

const Banner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.warning.secondary,
  border: `1px solid ${theme.palette.warning.primary}`,
  borderRadius: theme.border.radius2,

  '& img': {
    marginRight: theme.spacing(2),
  },

  '& p': {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.caption.fontSize,
  },
}));

const FeatureGateBanner = ({ canWriteBilling, upgradeText, ownerUpgradeText }) => {
  return (
    <Banner data-testid="feature-gate-banner">
      <img src="/assets/icons/lock.svg" alt="lock icon" />
      <Typography component="p">
        <InlineLinks
          text={canWriteBilling ?
            upgradeText :
            ownerUpgradeText}
        />
      </Typography>
    </Banner>
  );
};

export default FeatureGateBanner;

FeatureGateBanner.propTypes = {
  canWriteBilling: bool.isRequired,
  upgradeText: string.isRequired,
  ownerUpgradeText: string,
};

FeatureGateBanner.defaultProps = {
  ownerUpgradeText: 'Contact your team owner to unlock this feature.',
};
