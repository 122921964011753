import React, { useState } from 'react';
import { node, shape, string } from 'prop-types';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { Collapse, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Header = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 0',

  svg: {
    borderRadius: theme.border.radius,
  },
  ':hover': {
    svg: {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const Accordion = ({ title, children, headerStyles }) => {
  const [sectionOpen, setSectionOpen] = useState(true);

  return (
    <React.Fragment >
      <Header
        onClick={() => setSectionOpen(!sectionOpen)}
        sx={headerStyles}
      >
        <Typography variant="h2" fontSize="1rem" fontWeight={500}>{title}</Typography>
        {sectionOpen ? (
          <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
      </Header>

      <Collapse
        in={sectionOpen}
        timeout="auto"
      >
        {children}
      </Collapse>
    </React.Fragment>
  );
};

export default Accordion;

Accordion.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  headerStyles: shape({
    fontSize: string,
  }),
};

Accordion.defaultProps = {
  headerStyles: {},
};
