import React, { useState } from 'react';
import { bool, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Typography } from '@mui/material';

import secretProps from '../../../../types/secret';
import { setScrollAbility } from '../../../../services/styleUtils';
import staticText from '../../../../localization/Application/configuration';

import RegenerativeField from '../../../RegenerativeField';
import ValidateNameModal from '../../../ValidateNameModal';
import Feedback from '../../../Feedback';

const Secret = ({
  actions: {
    regenerateSecret,
    updateOnboardingEvent,
  },
  applicationId,
  applicationName,
  credentialsEvent,
  secret,
}) => {
  const [open, setOpen] = useState(false);

  const toggleModal = (withTracking = true) => {
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames[`modal${open ? 'Closed' : 'Opened'}`], {
        label: 'regenerate secret',
        title: 'Regenerate client secret',
      });
    }
    setScrollAbility(open);
    setOpen(!open);
  };

  const handleRegenerateSecret = () => {
    regenerateSecret(applicationId);
  };

  const description = (
    <div>
      This will permanently change the client secret of the application
      <Typography variant="bold" component="span"> {applicationName}</Typography>.
      Type your application name in the box below to confirm this change.
    </div>
  );

  const secretWarning = <Feedback message={staticText.oauth.clientSecretInfo} type="info" additionalClassNames="m-t-none" />;

  return (
    <React.Fragment>
      {open && (
        <ValidateNameModal
          actionTitle="Regenerate client secret"
          actionDescription={description}
          applicationName={applicationName}
          submitAction={handleRegenerateSecret}
          toggleModal={toggleModal}
        />
      )}
      <Typography variant="h3" marginBottom={2}>{staticText.oauth.clientSecret}</Typography>
      {secret && secretWarning}
      <RegenerativeField
        content={secret}
        regenerateHandler={toggleModal}
        updateOnboardingEvent={updateOnboardingEvent}
        credentialsEvent={credentialsEvent}
      />
    </React.Fragment>
  );
};

export default Secret;

Secret.propTypes = {
  ...secretProps,
  actions: shape({
    ...secretProps.actions,
  }).isRequired,
  credentialsEvent: shape({
    eventName: string.isRequired,
    eventStatus: bool.isRequired,
  }).isRequired,
};
