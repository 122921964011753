import { call, put, select, takeLatest } from 'redux-saga/effects';

import { selectors as applicationSelectors } from '../../../../services/applications/reducers';
import api from '../../../../services/api/api';
import alphabetizeMakes from '../ConnectCustomizations/utils/alphabetizeMakes';
import { actions, types } from './reducers';

export function* fetchApplicationMakes() {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchApplicationMakes,
      applicationId,
    );

    data.sort(alphabetizeMakes);

    yield put(actions.fetchApplicationMakesSuccess(data));
  } catch (error) {
    yield put(actions.fetchApplicationMakesFailure(error));
  }
}

export function* fetchSupportedCountries() {
  try {
    const { data: countries } = yield call(api.fetchSupportedCountries);

    yield put(actions.fetchSupportedCountriesSuccess(countries));
  } catch (error) {
    yield put(actions.fetchSupportedCountriesFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_APPLICATION_MAKES, fetchApplicationMakes);
  yield takeLatest(types.FETCH_SUPPORTED_COUNTRIES, fetchSupportedCountries);
}
