import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import staticText from '../../../../../../localization/Application/Billing/billing';
import { openChat } from '../../../../../../services/front/front';

const Button = styled(MuiButton, {
  shouldForwardProp: prop =>
    prop !== 'current',
})`
  ${({
    theme, current, variant,
  }) => {
    let color = theme.palette.primary.secondary;
    let backgroundColor = theme.palette.primary.main;
    let hoverBackground = theme.palette.secondary.main;
    let borderColor = '';

    if (variant === 'outlined') {
      borderColor = '';
      color = '';
      backgroundColor = '';
      hoverBackground = '';
    } else if (current) {
      color = theme.palette.common.white;
      backgroundColor = theme.palette.text.disabled;
    }

    return `
      font-size: 1.125rem;
      padding-top: 12px;
      padding-bottom: 12px;
      color: ${color && `${color} !important`};
      background-color: ${backgroundColor && `${backgroundColor} !important`};
      border: ${borderColor && `1px solid ${borderColor} !important`};
      transition: ${theme.transitions.create(['background-color', 'transform'], { duration: theme.transitions.duration.standard })};
      &:enabled:hover {
        background-color:  ${hoverBackground && `${hoverBackground} !important`};
      }
      &:disabled {
        opacity: 1;
      }
    `;
  }}
`;

const PlanButton = ({
  buttonTexts,
  columnPlanName,
  emailVerifiedAt,
  setCurrentModal,
  userPlanName,
  isCurrentPlanLegacy,
}) => {
  const buttonProps = {
    color: 'primary',
    variant: 'contained',
    fullWidth: true,
  };

  let buttonText = 'Current';

  const userPlan = userPlanName.toLowerCase();
  const columnPlan = columnPlanName.toLowerCase();
  const isUserOnColumnPlan = userPlan === columnPlan && !isCurrentPlanLegacy;

  // disable button if user is on the same plan
  if (isUserOnColumnPlan) {
    buttonProps.disabled = true;
    buttonProps.current = true;
    buttonText = buttonTexts.current;

  // if user's current plan is not free, the free plan button should show downgrade
  } else if (columnPlan === staticText.freePlanName) {
    buttonProps.onClick = () => setCurrentModal('downgradeFree');
    buttonProps.variant = 'outlined';

    buttonText = buttonTexts.downgrade;
  } else if (columnPlan === staticText.buildPlanName) {
    // We can remove business logic when there are no more Business (legacy) plans
    if (
      userPlan === staticText.scalePlanName ||
      userPlan === staticText.businessPlanName
    ) {
      buttonProps.onClick = () => setCurrentModal('downgradeBuild');
      buttonProps.variant = 'outlined';

      buttonText = buttonTexts.downgrade;
    } else if (!emailVerifiedAt) {
      buttonProps.onClick = () => setCurrentModal('verifyEmail');
      buttonText = buttonTexts.upgrade;
    } else {
      buttonProps.onClick = () => setCurrentModal({ name: 'businessTax', params: [columnPlan] });

      buttonText = buttonTexts.upgrade;
    }
  } else if (userPlan === staticText.freePlanName &&
    columnPlanName === staticText.salesLedPlanName) {
    buttonProps.onClick = openChat;
    buttonProps.variant = 'outlined';

    buttonText = buttonTexts.contactUs;
  } else {
    buttonProps.onClick = openChat;

    buttonText = buttonTexts.contactUs;
  }

  return (
    <div>
      <Button
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default PlanButton;

PlanButton.propTypes = {
  buttonTexts: shape({
    contactUs: string.isRequired,
    downgrade: string.isRequired,
    upgrade: string.isRequired,
    current: string.isRequired,
  }).isRequired,
  columnPlanName: string.isRequired,
  emailVerifiedAt: string,
  setCurrentModal: func.isRequired,
  userPlanName: string.isRequired,
  isCurrentPlanLegacy: bool.isRequired,
};

PlanButton.defaultProps = {
  emailVerifiedAt: null,
};
