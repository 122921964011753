import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { LaunchRounded, Error } from '@mui/icons-material';
import clsx from 'clsx';

import { AlertSubtitle, AlertTitle, Button, Container, PaymentAlertContent } from './styles';
import api from '../../../../../../services/api/api';
import billingStaticText from '../../../../../../localization/Application/Billing/billing';

const PaymentAlert = ({ organizationId, billingInfo }) => {
  const { stripeCustomerId } = billingInfo;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const getStripePortalURL = async () => {
    setIsButtonLoading(true);
    const URL = (await api.fetchStripeUrl(organizationId, 'portal', stripeCustomerId));
    window.location.href = URL;
  };

  return (
    <Container className={clsx('feedback', 'warn')} >
      <Error sx={{ color: 'error.main', fontSize: '1.5rem', marginTop: '-4px' }} alt="error" />
      <PaymentAlertContent >
        <AlertTitle variant="h4">
          {billingStaticText.paymentError}
        </AlertTitle>
        <AlertSubtitle>
          {billingStaticText.paymentErrorDescription}
        </AlertSubtitle>
        <Button
          onClick={() => getStripePortalURL()}
          variant="containedError"
          endIcon={<LaunchRounded fontSize="inherit" />}
          isButtonLoading={isButtonLoading}
        >
          {billingStaticText.updateBillingInformation}
        </Button>
      </PaymentAlertContent>
    </Container>
  );
};

export default PaymentAlert;

PaymentAlert.propTypes = {
  organizationId: string.isRequired,
  billingInfo: shape({
    stripeCustomerId: string.isRequired,
  }).isRequired,
};
