import React from 'react';
import { func, string, shape } from 'prop-types';

import { InlineStyledText, Modal } from '../../../../../../components';
import ValidateName from '../../../../../../components/ValidateNameModal/components/ValidateName';
import { modalConfig } from '../../utils/webhookModalHelpers';

const modalName = 'delete';

const staticText = {
  description1: 'Would you like to permanently delete the webhook |*|bold@@{{webhookName}}|*|? This action cannot be undone and all information related to this webhook will be lost. Enter the webhook name below to confirm.',
};

const DeleteWebhookModal = (props) => {
  const {
    webhook: {
      name,
      id,
    },
    actions: {
      deleteWebhookRequest,
      toggleWebhookModal,
    },
  } = props;

  const toggleModal = (trackCloseEvent = true) =>
    toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent });

  const submitAction = () => deleteWebhookRequest(id);

  const content = (
    <ValidateName
      nameToMatch={name}
      nameType="webhook"
      submitAction={submitAction}
      toggleModal={toggleModal}
      title={modalConfig[modalName].title}
      description={<InlineStyledText text={staticText.description1.replace('{{webhookName}}', name)} />}
    />
  );

  return <Modal title={modalConfig[modalName].title} content={content} toggleModal={toggleModal} />;
};

export default DeleteWebhookModal;

DeleteWebhookModal.propTypes = {
  webhook: shape({
    name: string.isRequired,
    id: string.isRequired,
  }).isRequired,
  actions: shape({
    deleteWebhookRequest: func.isRequired,
    toggleWebhookModal: func.isRequired,
  }).isRequired,
};
