import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import {
  array,
  bool,
  func,
  objectOf,
  oneOfType,
  number,
  shape,
  string,
  arrayOf,
} from 'prop-types';
import { reportToSegment, types } from '@smartcar/morse';

import staticText from '../../../../localization/Application/ConnectInsights/connectInsights';
import connectPhone from '../../../../assets/images/connect-phone.svg';
import { PAGE_SIZE, initialState } from './reducers';

import { FiltersBar, SessionDetailsDrawer, Statistics } from './components';
import ApplicationHeader from '../ApplicationHeader';
import { LogTable, Spinner } from '../../../../components';

import { EmptyStateContainer, EmptyStateImage } from '../../../../global-styles/components';

import { useDelay } from '../../../../hooks';

import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';


const formatFilters = (filters) => {
  return Object.keys(filters).reduce((acc, filter) => {
    if (filters[filter]) {
      acc[filter] = filters[filter];
    }
    return acc;
  }, {});
};

const ConnectInsights = ({
  actions: {
    fetchConnectEventsRequest,
    fetchConnectFunnelRequest,
    updateFilterValues,
  },
  connectInsightsFetching,
  eventsTablePageInfo,
  funnel,
  featureSetId,
  filterValues,
  paginationModel,
  pageToNextCursorMap,
}) => {
  const theme = useTheme();
  const { afterDelay: showLoading, resetTimer } = useDelay(300);
  const [currentDrawer, setCurrentDrawer] = useState(null);

  useEffect(() => {
    reportToSegment(types.PAGE, 'Connect');
  }, []);

  const funnelGated = !!gatedFeatureData(FEATURES.CONNECT_FUNNEL, featureSetId);

  const getPageInfo = (page, filters) => {
    const cursor = pageToNextCursorMap[page - 1];
    if (page === 0 || cursor) {
      const formattedFilterValues = {
        ...formatFilters(filters),
        limit: PAGE_SIZE,
        ...(cursor && { cursor }),
      };

      fetchConnectEventsRequest({
        filterValues: formattedFilterValues,
        newPaginationModel: {
          page,
          pageSize: PAGE_SIZE,
        },
      });
    }
  };

  const getFunnel = (filters) => {
    if (funnelGated) return;
    fetchConnectFunnelRequest(formatFilters(filters));
  };

  useEffect(() => {
    getPageInfo(paginationModel.page, filterValues);
    getFunnel(filterValues);
  }, []);

  const handleResetAll = () => {
    getPageInfo(0, initialState.filterValues);
    getFunnel(initialState.filterValues);
    updateFilterValues(initialState.filterValues);
    resetTimer();
  };

  const handleApplyFilters = (newFilterValues) => {
    getPageInfo(0, newFilterValues);
    getFunnel(newFilterValues);
    updateFilterValues(newFilterValues);
    resetTimer();
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    getPageInfo(newPaginationModel.page, filterValues);
    resetTimer();
  };

  const openSessionDetailsDrawer = (row) => {
    setCurrentDrawer(
      <SessionDetailsDrawer
        row={row}
        closeDrawer={() => setCurrentDrawer(null)}
      />,
    );
  };

  // variables for loading and empty states
  const tableLoadingAndNoEvents =
    connectInsightsFetching.eventsTable
      && (!eventsTablePageInfo.rows
        || (eventsTablePageInfo.rows && eventsTablePageInfo.rows.length === 0));
  const noEvents = !connectInsightsFetching.eventsTable
    && eventsTablePageInfo
    && eventsTablePageInfo.rows
    && eventsTablePageInfo.rows.length === 0;

  return (
    <Box>
      <ApplicationHeader heading={staticText.heading} />
      <div>
        <FiltersBar
          featureSetId={featureSetId}
          filterValues={filterValues}
          handleApplyFilters={handleApplyFilters}
          handleResetAll={handleResetAll}
        />
        <Divider sx={{ m: theme.spacing(3, 0) }} />
        <Statistics
          funnel={funnel}
          funnelGated={funnelGated}
          isFetching={connectInsightsFetching.connectFunnel}
          showLoading={showLoading}
        />
        <Divider sx={{ m: theme.spacing(3, 0) }} />
        <Typography variant="h2" id="heading-sessions-feed" width="fit-content">
          {staticText.sessionsFeed.heading}
        </Typography>
        <Typography mt={1}>{staticText.sessionsFeed.description}</Typography>
        <Divider sx={{ m: theme.spacing(3, 0) }} />
        {tableLoadingAndNoEvents && <Spinner delay={200} />}
        {eventsTablePageInfo && eventsTablePageInfo.rows && eventsTablePageInfo.rows.length > 0 && (
          <LogTable
            pageInfo={eventsTablePageInfo}
            getRowId={row => row.eventId}
            pageSizeOptions={[PAGE_SIZE]}
            paginationModel={paginationModel}
            handlePaginationModelChange={handlePaginationModelChange}
            handleRowClick={openSessionDetailsDrawer}
            loading={connectInsightsFetching.eventsTable && showLoading}
            openDrawer={openSessionDetailsDrawer}
            rowCount={eventsTablePageInfo.rowCount}
            rowHeight={74}
            hideHeader
            stylingOptions={{
              rowHover: {
                color: 'primary',
                cursor: 'pointer',
              },
            }}
          />
        )}
        {noEvents && (
          <EmptyStateContainer paperBackground condensed>
            <EmptyStateImage
              src={connectPhone}
              alt="illustration of a phone using the Smartcar connect flow"
              sx={{ mt: theme.spacing(2), mb: theme.spacing(4) }}
            />
            <Typography variant="h2" sx={{ mb: theme.spacing(2) }}>
              {staticText.emptyState}
            </Typography>
          </EmptyStateContainer>
        )}
      </div>
      {currentDrawer}
    </Box>
  );
};

export default ConnectInsights;

ConnectInsights.propTypes = {
  actions: shape({
    fetchConnectEventsRequest: func.isRequired,
    updateFilterValues: func.isRequired,
  }).isRequired,
  connectInsightsFetching: shape({
    eventsTable: bool.isRequired,
    sessionDetails: bool.isRequired,
    connectFunnel: bool.isRequired,
  }).isRequired,
  eventsTablePageInfo: oneOfType([
    shape({
      rows: array.isRequired,
      columns: array.isRequired,
      rowCount: number.isRequired,
    }),
    shape({}),
  ]).isRequired,
  featureSetId: string.isRequired,
  funnel: arrayOf(shape({
    count: string.isRequired,
    stage: string.isRequired,
    order: number.isRequired,
    displayName: string.isRequired,
    percent: number.isRequired,
  })).isRequired,
  filterValues: shape({
    vin: string,
    customerVehicleOwnerId: string,
    sessionId: string,
    start: string,
    end: string,
  }).isRequired,
  paginationModel: shape({
    page: number,
    pageSize: number,
  }).isRequired,
  pageToNextCursorMap: objectOf(string).isRequired,
};
