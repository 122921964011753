// country icons source https://icon-sets.iconify.design/

const countryList = {
  AF: {
    code: 'AF',
    iso_3166_alpha_2: 'AF',
    iso_3166_alpha_3: 'AFG',
    name: 'Afghanistan',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  AX: {
    code: 'AX',
    iso_3166_alpha_2: 'AX',
    iso_3166_alpha_3: 'ALA',
    name: 'Åland Islands',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  AL: {
    code: 'AL',
    iso_3166_alpha_2: 'AL',
    iso_3166_alpha_3: 'ALB',
    name: 'Albania',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  DZ: {
    code: 'DZ',
    iso_3166_alpha_2: 'DZ',
    iso_3166_alpha_3: 'DZA',
    name: 'Algeria',
    region: 'Africa',
    subRegion: 'Northern Africa',
  },
  AS: {
    code: 'AS',
    iso_3166_alpha_2: 'AS',
    iso_3166_alpha_3: 'ASM',
    name: 'American Samoa',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  AD: {
    code: 'AD',
    iso_3166_alpha_2: 'AD',
    iso_3166_alpha_3: 'AND',
    name: 'Andorra',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  AO: {
    code: 'AO',
    iso_3166_alpha_2: 'AO',
    iso_3166_alpha_3: 'AGO',
    name: 'Angola',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  AI: {
    code: 'AI',
    iso_3166_alpha_2: 'AI',
    iso_3166_alpha_3: 'AIA',
    name: 'Anguilla',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  AQ: {
    code: 'AQ',
    iso_3166_alpha_2: 'AQ',
    iso_3166_alpha_3: 'ATA',
    name: 'Antarctica',
    region: '',
    subRegion: '',
  },
  AG: {
    code: 'AG',
    iso_3166_alpha_2: 'AG',
    iso_3166_alpha_3: 'ATG',
    name: 'Antigua and Barbuda',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  AR: {
    code: 'AR',
    iso_3166_alpha_2: 'AR',
    iso_3166_alpha_3: 'ARG',
    name: 'Argentina',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  AM: {
    code: 'AM',
    iso_3166_alpha_2: 'AM',
    iso_3166_alpha_3: 'ARM',
    name: 'Armenia',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  AW: {
    code: 'AW',
    iso_3166_alpha_2: 'AW',
    iso_3166_alpha_3: 'ABW',
    name: 'Aruba',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  AU: {
    code: 'AU',
    iso_3166_alpha_2: 'AU',
    iso_3166_alpha_3: 'AUS',
    name: 'Australia',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
  AT: {
    code: 'AT',
    iso_3166_alpha_2: 'AT',
    iso_3166_alpha_3: 'AUT',
    name: 'Austria',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  AZ: {
    code: 'AZ',
    iso_3166_alpha_2: 'AZ',
    iso_3166_alpha_3: 'AZE',
    name: 'Azerbaijan',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  BS: {
    code: 'BS',
    iso_3166_alpha_2: 'BS',
    iso_3166_alpha_3: 'BHS',
    name: 'Bahamas',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  BH: {
    code: 'BH',
    iso_3166_alpha_2: 'BH',
    iso_3166_alpha_3: 'BHR',
    name: 'Bahrain',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  BD: {
    code: 'BD',
    iso_3166_alpha_2: 'BD',
    iso_3166_alpha_3: 'BGD',
    name: 'Bangladesh',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  BB: {
    code: 'BB',
    iso_3166_alpha_2: 'BB',
    iso_3166_alpha_3: 'BRB',
    name: 'Barbados',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  BY: {
    code: 'BY',
    iso_3166_alpha_2: 'BY',
    iso_3166_alpha_3: 'BLR',
    name: 'Belarus',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  BE: {
    code: 'BE',
    iso_3166_alpha_2: 'BE',
    iso_3166_alpha_3: 'BEL',
    name: 'Belgium',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  BZ: {
    code: 'BZ',
    iso_3166_alpha_2: 'BZ',
    iso_3166_alpha_3: 'BLZ',
    name: 'Belize',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  BJ: {
    code: 'BJ',
    iso_3166_alpha_2: 'BJ',
    iso_3166_alpha_3: 'BEN',
    name: 'Benin',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  BM: {
    code: 'BM',
    iso_3166_alpha_2: 'BM',
    iso_3166_alpha_3: 'BMU',
    name: 'Bermuda',
    region: 'Americas',
    subRegion: 'Northern America',
  },
  BT: {
    code: 'BT',
    iso_3166_alpha_2: 'BT',
    iso_3166_alpha_3: 'BTN',
    name: 'Bhutan',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  BO: {
    code: 'BO',
    iso_3166_alpha_2: 'BO',
    iso_3166_alpha_3: 'BOL',
    name: 'Bolivia (Plurinational State of)',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  BQ: {
    code: 'BQ',
    iso_3166_alpha_2: 'BQ',
    iso_3166_alpha_3: 'BES',
    name: 'Bonaire, Sint Eustatius and Saba',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  BA: {
    code: 'BA',
    iso_3166_alpha_2: 'BA',
    iso_3166_alpha_3: 'BIH',
    name: 'Bosnia and Herzegovina',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  BW: {
    code: 'BW',
    iso_3166_alpha_2: 'BW',
    iso_3166_alpha_3: 'BWA',
    name: 'Botswana',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  BV: {
    code: 'BV',
    iso_3166_alpha_2: 'BV',
    iso_3166_alpha_3: 'BVT',
    name: 'Bouvet Island',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  BR: {
    code: 'BR',
    iso_3166_alpha_2: 'BR',
    iso_3166_alpha_3: 'BRA',
    name: 'Brazil',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  IO: {
    code: 'IO',
    iso_3166_alpha_2: 'IO',
    iso_3166_alpha_3: 'IOT',
    name: 'British Indian Ocean Territory',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  BN: {
    code: 'BN',
    iso_3166_alpha_2: 'BN',
    iso_3166_alpha_3: 'BRN',
    name: 'Brunei Darussalam',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  BG: {
    code: 'BG',
    iso_3166_alpha_2: 'BG',
    iso_3166_alpha_3: 'BGR',
    name: 'Bulgaria',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  BF: {
    code: 'BF',
    iso_3166_alpha_2: 'BF',
    iso_3166_alpha_3: 'BFA',
    name: 'Burkina Faso',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  BI: {
    code: 'BI',
    iso_3166_alpha_2: 'BI',
    iso_3166_alpha_3: 'BDI',
    name: 'Burundi',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  CV: {
    code: 'CV',
    iso_3166_alpha_2: 'CV',
    iso_3166_alpha_3: 'CPV',
    name: 'Cabo Verde',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  KH: {
    code: 'KH',
    iso_3166_alpha_2: 'KH',
    iso_3166_alpha_3: 'KHM',
    name: 'Cambodia',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  CM: {
    code: 'CM',
    iso_3166_alpha_2: 'CM',
    iso_3166_alpha_3: 'CMR',
    name: 'Cameroon',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  CA: {
    code: 'CA',
    iso_3166_alpha_2: 'CA',
    iso_3166_alpha_3: 'CAN',
    name: 'Canada',
    region: 'Americas',
    subRegion: 'Northern America',
  },
  KY: {
    code: 'KY',
    iso_3166_alpha_2: 'KY',
    iso_3166_alpha_3: 'CYM',
    name: 'Cayman Islands',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  CF: {
    code: 'CF',
    iso_3166_alpha_2: 'CF',
    iso_3166_alpha_3: 'CAF',
    name: 'Central African Republic',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  TD: {
    code: 'TD',
    iso_3166_alpha_2: 'TD',
    iso_3166_alpha_3: 'TCD',
    name: 'Chad',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  CL: {
    code: 'CL',
    iso_3166_alpha_2: 'CL',
    iso_3166_alpha_3: 'CHL',
    name: 'Chile',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  CN: {
    code: 'CN',
    iso_3166_alpha_2: 'CN',
    iso_3166_alpha_3: 'CHN',
    name: 'China',
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  CX: {
    code: 'CX',
    iso_3166_alpha_2: 'CX',
    iso_3166_alpha_3: 'CXR',
    name: 'Christmas Island',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
  CC: {
    code: 'CC',
    iso_3166_alpha_2: 'CC',
    iso_3166_alpha_3: 'CCK',
    name: 'Cocos (Keeling) Islands',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
  CO: {
    code: 'CO',
    iso_3166_alpha_2: 'CO',
    iso_3166_alpha_3: 'COL',
    name: 'Colombia',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  KM: {
    code: 'KM',
    iso_3166_alpha_2: 'KM',
    iso_3166_alpha_3: 'COM',
    name: 'Comoros',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  CG: {
    code: 'CG',
    iso_3166_alpha_2: 'CG',
    iso_3166_alpha_3: 'COG',
    name: 'Congo',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  CD: {
    code: 'CD',
    iso_3166_alpha_2: 'CD',
    iso_3166_alpha_3: 'COD',
    name: 'Congo, Democratic Republic of the',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  CK: {
    code: 'CK',
    iso_3166_alpha_2: 'CK',
    iso_3166_alpha_3: 'COK',
    name: 'Cook Islands',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  CR: {
    code: 'CR',
    iso_3166_alpha_2: 'CR',
    iso_3166_alpha_3: 'CRI',
    name: 'Costa Rica',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  CI: {
    code: 'CI',
    iso_3166_alpha_2: 'CI',
    iso_3166_alpha_3: 'CIV',
    name: "Côte d'Ivoire",
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  HR: {
    code: 'HR',
    iso_3166_alpha_2: 'HR',
    iso_3166_alpha_3: 'HRV',
    name: 'Croatia',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  CU: {
    code: 'CU',
    iso_3166_alpha_2: 'CU',
    iso_3166_alpha_3: 'CUB',
    name: 'Cuba',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  CW: {
    code: 'CW',
    iso_3166_alpha_2: 'CW',
    iso_3166_alpha_3: 'CUW',
    name: 'Curaçao',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  CY: {
    code: 'CY',
    iso_3166_alpha_2: 'CY',
    iso_3166_alpha_3: 'CYP',
    name: 'Cyprus',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  CZ: {
    code: 'CZ',
    iso_3166_alpha_2: 'CZ',
    iso_3166_alpha_3: 'CZE',
    name: 'Czech Republic',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  DK: {
    code: 'DK',
    iso_3166_alpha_2: 'DK',
    iso_3166_alpha_3: 'DNK',
    name: 'Denmark',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  DJ: {
    code: 'DJ',
    iso_3166_alpha_2: 'DJ',
    iso_3166_alpha_3: 'DJI',
    name: 'Djibouti',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  DM: {
    code: 'DM',
    iso_3166_alpha_2: 'DM',
    iso_3166_alpha_3: 'DMA',
    name: 'Dominica',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  DO: {
    code: 'DO',
    iso_3166_alpha_2: 'DO',
    iso_3166_alpha_3: 'DOM',
    name: 'Dominican Republic',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  EC: {
    code: 'EC',
    iso_3166_alpha_2: 'EC',
    iso_3166_alpha_3: 'ECU',
    name: 'Ecuador',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  EG: {
    code: 'EG',
    iso_3166_alpha_2: 'EG',
    iso_3166_alpha_3: 'EGY',
    name: 'Egypt',
    region: 'Africa',
    subRegion: 'Northern Africa',
  },
  SV: {
    code: 'SV',
    iso_3166_alpha_2: 'SV',
    iso_3166_alpha_3: 'SLV',
    name: 'El Salvador',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  GQ: {
    code: 'GQ',
    iso_3166_alpha_2: 'GQ',
    iso_3166_alpha_3: 'GNQ',
    name: 'Equatorial Guinea',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  ER: {
    code: 'ER',
    iso_3166_alpha_2: 'ER',
    iso_3166_alpha_3: 'ERI',
    name: 'Eritrea',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  EE: {
    code: 'EE',
    iso_3166_alpha_2: 'EE',
    iso_3166_alpha_3: 'EST',
    name: 'Estonia',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  SZ: {
    code: 'SZ',
    iso_3166_alpha_2: 'SZ',
    iso_3166_alpha_3: 'SWZ',
    name: 'Eswatini',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  ET: {
    code: 'ET',
    iso_3166_alpha_2: 'ET',
    iso_3166_alpha_3: 'ETH',
    name: 'Ethiopia',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  FK: {
    code: 'FK',
    iso_3166_alpha_2: 'FK',
    iso_3166_alpha_3: 'FLK',
    name: 'Falkland Islands (Malvinas)',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  FO: {
    code: 'FO',
    iso_3166_alpha_2: 'FO',
    iso_3166_alpha_3: 'FRO',
    name: 'Faroe Islands',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  FJ: {
    code: 'FJ',
    iso_3166_alpha_2: 'FJ',
    iso_3166_alpha_3: 'FJI',
    name: 'Fiji',
    region: 'Oceania',
    subRegion: 'Melanesia',
  },
  FI: {
    code: 'FI',
    iso_3166_alpha_2: 'FI',
    iso_3166_alpha_3: 'FIN',
    name: 'Finland',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  FR: {
    code: 'FR',
    iso_3166_alpha_2: 'FR',
    iso_3166_alpha_3: 'FRA',
    name: 'France',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  GF: {
    code: 'GF',
    iso_3166_alpha_2: 'GF',
    iso_3166_alpha_3: 'GUF',
    name: 'French Guiana',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  PF: {
    code: 'PF',
    iso_3166_alpha_2: 'PF',
    iso_3166_alpha_3: 'PYF',
    name: 'French Polynesia',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  TF: {
    code: 'TF',
    iso_3166_alpha_2: 'TF',
    iso_3166_alpha_3: 'ATF',
    name: 'French Southern Territories',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  GA: {
    code: 'GA',
    iso_3166_alpha_2: 'GA',
    iso_3166_alpha_3: 'GAB',
    name: 'Gabon',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  GM: {
    code: 'GM',
    iso_3166_alpha_2: 'GM',
    iso_3166_alpha_3: 'GMB',
    name: 'Gambia',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  GE: {
    code: 'GE',
    iso_3166_alpha_2: 'GE',
    iso_3166_alpha_3: 'GEO',
    name: 'Georgia',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  DE: {
    code: 'DE',
    iso_3166_alpha_2: 'DE',
    iso_3166_alpha_3: 'DEU',
    name: 'Germany',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  GH: {
    code: 'GH',
    iso_3166_alpha_2: 'GH',
    iso_3166_alpha_3: 'GHA',
    name: 'Ghana',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  GI: {
    code: 'GI',
    iso_3166_alpha_2: 'GI',
    iso_3166_alpha_3: 'GIB',
    name: 'Gibraltar',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  GR: {
    code: 'GR',
    iso_3166_alpha_2: 'GR',
    iso_3166_alpha_3: 'GRC',
    name: 'Greece',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  GL: {
    code: 'GL',
    iso_3166_alpha_2: 'GL',
    iso_3166_alpha_3: 'GRL',
    name: 'Greenland',
    region: 'Americas',
    subRegion: 'Northern America',
  },
  GD: {
    code: 'GD',
    iso_3166_alpha_2: 'GD',
    iso_3166_alpha_3: 'GRD',
    name: 'Grenada',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  GP: {
    code: 'GP',
    iso_3166_alpha_2: 'GP',
    iso_3166_alpha_3: 'GLP',
    name: 'Guadeloupe',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  GU: {
    code: 'GU',
    iso_3166_alpha_2: 'GU',
    iso_3166_alpha_3: 'GUM',
    name: 'Guam',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  GT: {
    code: 'GT',
    iso_3166_alpha_2: 'GT',
    iso_3166_alpha_3: 'GTM',
    name: 'Guatemala',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  GG: {
    code: 'GG',
    iso_3166_alpha_2: 'GG',
    iso_3166_alpha_3: 'GGY',
    name: 'Guernsey',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  GN: {
    code: 'GN',
    iso_3166_alpha_2: 'GN',
    iso_3166_alpha_3: 'GIN',
    name: 'Guinea',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  GW: {
    code: 'GW',
    iso_3166_alpha_2: 'GW',
    iso_3166_alpha_3: 'GNB',
    name: 'Guinea-Bissau',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  GY: {
    code: 'GY',
    iso_3166_alpha_2: 'GY',
    iso_3166_alpha_3: 'GUY',
    name: 'Guyana',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  HT: {
    code: 'HT',
    iso_3166_alpha_2: 'HT',
    iso_3166_alpha_3: 'HTI',
    name: 'Haiti',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  HM: {
    code: 'HM',
    iso_3166_alpha_2: 'HM',
    iso_3166_alpha_3: 'HMD',
    name: 'Heard Island and McDonald Islands',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
  VA: {
    code: 'VA',
    iso_3166_alpha_2: 'VA',
    iso_3166_alpha_3: 'VAT',
    name: 'Holy See',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  HN: {
    code: 'HN',
    iso_3166_alpha_2: 'HN',
    iso_3166_alpha_3: 'HND',
    name: 'Honduras',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  HK: {
    code: 'HK',
    iso_3166_alpha_2: 'HK',
    iso_3166_alpha_3: 'HKG',
    name: 'Hong Kong',
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  HU: {
    code: 'HU',
    iso_3166_alpha_2: 'HU',
    iso_3166_alpha_3: 'HUN',
    name: 'Hungary',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  IS: {
    code: 'IS',
    iso_3166_alpha_2: 'IS',
    iso_3166_alpha_3: 'ISL',
    name: 'Iceland',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  IN: {
    code: 'IN',
    iso_3166_alpha_2: 'IN',
    iso_3166_alpha_3: 'IND',
    name: 'India',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  ID: {
    code: 'ID',
    iso_3166_alpha_2: 'ID',
    iso_3166_alpha_3: 'IDN',
    name: 'Indonesia',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  IR: {
    code: 'IR',
    iso_3166_alpha_2: 'IR',
    iso_3166_alpha_3: 'IRN',
    name: 'Iran (Islamic Republic of)',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  IQ: {
    code: 'IQ',
    iso_3166_alpha_2: 'IQ',
    iso_3166_alpha_3: 'IRQ',
    name: 'Iraq',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  IE: {
    code: 'IE',
    iso_3166_alpha_2: 'IE',
    iso_3166_alpha_3: 'IRL',
    name: 'Ireland',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  IM: {
    code: 'IM',
    iso_3166_alpha_2: 'IM',
    iso_3166_alpha_3: 'IMN',
    name: 'Isle of Man',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  IL: {
    code: 'IL',
    iso_3166_alpha_2: 'IL',
    iso_3166_alpha_3: 'ISR',
    name: 'Israel',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  IT: {
    code: 'IT',
    iso_3166_alpha_2: 'IT',
    iso_3166_alpha_3: 'ITA',
    name: 'Italy',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  JM: {
    code: 'JM',
    iso_3166_alpha_2: 'JM',
    iso_3166_alpha_3: 'JAM',
    name: 'Jamaica',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  JP: {
    code: 'JP',
    iso_3166_alpha_2: 'JP',
    iso_3166_alpha_3: 'JPN',
    name: 'Japan',
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  JE: {
    code: 'JE',
    iso_3166_alpha_2: 'JE',
    iso_3166_alpha_3: 'JEY',
    name: 'Jersey',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  JO: {
    code: 'JO',
    iso_3166_alpha_2: 'JO',
    iso_3166_alpha_3: 'JOR',
    name: 'Jordan',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  KZ: {
    code: 'KZ',
    iso_3166_alpha_2: 'KZ',
    iso_3166_alpha_3: 'KAZ',
    name: 'Kazakhstan',
    region: 'Asia',
    subRegion: 'Central Asia',
  },
  KE: {
    code: 'KE',
    iso_3166_alpha_2: 'KE',
    iso_3166_alpha_3: 'KEN',
    name: 'Kenya',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  KI: {
    code: 'KI',
    iso_3166_alpha_2: 'KI',
    iso_3166_alpha_3: 'KIR',
    name: 'Kiribati',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  KP: {
    code: 'KP',
    iso_3166_alpha_2: 'KP',
    iso_3166_alpha_3: 'PRK',
    name: "Korea (Democratic People's Republic of)",
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  KR: {
    code: 'KR',
    iso_3166_alpha_2: 'KR',
    iso_3166_alpha_3: 'KOR',
    name: 'Korea, Republic of',
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  KW: {
    code: 'KW',
    iso_3166_alpha_2: 'KW',
    iso_3166_alpha_3: 'KWT',
    name: 'Kuwait',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  KG: {
    code: 'KG',
    iso_3166_alpha_2: 'KG',
    iso_3166_alpha_3: 'KGZ',
    name: 'Kyrgyzstan',
    region: 'Asia',
    subRegion: 'Central Asia',
  },
  LA: {
    code: 'LA',
    iso_3166_alpha_2: 'LA',
    iso_3166_alpha_3: 'LAO',
    name: "Lao People's Democratic Republic",
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  LV: {
    code: 'LV',
    iso_3166_alpha_2: 'LV',
    iso_3166_alpha_3: 'LVA',
    name: 'Latvia',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  LB: {
    code: 'LB',
    iso_3166_alpha_2: 'LB',
    iso_3166_alpha_3: 'LBN',
    name: 'Lebanon',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  LS: {
    code: 'LS',
    iso_3166_alpha_2: 'LS',
    iso_3166_alpha_3: 'LSO',
    name: 'Lesotho',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  LR: {
    code: 'LR',
    iso_3166_alpha_2: 'LR',
    iso_3166_alpha_3: 'LBR',
    name: 'Liberia',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  LY: {
    code: 'LY',
    iso_3166_alpha_2: 'LY',
    iso_3166_alpha_3: 'LBY',
    name: 'Libya',
    region: 'Africa',
    subRegion: 'Northern Africa',
  },
  LI: {
    code: 'LI',
    iso_3166_alpha_2: 'LI',
    iso_3166_alpha_3: 'LIE',
    name: 'Liechtenstein',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  LT: {
    code: 'LT',
    iso_3166_alpha_2: 'LT',
    iso_3166_alpha_3: 'LTU',
    name: 'Lithuania',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  LU: {
    code: 'LU',
    iso_3166_alpha_2: 'LU',
    iso_3166_alpha_3: 'LUX',
    name: 'Luxembourg',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  MO: {
    code: 'MO',
    iso_3166_alpha_2: 'MO',
    iso_3166_alpha_3: 'MAC',
    name: 'Macao',
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  MG: {
    code: 'MG',
    iso_3166_alpha_2: 'MG',
    iso_3166_alpha_3: 'MDG',
    name: 'Madagascar',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  MW: {
    code: 'MW',
    iso_3166_alpha_2: 'MW',
    iso_3166_alpha_3: 'MWI',
    name: 'Malawi',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  MY: {
    code: 'MY',
    iso_3166_alpha_2: 'MY',
    iso_3166_alpha_3: 'MYS',
    name: 'Malaysia',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  MV: {
    code: 'MV',
    iso_3166_alpha_2: 'MV',
    iso_3166_alpha_3: 'MDV',
    name: 'Maldives',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  ML: {
    code: 'ML',
    iso_3166_alpha_2: 'ML',
    iso_3166_alpha_3: 'MLI',
    name: 'Mali',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  MT: {
    code: 'MT',
    iso_3166_alpha_2: 'MT',
    iso_3166_alpha_3: 'MLT',
    name: 'Malta',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  MH: {
    code: 'MH',
    iso_3166_alpha_2: 'MH',
    iso_3166_alpha_3: 'MHL',
    name: 'Marshall Islands',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  MQ: {
    code: 'MQ',
    iso_3166_alpha_2: 'MQ',
    iso_3166_alpha_3: 'MTQ',
    name: 'Martinique',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  MR: {
    code: 'MR',
    iso_3166_alpha_2: 'MR',
    iso_3166_alpha_3: 'MRT',
    name: 'Mauritania',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  MU: {
    code: 'MU',
    iso_3166_alpha_2: 'MU',
    iso_3166_alpha_3: 'MUS',
    name: 'Mauritius',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  YT: {
    code: 'YT',
    iso_3166_alpha_2: 'YT',
    iso_3166_alpha_3: 'MYT',
    name: 'Mayotte',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  MX: {
    code: 'MX',
    iso_3166_alpha_2: 'MX',
    iso_3166_alpha_3: 'MEX',
    name: 'Mexico',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  FM: {
    code: 'FM',
    iso_3166_alpha_2: 'FM',
    iso_3166_alpha_3: 'FSM',
    name: 'Micronesia (Federated States of)',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  MD: {
    code: 'MD',
    iso_3166_alpha_2: 'MD',
    iso_3166_alpha_3: 'MDA',
    name: 'Moldova, Republic of',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  MC: {
    code: 'MC',
    iso_3166_alpha_2: 'MC',
    iso_3166_alpha_3: 'MCO',
    name: 'Monaco',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  MN: {
    code: 'MN',
    iso_3166_alpha_2: 'MN',
    iso_3166_alpha_3: 'MNG',
    name: 'Mongolia',
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  ME: {
    code: 'ME',
    iso_3166_alpha_2: 'ME',
    iso_3166_alpha_3: 'MNE',
    name: 'Montenegro',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  MS: {
    code: 'MS',
    iso_3166_alpha_2: 'MS',
    iso_3166_alpha_3: 'MSR',
    name: 'Montserrat',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  MA: {
    code: 'MA',
    iso_3166_alpha_2: 'MA',
    iso_3166_alpha_3: 'MAR',
    name: 'Morocco',
    region: 'Africa',
    subRegion: 'Northern Africa',
  },
  MZ: {
    code: 'MZ',
    iso_3166_alpha_2: 'MZ',
    iso_3166_alpha_3: 'MOZ',
    name: 'Mozambique',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  MM: {
    code: 'MM',
    iso_3166_alpha_2: 'MM',
    iso_3166_alpha_3: 'MMR',
    name: 'Myanmar',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  NA: {
    code: 'NA',
    iso_3166_alpha_2: 'NA',
    iso_3166_alpha_3: 'NAM',
    name: 'Namibia',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  NR: {
    code: 'NR',
    iso_3166_alpha_2: 'NR',
    iso_3166_alpha_3: 'NRU',
    name: 'Nauru',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  NP: {
    code: 'NP',
    iso_3166_alpha_2: 'NP',
    iso_3166_alpha_3: 'NPL',
    name: 'Nepal',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  NL: {
    code: 'NL',
    iso_3166_alpha_2: 'NL',
    iso_3166_alpha_3: 'NLD',
    name: 'Netherlands',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  NC: {
    code: 'NC',
    iso_3166_alpha_2: 'NC',
    iso_3166_alpha_3: 'NCL',
    name: 'New Caledonia',
    region: 'Oceania',
    subRegion: 'Melanesia',
  },
  NZ: {
    code: 'NZ',
    iso_3166_alpha_2: 'NZ',
    iso_3166_alpha_3: 'NZL',
    name: 'New Zealand',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
  NI: {
    code: 'NI',
    iso_3166_alpha_2: 'NI',
    iso_3166_alpha_3: 'NIC',
    name: 'Nicaragua',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  NE: {
    code: 'NE',
    iso_3166_alpha_2: 'NE',
    iso_3166_alpha_3: 'NER',
    name: 'Niger',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  NG: {
    code: 'NG',
    iso_3166_alpha_2: 'NG',
    iso_3166_alpha_3: 'NGA',
    name: 'Nigeria',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  NU: {
    code: 'NU',
    iso_3166_alpha_2: 'NU',
    iso_3166_alpha_3: 'NIU',
    name: 'Niue',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  NF: {
    code: 'NF',
    iso_3166_alpha_2: 'NF',
    iso_3166_alpha_3: 'NFK',
    name: 'Norfolk Island',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
  },
  MK: {
    code: 'MK',
    iso_3166_alpha_2: 'MK',
    iso_3166_alpha_3: 'MKD',
    name: 'North Macedonia',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  MP: {
    code: 'MP',
    iso_3166_alpha_2: 'MP',
    iso_3166_alpha_3: 'MNP',
    name: 'Northern Mariana Islands',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  NO: {
    code: 'NO',
    iso_3166_alpha_2: 'NO',
    iso_3166_alpha_3: 'NOR',
    name: 'Norway',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  OM: {
    code: 'OM',
    iso_3166_alpha_2: 'OM',
    iso_3166_alpha_3: 'OMN',
    name: 'Oman',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  PK: {
    code: 'PK',
    iso_3166_alpha_2: 'PK',
    iso_3166_alpha_3: 'PAK',
    name: 'Pakistan',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  PW: {
    code: 'PW',
    iso_3166_alpha_2: 'PW',
    iso_3166_alpha_3: 'PLW',
    name: 'Palau',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  PS: {
    code: 'PS',
    iso_3166_alpha_2: 'PS',
    iso_3166_alpha_3: 'PSE',
    name: 'Palestine, State of',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  PA: {
    code: 'PA',
    iso_3166_alpha_2: 'PA',
    iso_3166_alpha_3: 'PAN',
    name: 'Panama',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  PG: {
    code: 'PG',
    iso_3166_alpha_2: 'PG',
    iso_3166_alpha_3: 'PNG',
    name: 'Papua New Guinea',
    region: 'Oceania',
    subRegion: 'Melanesia',
  },
  PY: {
    code: 'PY',
    iso_3166_alpha_2: 'PY',
    iso_3166_alpha_3: 'PRY',
    name: 'Paraguay',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  PE: {
    code: 'PE',
    iso_3166_alpha_2: 'PE',
    iso_3166_alpha_3: 'PER',
    name: 'Peru',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  PH: {
    code: 'PH',
    iso_3166_alpha_2: 'PH',
    iso_3166_alpha_3: 'PHL',
    name: 'Philippines',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  PN: {
    code: 'PN',
    iso_3166_alpha_2: 'PN',
    iso_3166_alpha_3: 'PCN',
    name: 'Pitcairn',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  PL: {
    code: 'PL',
    iso_3166_alpha_2: 'PL',
    iso_3166_alpha_3: 'POL',
    name: 'Poland',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  PT: {
    code: 'PT',
    iso_3166_alpha_2: 'PT',
    iso_3166_alpha_3: 'PRT',
    name: 'Portugal',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  PR: {
    code: 'PR',
    iso_3166_alpha_2: 'PR',
    iso_3166_alpha_3: 'PRI',
    name: 'Puerto Rico',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  QA: {
    code: 'QA',
    iso_3166_alpha_2: 'QA',
    iso_3166_alpha_3: 'QAT',
    name: 'Qatar',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  RE: {
    code: 'RE',
    iso_3166_alpha_2: 'RE',
    iso_3166_alpha_3: 'REU',
    name: 'Réunion',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  RO: {
    code: 'RO',
    iso_3166_alpha_2: 'RO',
    iso_3166_alpha_3: 'ROU',
    name: 'Romania',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  RU: {
    code: 'RU',
    iso_3166_alpha_2: 'RU',
    iso_3166_alpha_3: 'RUS',
    name: 'Russian Federation',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  RW: {
    code: 'RW',
    iso_3166_alpha_2: 'RW',
    iso_3166_alpha_3: 'RWA',
    name: 'Rwanda',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  BL: {
    code: 'BL',
    iso_3166_alpha_2: 'BL',
    iso_3166_alpha_3: 'BLM',
    name: 'Saint Barthélemy',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  SH: {
    code: 'SH',
    iso_3166_alpha_2: 'SH',
    iso_3166_alpha_3: 'SHN',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  KN: {
    code: 'KN',
    iso_3166_alpha_2: 'KN',
    iso_3166_alpha_3: 'KNA',
    name: 'Saint Kitts and Nevis',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  LC: {
    code: 'LC',
    iso_3166_alpha_2: 'LC',
    iso_3166_alpha_3: 'LCA',
    name: 'Saint Lucia',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  MF: {
    code: 'MF',
    iso_3166_alpha_2: 'MF',
    iso_3166_alpha_3: 'MAF',
    name: 'Saint Martin (French part)',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  PM: {
    code: 'PM',
    iso_3166_alpha_2: 'PM',
    iso_3166_alpha_3: 'SPM',
    name: 'Saint Pierre and Miquelon',
    region: 'Americas',
    subRegion: 'Northern America',
  },
  VC: {
    code: 'VC',
    iso_3166_alpha_2: 'VC',
    iso_3166_alpha_3: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  WS: {
    code: 'WS',
    iso_3166_alpha_2: 'WS',
    iso_3166_alpha_3: 'WSM',
    name: 'Samoa',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  SM: {
    code: 'SM',
    iso_3166_alpha_2: 'SM',
    iso_3166_alpha_3: 'SMR',
    name: 'San Marino',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  ST: {
    code: 'ST',
    iso_3166_alpha_2: 'ST',
    iso_3166_alpha_3: 'STP',
    name: 'Sao Tome and Principe',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  SA: {
    code: 'SA',
    iso_3166_alpha_2: 'SA',
    iso_3166_alpha_3: 'SAU',
    name: 'Saudi Arabia',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  SN: {
    code: 'SN',
    iso_3166_alpha_2: 'SN',
    iso_3166_alpha_3: 'SEN',
    name: 'Senegal',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  RS: {
    code: 'RS',
    iso_3166_alpha_2: 'RS',
    iso_3166_alpha_3: 'SRB',
    name: 'Serbia',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  SC: {
    code: 'SC',
    iso_3166_alpha_2: 'SC',
    iso_3166_alpha_3: 'SYC',
    name: 'Seychelles',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  SL: {
    code: 'SL',
    iso_3166_alpha_2: 'SL',
    iso_3166_alpha_3: 'SLE',
    name: 'Sierra Leone',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  SG: {
    code: 'SG',
    iso_3166_alpha_2: 'SG',
    iso_3166_alpha_3: 'SGP',
    name: 'Singapore',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  SX: {
    code: 'SX',
    iso_3166_alpha_2: 'SX',
    iso_3166_alpha_3: 'SXM',
    name: 'Sint Maarten (Dutch part)',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  SK: {
    code: 'SK',
    iso_3166_alpha_2: 'SK',
    iso_3166_alpha_3: 'SVK',
    name: 'Slovakia',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  SI: {
    code: 'SI',
    iso_3166_alpha_2: 'SI',
    iso_3166_alpha_3: 'SVN',
    name: 'Slovenia',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  SB: {
    code: 'SB',
    iso_3166_alpha_2: 'SB',
    iso_3166_alpha_3: 'SLB',
    name: 'Solomon Islands',
    region: 'Oceania',
    subRegion: 'Melanesia',
  },
  SO: {
    code: 'SO',
    iso_3166_alpha_2: 'SO',
    iso_3166_alpha_3: 'SOM',
    name: 'Somalia',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  ZA: {
    code: 'ZA',
    iso_3166_alpha_2: 'ZA',
    iso_3166_alpha_3: 'ZAF',
    name: 'South Africa',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  GS: {
    code: 'GS',
    iso_3166_alpha_2: 'GS',
    iso_3166_alpha_3: 'SGS',
    name: 'South Georgia and the South Sandwich Islands',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  SS: {
    code: 'SS',
    iso_3166_alpha_2: 'SS',
    iso_3166_alpha_3: 'SSD',
    name: 'South Sudan',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  ES: {
    code: 'ES',
    iso_3166_alpha_2: 'ES',
    iso_3166_alpha_3: 'ESP',
    name: 'Spain',
    region: 'Europe',
    subRegion: 'Southern Europe',
  },
  LK: {
    code: 'LK',
    iso_3166_alpha_2: 'LK',
    iso_3166_alpha_3: 'LKA',
    name: 'Sri Lanka',
    region: 'Asia',
    subRegion: 'Southern Asia',
  },
  SD: {
    code: 'SD',
    iso_3166_alpha_2: 'SD',
    iso_3166_alpha_3: 'SDN',
    name: 'Sudan',
    region: 'Africa',
    subRegion: 'Northern Africa',
  },
  SR: {
    code: 'SR',
    iso_3166_alpha_2: 'SR',
    iso_3166_alpha_3: 'SUR',
    name: 'Suriname',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  SJ: {
    code: 'SJ',
    iso_3166_alpha_2: 'SJ',
    iso_3166_alpha_3: 'SJM',
    name: 'Svalbard and Jan Mayen',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  SE: {
    code: 'SE',
    iso_3166_alpha_2: 'SE',
    iso_3166_alpha_3: 'SWE',
    name: 'Sweden',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  CH: {
    code: 'CH',
    iso_3166_alpha_2: 'CH',
    iso_3166_alpha_3: 'CHE',
    name: 'Switzerland',
    region: 'Europe',
    subRegion: 'Western Europe',
  },
  SY: {
    code: 'SY',
    iso_3166_alpha_2: 'SY',
    iso_3166_alpha_3: 'SYR',
    name: 'Syrian Arab Republic',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  TW: {
    code: 'TW',
    iso_3166_alpha_2: 'TW',
    iso_3166_alpha_3: 'TWN',
    name: 'Taiwan, Province of China',
    region: 'Asia',
    subRegion: 'Eastern Asia',
  },
  TJ: {
    code: 'TJ',
    iso_3166_alpha_2: 'TJ',
    iso_3166_alpha_3: 'TJK',
    name: 'Tajikistan',
    region: 'Asia',
    subRegion: 'Central Asia',
  },
  TZ: {
    code: 'TZ',
    iso_3166_alpha_2: 'TZ',
    iso_3166_alpha_3: 'TZA',
    name: 'Tanzania, United Republic of',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  TH: {
    code: 'TH',
    iso_3166_alpha_2: 'TH',
    iso_3166_alpha_3: 'THA',
    name: 'Thailand',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  TL: {
    code: 'TL',
    iso_3166_alpha_2: 'TL',
    iso_3166_alpha_3: 'TLS',
    name: 'Timor-Leste',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  TG: {
    code: 'TG',
    iso_3166_alpha_2: 'TG',
    iso_3166_alpha_3: 'TGO',
    name: 'Togo',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  TK: {
    code: 'TK',
    iso_3166_alpha_2: 'TK',
    iso_3166_alpha_3: 'TKL',
    name: 'Tokelau',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  TO: {
    code: 'TO',
    iso_3166_alpha_2: 'TO',
    iso_3166_alpha_3: 'TON',
    name: 'Tonga',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  TT: {
    code: 'TT',
    iso_3166_alpha_2: 'TT',
    iso_3166_alpha_3: 'TTO',
    name: 'Trinidad and Tobago',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  TN: {
    code: 'TN',
    iso_3166_alpha_2: 'TN',
    iso_3166_alpha_3: 'TUN',
    name: 'Tunisia',
    region: 'Africa',
    subRegion: 'Northern Africa',
  },
  TR: {
    code: 'TR',
    iso_3166_alpha_2: 'TR',
    iso_3166_alpha_3: 'TUR',
    name: 'Turkey',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  TM: {
    code: 'TM',
    iso_3166_alpha_2: 'TM',
    iso_3166_alpha_3: 'TKM',
    name: 'Turkmenistan',
    region: 'Asia',
    subRegion: 'Central Asia',
  },
  TC: {
    code: 'TC',
    iso_3166_alpha_2: 'TC',
    iso_3166_alpha_3: 'TCA',
    name: 'Turks and Caicos Islands',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  TV: {
    code: 'TV',
    iso_3166_alpha_2: 'TV',
    iso_3166_alpha_3: 'TUV',
    name: 'Tuvalu',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  UG: {
    code: 'UG',
    iso_3166_alpha_2: 'UG',
    iso_3166_alpha_3: 'UGA',
    name: 'Uganda',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  UA: {
    code: 'UA',
    iso_3166_alpha_2: 'UA',
    iso_3166_alpha_3: 'UKR',
    name: 'Ukraine',
    region: 'Europe',
    subRegion: 'Eastern Europe',
  },
  AE: {
    code: 'AE',
    iso_3166_alpha_2: 'AE',
    iso_3166_alpha_3: 'ARE',
    name: 'United Arab Emirates',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  GB: {
    code: 'GB',
    iso_3166_alpha_2: 'GB',
    iso_3166_alpha_3: 'GBR',
    name: 'United Kingdom',
    region: 'Europe',
    subRegion: 'Northern Europe',
  },
  US: {
    code: 'US',
    iso_3166_alpha_2: 'US',
    iso_3166_alpha_3: 'USA',
    name: 'United States of America',
    region: 'Americas',
    subRegion: 'Northern America',
  },
  UM: {
    code: 'UM',
    iso_3166_alpha_2: 'UM',
    iso_3166_alpha_3: 'UMI',
    name: 'United States Minor Outlying Islands',
    region: 'Oceania',
    subRegion: 'Micronesia',
  },
  UY: {
    code: 'UY',
    iso_3166_alpha_2: 'UY',
    iso_3166_alpha_3: 'URY',
    name: 'Uruguay',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  UZ: {
    code: 'UZ',
    iso_3166_alpha_2: 'UZ',
    iso_3166_alpha_3: 'UZB',
    name: 'Uzbekistan',
    region: 'Asia',
    subRegion: 'Central Asia',
  },
  VU: {
    code: 'VU',
    iso_3166_alpha_2: 'VU',
    iso_3166_alpha_3: 'VUT',
    name: 'Vanuatu',
    region: 'Oceania',
    subRegion: 'Melanesia',
  },
  VE: {
    code: 'VE',
    iso_3166_alpha_2: 'VE',
    iso_3166_alpha_3: 'VEN',
    name: 'Venezuela (Bolivarian Republic of)',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  VN: {
    code: 'VN',
    iso_3166_alpha_2: 'VN',
    iso_3166_alpha_3: 'VNM',
    name: 'Viet Nam',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
  },
  VG: {
    code: 'VG',
    iso_3166_alpha_2: 'VG',
    iso_3166_alpha_3: 'VGB',
    name: 'Virgin Islands (British)',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  VI: {
    code: 'VI',
    iso_3166_alpha_2: 'VI',
    iso_3166_alpha_3: 'VIR',
    name: 'Virgin Islands (U.S.)',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
  },
  WF: {
    code: 'WF',
    iso_3166_alpha_2: 'WF',
    iso_3166_alpha_3: 'WLF',
    name: 'Wallis and Futuna',
    region: 'Oceania',
    subRegion: 'Polynesia',
  },
  EH: {
    code: 'EH',
    iso_3166_alpha_2: 'EH',
    iso_3166_alpha_3: 'ESH',
    name: 'Western Sahara',
    region: 'Africa',
    subRegion: 'Northern Africa',
  },
  YE: {
    code: 'YE',
    iso_3166_alpha_2: 'YE',
    iso_3166_alpha_3: 'YEM',
    name: 'Yemen',
    region: 'Asia',
    subRegion: 'Western Asia',
  },
  ZM: {
    code: 'ZM',
    iso_3166_alpha_2: 'ZM',
    iso_3166_alpha_3: 'ZMB',
    name: 'Zambia',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
  ZW: {
    code: 'ZW',
    iso_3166_alpha_2: 'ZW',
    iso_3166_alpha_3: 'ZWE',
    name: 'Zimbabwe',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
  },
};

const defaultCountryCode = 'US';

const supportedCountries = [
  'US', 'CA', 'AT', 'BE', 'BG',
  'HR', 'CZ', 'DK', 'EE', 'FI',
  'FR', 'DE', 'GR', 'HU', 'IE',
  'IT', 'LV', 'LT', 'LU', 'MT',
  'NL', 'NO', 'PL', 'PT', 'RO',
  'SK', 'SI', 'ES', 'SE', 'CH',
  'GB',
];

const getCountryDisplayName = countryCode => countryList[countryCode].name;

export {
  countryList,
  supportedCountries,
  getCountryDisplayName,
  defaultCountryCode,
};
