const staticText = {
  plans: {
    free: {
      planName: 'Free',
      price: {
        cost: '$0',
        addendum: 'No credit card required',
      },
    },
    build: {
      planName: 'Build',
      price: {
        cost: '$2.99',
        addendum: 'per vehicle/month',
      },
    },
    salesLed: {
      planName: 'Custom',
      price: {
        addendum: 'Custom pricing plans available',
      },
    },
  },
  features: [
    {
      title: 'Connected vehicles',
      free: '1 included',
      build: 'Up to 100',
      salesLed: 'Custom',
    },
    {
      title: 'Simulated vehicles',
      free: 'Up to 5',
      build: 'Up to 10',
      salesLed: 'Custom',
    },
    {
      title: 'API calls',
      free: '500 per vehicle',
      build: '1,000 per vehicle',
      salesLed: 'Custom',
    },
    {
      title: 'Log data',
      free: '1 day',
      build: '7 days',
      salesLed: 'Up to 365 days',
    },
    {
      title: 'Applications',
      free: '1',
      build: '2',
      salesLed: 'Custom',
    },
    {
      title: 'Smartcar Connect customization',
      free: false,
      build: false,
      salesLed: true,
    },
    {
      title: 'Tiered usage-based pricing',
      free: false,
      build: false,
      salesLed: true,
    },
    {
      title: 'SLAs / Certifications',
      free: false,
      build: false,
      salesLed: true,
    },
    {
      title: 'Scheduled webhooks',
      free: false,
      build: false,
      salesLed: true,
    },
    {
      title: 'Log filtering and exporting',
      free: false,
      build: false,
      salesLed: true,
    },
    {
      title: 'Compatibility API by VIN',
      free: false,
      build: false,
      salesLed: true,
    },
    {
      title: 'Dedicated Slack channel',
      free: false,
      build: false,
      salesLed: true,
    },
    {
      title: 'Managed onboarding',
      free: false,
      build: false,
      salesLed: true,
    },
  ],
  chips: {
    current: 'Current',
    recommended: 'Recommended',
  },
  buttonTexts: {
    downgrade: 'Downgrade',
    upgrade: 'Upgrade',
    contactUs: 'Contact us',
    current: 'Current',
  },
};

export default staticText;

