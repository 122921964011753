/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { X } from 'react-feather';
import { node, func, string, bool } from 'prop-types';
import { createPortal } from 'react-dom';

import './Modal.scss';

const Modal = ({
  title,
  content,
  additionalClassNames,
  modalContentClassNames,
  toggleModal,
  showClose,
  divider,
  dividerClassNames,
}) => {
  if (!content) return null;

  useEffect(() => {
    document.querySelector('html').classList.add('modal-open');
    return () => {
      document.querySelector('html').classList.remove('modal-open');
    };
  });
  return createPortal(
    <div onClick={toggleModal} className="modal-wrapper">
      <div
        onClick={e => e.stopPropagation()}
        className={`modal${additionalClassNames ? ` ${additionalClassNames}` : ''}`}
      >
        <div
          className={`modal-content${modalContentClassNames ? ` ${modalContentClassNames}` : ''}`}
        >
          <React.Fragment>
            <div className="modal-content-header">
              {title &&
              <h2 className="emphasized-content m-b-none">{title}</h2>
                }
              {showClose &&
                <button onClick={toggleModal} className="icon-close icon">
                  <X size={23} />
                </button>
                }
            </div>
            {title && divider &&
              <hr className={`divider top-divider${dividerClassNames ? ` ${dividerClassNames}` : ''}`} />
            }
          </React.Fragment>
          {content}
        </div>
      </div>
    </div>,
    document.getElementById('modal-root'));
};

export default Modal;

Modal.propTypes = {
  title: string,
  content: node,
  additionalClassNames: string,
  modalContentClassNames: string,
  toggleModal: func.isRequired,
  showClose: bool,
  divider: bool,
  dividerClassNames: string,
};

Modal.defaultProps = {
  title: '',
  additionalClassNames: '',
  modalContentClassNames: '',
  content: null,
  showClose: true,
  divider: true,
  dividerClassNames: '',
};
