import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Box, TextField } from '@mui/material';

import FormFieldLabel from '../FormFieldLabel';
import RedirectUriSelect from './components/RedirectUriSelect';
import { CardSelect } from '../../../../../../components/Form/components';

import staticText from '../../../../../../localization/Application/connect-playground';

const BasicSettings = ({
  applicationId,
  urlValues,
  appRedirectUris,
  handleChange,
  updateApplication,
  canWriteConnectConfig,
}) => {
  const { clientId, redirectUri, mode } = staticText.form.basic;

  return (
    <Box display="flex" flexDirection="column" maxWidth="500px">
      <FormFieldLabel label={clientId.label} description={clientId.description}>
        <TextField name="clientId" defaultValue={applicationId} disabled />
      </FormFieldLabel>
      <FormFieldLabel label={redirectUri.label} description={redirectUri.description}>
        <RedirectUriSelect
          applicationId={applicationId}
          selectedRedirectUri={urlValues.redirectUri}
          appRedirectUris={appRedirectUris}
          handleChange={({ param, value }) => handleChange({ param, value })}
          updateApplication={updateApplication}
          disableAddRedirectUri={!canWriteConnectConfig}
        />
      </FormFieldLabel>
      <FormFieldLabel label={mode.label}>
        <CardSelect
          inputName="mode"
          inputValue={urlValues.mode}
          handleChange={({ value }) => handleChange({ param: 'mode', value })}
          options={mode.options}
        />
      </FormFieldLabel>
    </Box>
  );
};

export default BasicSettings;

BasicSettings.propTypes = {
  applicationId: string.isRequired,
  urlValues: shape({
    mode: string.isRequired,
  }).isRequired,
  handleChange: func.isRequired,
  appRedirectUris: arrayOf(string).isRequired,
  updateApplication: func.isRequired,
  canWriteConnectConfig: bool.isRequired,
};

