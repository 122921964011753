import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: theme.border.boxShadow,
  borderRadius: theme.border.radius,
  marginTop: theme.spacing(-2),
  marginBottom: theme.spacing(-1),
}));

export const Badge = styled('div')(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '100%',
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '-12px',
  right: '-12px',

  p: {
    height: '21px',
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontSize: theme.typography.fontSizes.xs,
  textTransform: 'none',
}));

export const Value = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.monospace,
  fontSize: theme.typography.fontSizes.xs,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  backgroundColor: theme.palette.background,
  padding: '6px 6px 4px',
}));

export const Delete = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  padding: '5px',
  borderColor: theme.palette.gray,
  minWidth: '0',
  '& svg': {
    height: '24px',
    width: '24px',
    fill: theme.palette.gray,
  },
}));
