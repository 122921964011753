import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, Grid, styled, FormHelperText, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { AuthContentContainer, InlineLinks, InputField } from '../../../../components';
import PasswordRequirements from '../PasswordRequirements/PasswordRequirements';
import staticText from '../../../../localization/Authentication/signUp';
import authFormState, { AUTH_SEARCH_PARAMS, validatePostAuthRedirect } from '../authFormUtils';
import utils from '../../../utils';
import { googleAnalyticsClientId } from '../../../../services/auth/utils';
import HiddenUtmFields from './components/HiddenUtmFields/HiddenUtmFields';
import LoginButtons from '../Login/components/LoginButtons';

const FormFields = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: theme.spacing(2),
  marginTop: theme.spacing(2),

  '.MuiFormControl-root': {
    margin: '0',
    gridColumnStart: 'span 2',
  },

  '& > div:nth-of-type(1), & > div:nth-of-type(2)': {
    gridColumnStart: 'span 1',
  },
}));

const SignUp = ({
  actions: {
    createDashboardUser,
    resetErrors,
    setAccountType,
    setPlanRedirect,
    setPostAuthRedirect,
    triggerOauthFlowRequest,
    setPrefilledInvitedUsername,
    resetLoginPage,
    resetOauthInvitation,
  },
  inProgress,
  location,
  signUpFormErrors,
  triggerOauthFlow,
  authorizationUrl,
}) => {
  const [disableFields, setDisableFields] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

  const prefilledUsername = new URLSearchParams(location.search).get(AUTH_SEARCH_PARAMS.EMAIL);

  const {
    setErroredFields,
    erroredFields,
    handleFocus,
    handleChange,
    values,
  } = authFormState(
    utils.isEmail(prefilledUsername)
      ? { username: prefilledUsername }
      : undefined,
    setShowPasswordRequirements,
  );

  useEffect(() => {
    reportToSegment(types.PAGE, 'Sign Up');

    // sends the prefilledUsername to the backend for GoogleOAuth
    // when an user is accepting an invitation
    if (prefilledUsername) {
      setPrefilledInvitedUsername(prefilledUsername);
    }

    // Redirect the user to the workOs AuthKit on the backend
    // Triggers all Oauth flows (ie. create or login)
    if (triggerOauthFlow && authorizationUrl) {
      window.location.href = authorizationUrl;
    }
  }, [authorizationUrl]);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    setErroredFields([signUpFormErrors]);
  }, [inProgress, signUpFormErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    resetErrors();

    const anonymousId = window.analytics &&
       typeof window.analytics.user === 'function' && window.analytics.user().anonymousId();
    const gaClientId = googleAnalyticsClientId();

    const billingPlan = new URLSearchParams(location.search).get(AUTH_SEARCH_PARAMS.PLAN);
    if (billingPlan) {
      setPlanRedirect(billingPlan);
    }
    const postAuthRedirect = new URLSearchParams(location.search).get(AUTH_SEARCH_PARAMS.RETURN_TO);
    if (postAuthRedirect && validatePostAuthRedirect(postAuthRedirect)) {
      setPostAuthRedirect(postAuthRedirect);
    }
    setAccountType(values.accountType);
    createDashboardUser({
      ...values, anonymousId, gaClientId,
    });

    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'signup attempt',
      form_content: {
        firstName: values.firstName,
        lastName: values.lastName,
        accountType: values.accountType,
        username: values.username,
      },
    });
  };

  const getGeneralError = () => {
    let error;
    if (Array.isArray(erroredFields)) {
      error = erroredFields.find(err => !err.field || err.field === 'required_fields');
    } else {
      error = erroredFields;
    }

    return error || '';
  };

  const getErrorMessage = (field) => {
    let error;
    if (Array.isArray(erroredFields)) {
      error = erroredFields.find(err => err.field === field);
    }

    return error || '';
  };

  const togglePasswordVisibility = () => {
    setShowPassword(show => !show);
  };

  return (
    <AuthContentContainer
      subLink={staticText.subLink}
      resetErrors={resetErrors}
      resetLoginPage={resetLoginPage}
      resetOauthInvitation={resetOauthInvitation}
    >
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1">{staticText.title}</Typography>
          <LoginButtons
            disableFields={disableFields}
            userAuthFlow="signup"
            resetErrors={resetErrors}
            triggerOauthFlowRequest={triggerOauthFlowRequest}
          />
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-end"
              mt={2}
            >
              <Typography
                id="error-container"
                color="error"
                variant="caption"
                position="relative"
                top="10px"
                textAlign="right"
              >
                {getGeneralError()}
              </Typography>
              <FormFields>
                {staticText.fields.map(field => (field.type === 'select' ?
                (
                  <FormControl key={field.name} error={!!getErrorMessage(field.name)}>
                    <InputLabel id="account-type-select-label">{field.label}</InputLabel>
                    <Select
                      labelId="account-type-select-label"
                      id="account-type-select"
                      value={values[field.name] || ''}
                      label={field.label}
                      onChange={event => handleChange({
                        key: field.name,
                        value: event.target.value,
                      })}
                      disabled={disableFields}
                      required
                    >
                      {field.options.map(option => (
                        <MenuItem value={option} key={option}>{option}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {getErrorMessage(field.name)}
                    </FormHelperText>
                  </FormControl>
                ) : (
                  <InputField
                    key={field.name}
                    inputName={field.name}
                    // eslint-disable-next-line no-nested-ternary
                    inputType={field.type === 'password' ?
                      (!showPassword ? field.type : 'text') :
                      field.type}
                    inputValues={values[field.name]}
                    inputPlaceholder={field.label}
                    handleChange={handleChange}
                    handleFocus={handleFocus}
                    inputDisabled={Boolean(field.name === 'username' && prefilledUsername) || disableFields}
                    errorMessage={getErrorMessage(field.name)}
                    required
                    InputProps={
                      field.name === 'password'
                        ? {
                            endAdornment: (
                              <IconButton onClick={togglePasswordVisibility}>
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            ),
                          }
                        : {}
                    }
                  />
                )
              ))}
                <HiddenUtmFields />
              </FormFields>
              {showPasswordRequirements && (
              <Box mt={1} width="100%">
                <PasswordRequirements inputString={values.password} />
              </Box>
            )}
            </Box>
            <Box mt={3} mb={2} pr={6}>
              <InlineLinks text={staticText.terms} />
            </Box>
            <Button
              disabled={disableFields}
              type="submit"
              id="btnSubmit"
              variant="contained"
              size="large"
            >
              {staticText.btnSubmit}
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography variant="h2">{staticText.subTitle}</Typography>
          <Box mt={3} mb={3}>
            {staticText.bullets.map(bullet => (
              <Box key={bullet.key} mb={2} display="flex">
                <img
                  width={12}
                  height={12}
                  src="/assets/icons/min-check.svg"
                  alt="feature list"
                  className="m-r-sm"
                />
                {bullet.text}
              </Box>
            ))}
          </Box>
          <InlineLinks text={staticText.contactUs} />
          <Box textAlign="center">
            <img
              className="m-t-lg"
              src={staticText.image.url}
              alt={staticText.image.alt}
              width="75%"
            />
          </Box>
        </Grid>
      </Grid>
    </AuthContentContainer>
  );
};

export default SignUp;

SignUp.propTypes = {
  actions: shape({
    createDashboardUser: func.isRequired,
    resetErrors: func.isRequired,
    triggerOauthFlowRequest: func.isRequired,
    setAccountType: func.isRequired,
    setPlanRedirect: func.isRequired,
    setPostAuthRedirect: func.isRequired,
    resetOauthInvitation: func.isRequired,
  }).isRequired,
  inProgress: bool,
  triggerOauthFlow: bool.isRequired,
  authorizationUrl: string,
  location: shape({
    search: string,
  }).isRequired,
  signUpFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ),
};

SignUp.defaultProps = {
  signUpFormErrors: [],
  inProgress: false,
  authorizationUrl: null,
};
