import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  AuthContentContainer,
  InlineRoute,
  InputField,
} from '../../../../components';
import staticText from '../../../../localization/Authentication/login';
import { googleAnalyticsClientId } from '../../../../services/auth/utils';
import utils from '../../../utils';
import authFormState, {
  AUTH_SEARCH_PARAMS,
  validatePostAuthRedirect,
} from '../authFormUtils';
import LoginButtons from './components/LoginButtons';

const Login = ({
  actions: {
    getLoginProviderRequest,
    authenticateDeveloperRequest,
    resetErrors,
    setPostAuthRedirect,
    triggerOauthFlowRequest,
    setPrefilledInvitedUsername,
    resetOauthInvitation,
    mfaResetLogin,
  },
  inProgress,
  loginProvider,
  location,
  loginFormErrors,
  signUpFormErrors,
  authorizationUrl,
  triggerOauthFlow,
  automaticallyLoginViaOauth,
  loginProviderUsername,
}) => {
  const [disableFields, setDisableFields] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputFields, setInputFields] = useState([]);

  const getInputFields = () => (loginProvider === 'EmailPassword' ? staticText.fields : staticText.fields.filter(field => field.name === 'username'));


  const prefilledUsername = new URLSearchParams(location.search).get(
    AUTH_SEARCH_PARAMS.EMAIL,
  );

  const {
    setErroredFields,
    erroredFields,
    handleFocus,
    handleChange,
    values,
    getErrorMessage,
  } = authFormState(
    utils.isEmail(prefilledUsername)
      ? { username: prefilledUsername }
      : undefined,
  );

  const authenticateUser = () => {
    const anonymousId =
        window.analytics &&
        typeof window.analytics.user === 'function' &&
        window.analytics.user().anonymousId();
    const gaClientId = googleAnalyticsClientId();

    const postAuthRedirect = new URLSearchParams(location.search).get(
      AUTH_SEARCH_PARAMS.RETURN_TO,
    );

    if (postAuthRedirect && validatePostAuthRedirect(postAuthRedirect)) {
      setPostAuthRedirect(postAuthRedirect);
    }

    authenticateDeveloperRequest({
      ...values,
      anonymousId,
      gaClientId,
    });

    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'login attempt',
      form_content: { username: values.username },
    });
  };

  useEffect(() => {
    reportToSegment(types.PAGE, 'Login');

    // sends the prefilledUsername to the backend for GoogleOAuth
    // when an user is accepting an invitation
    if (prefilledUsername) {
      setPrefilledInvitedUsername(prefilledUsername);
    }

    // Redirect the user to the workOs AuthKit on the backend
    // Triggers all Oauth flows (ie. create or login)
    if (triggerOauthFlow && authorizationUrl) {
      window.location.href = authorizationUrl;
    }
  }, [authorizationUrl]);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    if (loginFormErrors && loginFormErrors.length > 0) {
      setErroredFields([loginFormErrors]);
    } else {
      setErroredFields([signUpFormErrors]);
    }
  }, [inProgress, loginFormErrors, signUpFormErrors]);

  useEffect(() => {
    setInputFields(getInputFields());
  }, [loginProvider]);

  useEffect(() => {
    // only authenticate if the password field is filled
    const hasPassword = values.password && values.password.length;
    if (loginProviderUsername && hasPassword) {
      authenticateUser();
    }
  }, [loginProviderUsername]);


  useEffect(() => {
    mfaResetLogin();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    resetErrors();

    // call getLoginProviderRequest to check username and factorId
    getLoginProviderRequest(values.username);
  };

  const getGeneralError = () => {
    let error;
    if (Array.isArray(erroredFields)) {
      error = erroredFields.find(err => !err.field || err.field === 'required_fields');
    } else {
      error = erroredFields;
    }

    return error || '';
  };

  const togglePasswordVisibility = () => {
    /* istanbul ignore next */
    setShowPassword(show => !show);
  };

  return (
    <AuthContentContainer
      subLink={staticText.subLink}
      resetErrors={resetErrors}
      resetOauthInvitation={resetOauthInvitation}
    >
      <Grid container columnSpacing={18}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1">{staticText.title}</Typography>
          <LoginButtons
            disableFields={disableFields}
            userAuthFlow="login"
            resetErrors={resetErrors}
            triggerOauthFlowRequest={triggerOauthFlowRequest}
            loginProvider={loginProvider}
            automaticallyLoginViaOauth={automaticallyLoginViaOauth}
            loginProviderUsername={loginProviderUsername}
          />
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-end"
              mt={2}
            >
              <Typography
                id="error-container"
                color="error"
                variant="caption"
                position="relative"
                top="10px"
                textAlign="right"
              >
                {getGeneralError()}
              </Typography>
              {inputFields.map(field => (
                <InputField
                  key={field.name}
                  inputName={field.name}
                  // eslint-disable-next-line no-nested-ternary
                  inputType={field.type === 'password' ?
                    (!showPassword ? field.type : 'text') :
                    field.type}
                  inputValues={values[field.name]}
                  inputPlaceholder={field.label}
                  handleChange={handleChange}
                  handleFocus={handleFocus}
                  inputDisabled={
                    Boolean(field.name === 'username' && prefilledUsername) ||
                    disableFields
                  }
                  errorMessage={getErrorMessage(field.name)}
                  InputProps={
                    field.name === 'password'
                      ? {
                          endAdornment: (
                            <IconButton onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }
                      : {}
                  }
                />
              ))}
            </Box>
            {loginProvider === 'EmailPassword' && (
              <Box mt={1}>
                <InlineRoute text={staticText.forgotPassword} />
              </Box>
            )}
            <Box mt={3}>
              <Button
                id="btnLoginIn"
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                disabled={disableFields}
              >
                {staticText.btnSubmit}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          <img
            src={staticText.image.url}
            alt={staticText.image.alt}
            width="100%"
          />
        </Grid>
      </Grid>
    </AuthContentContainer>
  );
};

export default Login;

Login.propTypes = {
  actions: shape({
    getLoginProviderRequest: func.isRequired,
    authenticateDeveloperRequest: func.isRequired,
    triggerOauthFlowRequest: func.isRequired,
    resetErrors: func.isRequired,
    setPostAuthRedirect: func.isRequired,
    setPrefilledInvitedUsername: func.isRequired,
    resetOauthInvitation: func.isRequired,
    mfaResetLogin: func.isRequired,
  }).isRequired,
  loginProvider: string,
  authorizationUrl: string,
  triggerOauthFlow: bool.isRequired,
  inProgress: bool,
  location: shape({
    search: string,
  }).isRequired,
  loginFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }),
  ),
  signUpFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }),
  ),
  automaticallyLoginViaOauth: bool.isRequired,
  loginProviderUsername: string,
};

Login.defaultProps = {
  loginFormErrors: [],
  signUpFormErrors: [],
  inProgress: false,
  loginProvider: 'GoogleOAuth',
  authorizationUrl: null,
  loginProviderUsername: null,
};
