const staticText = {
  categories: [
    {
      title: 'Connected vehicles',
      sections: [
        {
          features: [
            {
              title: 'Vehicles',
              description: 'Pricing is based on the number of connected vehicles',
              free: '1',
              build: 'Up to 100',
              scale: 'Custom',
              enterprise: 'Custom',
            },
            {
              title: 'Simulated vehicles',
              free: 'Up to 5',
              build: 'Up to 10',
              scale: 'Custom',
              enterprise: 'Custom',
            },
            {
              title: 'API calls',
              free: '500 / vehicle / month',
              build: '1,000 / vehicle / month',
              scale: 'Custom',
              enterprise: 'Custom',
            },
          ],
        },
      ],
    },
    {
      title: 'Platform',
      sections: [
        {
          title: 'APIs',
          features: [
            {
              title: 'API SDKs',
              description: 'We offer API SDKs for Go, Java, Node.js, Python, and Ruby',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Standard endpoints',
              description: 'Retrieve standard vehicle data and trigger standard actions through API requests',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Batch requests',
              description: 'Make requests to multiple API endpoints with a single request',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Brand-specific endpoints',
              description: 'Allow developers to access even more information from vehicles if that information is available',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Compatibility API',
              description: "Based on VIN or region and brand, verify a vehicle's eligibility before directing a user through Smartcar Connect",
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
          ],
        },
        {
          title: 'Connect',
          features: [
            {
              title: 'Connect SDKs',
              description: 'We offer Connect SDKs for Android, iOS, JavaScript, and Flutter',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Consent management',
              description: 'Smartcar Connect lets your collect user consent in just a few clicks',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Easy password reset',
              description: 'Incorporate a user-friendly password reset flow into your application for each car brand',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Connect insights',
              description: 'Analyze user interactions at key stages of your Connect flow to easily troubleshoot issues by examining individual sessions',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Brand filter by engine type',
              description: 'Customize the vehicle brands you want to display in Connect through the Dashboard',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Appearance customization (logo and app name)',
              description: 'Provide a seamless user experience by adding your own logo to Connect',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Appearance customization (display theme)',
              description: 'Provide a seamless user experience by customizing the display theme in Connect',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Require app permissions',
              description: 'Allow vehicles to be compatible with required permission in Connect',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Control new brand releases',
              description: '',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Advanced brand controls',
              description: '',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Conversion funnel',
              description: 'Gain visibility into conversion rates within your Connect flow to pinpoint user drop-off',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Country filter',
              description: 'Customize the countries you want to display in Connect through the Dashboard',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            // {
            //   title: 'Single select',
            //   description: 'Allow vehicle owners to only select one vehicle through
            //    Smartcar Connect',
            //   free: false,
            //   build: false,
            //   scale: false,
            //   enterprise: true,
            // },
            // {
            //   title: 'Brand select',
            //   description: 'Allow vehicle owners to only select from only one brand through
            //    Smartcar Connect',
            //   free: false,
            //   build: false,
            //   scale: false,
            //   enterprise: true,
            // },
          ],
        },
        {
          title: 'Dashboard',
          features: [
            {
              title: 'SSO with Google',
              description: '',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: '2FA',
              description: '',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Analytics',
              description: 'Visualize your vehicle integration data through interactive charts and graphs',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Vehicle list',
              description: 'Browse, sort, and filter a detailed log of endpoint requests, status codes, and errors by vehicle',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Teams',
              description: 'Invite others to collaborate and help you get your application up and running faster',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Team roles',
              description: 'Ability to invite users to an application as an admin, editor, or viewer',
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
            {
              title: 'Vehicle request logs',
              description: 'Browse, sort, and filter a detailed log of endpoint requests, status codes, and errors by vehicle',
              free: '1 day',
              build: '7 days',
              scale: '30 days',
              enterprise: '365 days',
            },
            {
              title: 'API request logs',
              description: 'Analyze the types of requests, total number of requests, and error rates for each vehicle',
              free: '1 day',
              build: '7 days',
              scale: '30 days',
              enterprise: '365 days',
            },
            {
              title: 'Logs filter',
              description: '',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Logs export',
              description: '',
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
            {
              title: 'Scheduled webhooks',
              description: 'Create and manage scheduled webhooks',
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
            {
              title: 'Number of apps',
              description: '',
              free: '1',
              build: '2',
              scale: '5',
              enterprise: 'Custom',
            },
          ],
        },
      ],
    },
    {
      title: 'Compliance',
      sections: [
        {
          features: [
            {
              title: 'SOC 2 Type 2',
              description: 'Smartcar is SOC 2 Type 2 compliant. SOC 2 Type 2 is an independent audit report which details information and assurance about Smartcar’s controls. ',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'ISO 27001 & ISO 27701',
              description: 'Smartcar is ISO 27001 & ISO 27701 compliant, ensuring top-tier information security and privacy management',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'GDPR',
              description: '',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Annual penetration testing',
              description: 'Each year, Smartcar undergoes annual penetration testing (pen test), designed to expose flaws in our security system and check for potential vulnerabilities that may be exploited during a cyberattack',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Security assessment',
              description: 'Smartcar will answer compliance questionnaires',
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
            {
              title: 'MSA',
              description: '',
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
            {
              title: 'SLAs',
              description: '',
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
          ]
          ,
        },
      ],
    },
    {
      title: 'Support',
      sections: [
        {
          features: [
            {
              title: 'Email',
              description: 'Please send support requests to support@smartcar.com',
              free: true,
              build: true,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Chat',
              description: '',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Managed onboarding',
              description: 'Dedicated Solution Architect during the first 90 days. We will help your team understand, scope, and implement Smartcar API',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Strategic account manager',
              description: 'Our team can assist with RFPs, support go-to-market initiatives, conduct quarterly business reviews, and more',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Priority response',
              description: 'Displayed hours represent the average first response time on weekdays: 12 AM - 5 PM PST (8 AM - 1 AM GMT)',
              free: false,
              build: false,
              scale: '24 hours',
              enterprise: '4 hours',
            },
            {
              title: 'Shared Slack',
              description: '',
              free: false,
              build: false,
              scale: false,
              enterprise: true,
            },
          ]
          ,
        },
      ],
    },
    {
      title: 'Procurement',
      sections: [
        {
          features: [
            {
              title: 'Multiple payment methods',
              description: '',
              free: true,
              build: true,
              scale: false,
              enterprise: true,
            },
            {
              title: 'Invoicing',
              description: '',
              free: false,
              build: false,
              scale: true,
              enterprise: true,
            },
            {
              title: 'Currency support',
              description: '',
              free: 'USD',
              build: 'USD',
              scale: 'USD, GBP, EUR',
              enterprise: 'USD, GBP, EUR',
            },
          ]
          ,
        },
      ],
    }],
};

export default staticText;
