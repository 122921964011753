import React from 'react';
import { element, func, shape, string } from 'prop-types';

import { Modal } from '../../../../../../components';
import WebhooksForm from '../WebhooksForm';
import { modalConfig } from '../../utils/webhookModalHelpers';

const WebhooksModal = ({
  actions: { toggleWebhookModal },
  mode,
  upgradeCta,
  upgradeMessage,
}) => {
  const modalName = mode;
  const content = (
    <WebhooksForm
      mode={mode}
      upgradeCta={upgradeCta}
      upgradeMessage={upgradeMessage}
    />
  );

  return (
    <Modal
      title={modalConfig[modalName].title}
      content={content}
      toggleModal={() =>
        toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent: true })
      }
      divider={false}
    />
  );
};

export default WebhooksModal;

WebhooksModal.propTypes = {
  actions: shape({
    toggleWebhookModal: func.isRequired,
  }).isRequired,
  mode: string.isRequired,
  upgradeCta: element,
  upgradeMessage: element,
};

WebhooksModal.defaultProps = {
  upgradeCta: null,
  upgradeMessage: null,
};
