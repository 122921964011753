import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Divider, Grid } from '@mui/material';

import { Checkbox, PermissionGate } from '../../../../../../../../components';
import staticText from '../../../../../../../../localization/Application/connect-config';
import { CustomizationOptionLabel } from '../../../../styles';

const RegionList = ({
  region,
  countries,
  selectedCountries,
  updateSelectedCountries,
  selectCountriesIsGated,
}) => {
  const regionDisplayName = region === 'North America' ? 'Americas' : region;

  const handleChange = ({ target: { name, checked } }) => {
    const updatedCountries = [...selectedCountries];
    if (checked) {
      updatedCountries.push(name);
    } else {
      updatedCountries.splice(updatedCountries.indexOf(name), 1);
    }
    updateSelectedCountries(updatedCountries);
  };

  const selectOrClearAll = ({ target: { checked } }) => {
    const updatedCountries = [...selectedCountries];
    if (checked) {
      countries.forEach((country) => {
        if (country.region === region && !updatedCountries.includes(country.code)) {
          updatedCountries.push(country.code);
        }
      });
    } else {
      countries.forEach((country) => {
        if (country.region === region) {
          const index = updatedCountries.indexOf(country.code);
          if (index !== -1) {
            updatedCountries.splice(index, 1);
          }
        }
      });
    }

    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[connect config - countries] ${region} - Select all`,
    });
    updateSelectedCountries(updatedCountries);
  };

  const countryNameEdit = (country) => {
    if (country === 'Bosnia and Herzegovina') return 'Bosnia and Herz...';
    return country;
  };

    // isAllSelected & isSomeSelected needed to determine Select All checkbox icon
  const updateSelectAllCheckbox = () => {
    const allSelected = countries.filter((country) => {
      if (selectedCountries.includes(country.code)) {
        return true;
      }
      return false;
    });

    const someSelected = countries.filter((country) => {
      if (selectedCountries.includes(country.code)) {
        return true;
      }
      return false;
    });

    return {
      isAllSelected: allSelected.length === countries.length,
      isSomeSelected: someSelected.length > 0,
    };
  };

  const {
    isAllSelected,
    isSomeSelected,
  } = updateSelectAllCheckbox();

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        marginBottom={2}
        marginTop={3}
      >
        <CustomizationOptionLabel>{regionDisplayName}</CustomizationOptionLabel>
        <PermissionGate dashboardPermission="write_connect_configuration" disabled>
          <Checkbox
            inputName={region}
            text={staticText.toggleButton}
            labelVariant="bold"
            handleChange={selectOrClearAll}
            checked={isAllSelected && isSomeSelected}
            partiallySelected={isSomeSelected}
            wrapperClassName="bold"
            disabled={selectCountriesIsGated}
          />
        </PermissionGate>
      </Box>
      <Divider sx={{ marginBottom: 1 }} />
      <div>
        <Grid
          container
          spacing={2}
          sx={{
            gridTemplateColumns: 'repeat(2, 1fr)', textAlign: 'left', textWrap: 'nowrap',
          }}
        >
          {countries.map(country => (
            <Grid
              key={country.code}
              item
              xs={6}
              sx={{
              display: 'flex', alignItems: 'flex-start', fontSize: '14px', marginTop: '10px',
              }}
            >
              <PermissionGate dashboardPermission="write_connect_configuration" disabled>
                <Checkbox
                  inputName={country.code}
                  text={countryNameEdit(country.displayName)}
                  handleChange={handleChange}
                  checked={selectedCountries.includes(country.code)}
                  icon={`/assets/icons/countries/${country.code}.svg`}
                  disabled={selectCountriesIsGated}
                />
              </PermissionGate>
            </Grid>
          ))}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default RegionList;

RegionList.propTypes = {
  region: string.isRequired,
  countries: arrayOf(shape({
    code: string.isRequired,
    region: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  selectedCountries: arrayOf(string).isRequired,
  updateSelectedCountries: func.isRequired,
  selectCountriesIsGated: bool.isRequired,
};
