import React, { useEffect, useState } from 'react';
import { func, bool, shape, string } from 'prop-types';
import moment from 'moment-timezone';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';

import { Spinner, Toast, UpsellModal } from '../../../../../../../../../../components';
import { staticText } from '../../../../../../../../../../localization/Application/Logs/filtersBar';
import FEATURES from '../../../../../../../../../../services/featureGate/features';
import { gatedFeatureData } from '../../../../../../../../../../services/featureGate';

/* istanbul ignore file */
const ExportButton = ({
  actions: {
    fetchExportData,
    setLogExportTriggered,
  },
  logExportTriggered,
  exportData,
  featureSetId,
  isFetchingExportData,
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState();
  const theme = useTheme();

  const isGated = gatedFeatureData(FEATURES.LOG_EXPORT, featureSetId);

  const toggleModal = () => {
    reportToSegment(types.TRACK, eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`], staticText.upgradeModal);
    setModalOpen(!modalOpen);
  };

  const handleExport = () => {
    if (isGated) {
      toggleModal();
    } else {
      reportToSegment(types.TRACK, eventNames.buttonClicked, {
        label: 'download',
        text: staticText.export,
      });
      fetchExportData();
      Toast(staticText.exportStarted, 'info');
    }
  };

  const downloadFile = () => {
    if (logExportTriggered) {
      const url = window.URL.createObjectURL(new Blob([exportData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${moment().format('YYYY-MM-DD')} requests logs.csv`);
      link.click();
      window.URL.revokeObjectURL(url);
      setLogExportTriggered(false);
    }
  };

  useEffect(() => {
    downloadFile();
  }, [exportData]);

  return (
    <div>
      {modalOpen && (
        <UpsellModal
          toggleModal={toggleModal}
          feature={FEATURES.LOG_EXPORT}
        />
      )}
      <Button
        onClick={handleExport}
        variant="outlined"
        id="data-export"
        endIcon={isFetchingExportData
          ? <Spinner size="xs" spinnerColor={theme.palette.grey[200]} additionalClassNames="export-button" />
          : <IosShareRoundedIcon color={theme.palette.primary.main} fontSize="small" />}
        disabled={isFetchingExportData || disabled}
        sx={{ height: '42px', marginLeft: 2 }}
      >
        {staticText.export}
      </Button>
    </div>
  );
};


export default ExportButton;

ExportButton.propTypes = {
  actions: shape({
    fetchExportData: func.isRequired,
    setLogExportTriggered: func.isRequired,
  }).isRequired,
  featureSetId: string.isRequired,
  logExportTriggered: bool.isRequired,
  exportData: string.isRequired,
  isFetchingExportData: bool.isRequired,
  disabled: bool.isRequired,
};
