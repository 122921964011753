import React from 'react';
import { element, shape, string } from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { WebhooksStatusRow } from './components';
import staticText from '../../../../../../localization/Application/webhooks';
import { TYPES } from '../../utils/webhookTypes';
import formFields from '../WebhooksForm/fields';

const { isVehicleHealthEvent } = formFields;

const WebhooksStatus = ({ webhooks, upgradeMessage }) => {
  const { eventBasedWebhooksEnabled, dynamicWebhooksEnabled } = useFlags();
  const disabledByFlag = (webhook) => {
    if (webhook.type === TYPES.dynamic.name && !dynamicWebhooksEnabled) {
      return true;
    }
    if (
      // eventBasedWebhooksEnabled refers to the Ev Charging subcategory of eventBased
      webhook.type === TYPES.eventBased.name
      && !isVehicleHealthEvent(webhook.events[0])
      && !eventBasedWebhooksEnabled
    ) {
      return true;
    }
    return false;
  };
  return (
    <React.Fragment>
      {upgradeMessage}
      <TableContainer sx={{ mb: 4 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '150px' }}>
                <Typography variant="h3">{staticText.webhooksTable.headers.status}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h3">{staticText.webhooksTable.headers.name}</Typography>
              </TableCell>
              <TableCell sx={{ width: '300px' }}>
                <Typography variant="h3">{staticText.webhooksTable.headers.actions}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(webhooks).map(webhook => (
              <WebhooksStatusRow
                key={webhook.id}
                webhook={webhook}
                disabled={!!upgradeMessage || disabledByFlag(webhook)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default WebhooksStatus;

WebhooksStatus.propTypes = {
  webhooks: shape({
    disabledReason: string,
    name: string,
    id: string,
    status: string,
  }).isRequired,
  upgradeMessage: element,
};

WebhooksStatus.defaultProps = {
  upgradeMessage: null,
};
