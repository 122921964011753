/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { LogTable, Spinner, PermissionGate } from '../../../../../../../../components';
import { menuItems } from '../../../../../../../../localization/Application/VehicleManagement/webhooksTable';
import { Section } from '../../styles';
import NoResultsFound from '../NoResultsFound';
import { useDelay } from '../../../../../../../../hooks';
import UnsubscribeModal from '../UnsubscribeModal';

const WebhooksOverview = ({
  webhooksInfo,
  isFetchingWebhooksInfo,
  applicationId,
  unsubscribeVehicleFromWebhook,
}) => {
  const [currentModal, setCurrentModal] = useState(null);
  const { afterDelay: showLoading, resetTimer } = useDelay();

  const handleUnsubscribe = (webhookId) => {
    unsubscribeVehicleFromWebhook(webhookId);
    resetTimer();
  };

  const openUnsubcribeModal = (webhook) => {
    setCurrentModal(
      <PermissionGate
        dashboardPermission="write_vehicle_management"
        disabled
      >
        <UnsubscribeModal
          closeModal={() => setCurrentModal(null)}
          unsubscribeVehicleFromWebhook={handleUnsubscribe}
          webhook={webhook}
        />
      </PermissionGate>,
    );
  };

  const moreActions = {
    menuItems,
    actionMap: {
      unsubscribe: openUnsubcribeModal,
    },
  };

  return (
    <React.Fragment>
      {currentModal}
      {
        !webhooksInfo.rows &&
        isFetchingWebhooksInfo &&
          <Spinner size="small" additionalClassNames="vehicle-overview" delay={200} />
      }
      {
        webhooksInfo.rows &&
        webhooksInfo.rows.length > 0 && (
          <LogTable
            getRowId={row => row.webhookId}
            pageInfo={webhooksInfo}
            pageSizeOptions={[10]}
            paginationModel={{
            page: 0,
            pageSize: 10,
          }}
            handlePaginationModelChange={() => {}}
            loading={showLoading && isFetchingWebhooksInfo}
            moreActions={moreActions}
            hideFooter
          />
        )
      }
      {
        webhooksInfo.rows &&
        webhooksInfo.rows.length === 0 &&
          <Section>
            <NoResultsFound
              noWebhooksFound
              applicationId={applicationId}
            />
          </Section>
      }
    </React.Fragment>
  );
};

export default WebhooksOverview;

WebhooksOverview.propTypes = {
  webhooksInfo: shape({
    rows: array,
    columns: array,
    rowCount: number,
  }).isRequired,
  isFetchingWebhooksInfo: bool.isRequired,
  applicationId: string.isRequired,
  unsubscribeVehicleFromWebhook: func.isRequired,
};
