const staticText = {
  heading: 'Billing',
  pageDescription: 'Manage your Smartcar plan and payments',
  tabs: {
    free: [
      'All plans',
      'My plan',
      // 'Add-ons', // when uncommented, the add-on tab will be rendered
    ],
    build: [
      'All plans',
      'Billing information',
      'My plan',
      // 'Add-ons',
    ],
    scale: [
      'All plans',
      'Billing information',
      'My plan',
      // 'Add-ons',
    ],
    salesLed: [
      'Overview',
      'My plan',
      // 'Add-ons',
    ],
  },
  freePlanName: 'free',
  buildPlanName: 'build',
  buildPlanAdditionalVehicleCost: 7,
  scalePlanName: 'scale',
  scalePlanAdditionalVehicleCost: 6,
  enterprisePlanName: 'enterprise',
  salesLedPlanName: 'salesLed',
  businessPlanName: 'business',
  activeVehicleLimitExceededFree: 'exceeding your active vehicles limit. ',
  activeVehicleLimitExceededPaid: vehiclesOverBase =>
    `You will be charged for ${vehiclesOverBase} extra ${vehiclesOverBase > 1 ? 'vehicles' : 'vehicle'} this month at the rate specified in your contract. `,
  singleVehicle: 'vehicle is',
  pluralVehicles: 'vehicles are',
  contactUs: 'Please contact us to upgrade your plan.',
  updateBillingInformation: 'Update your billing information',
  seePastInvoices:
    'For questions about billing and to see past invoices, please |+|reach out@@mailto:finance@smartcar.com|+| to our finance team.',
  planDowngraded: (date, planName, futurePlan) =>
    `You have downgraded your billing plan to ${futurePlan}. Your access to ${planName} plan features will end on ${date}.`,
  noPermissionToAccessPage: 'You don\'t have the permissions to access the billing page. Please contact a team owner.',
  isUpdatingBillingInfo: 'Processing, please hold...',
  billingInfoError: 'There was an issue retrieving your billing information. Please log in again. For ongoing issues, please contact',
  missingStripeCredentialsError: "We're missing important information about your team for billing purposes. To avoid service interruption, please contact",
  updateBillingInfoError: 'There was an issue updating your plan. Please try again. For ongoing issues please contact',
  paymentError: 'We had trouble with your payment',
  paymentErrorDescription: 'Your bill is overdue and we have not yet received your payment.  Please update your billing information and submit payment.',
  paymentErrorFeedback: 'We were unable to process your payment for your last invoice. Please correct the issue to avoid service interruption.',
};

export default staticText;
