import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Typography, useTheme } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { Selector, AppNameButton, AppName, SelectorArrow, AppListContainer } from '../AppSelector/styles';
import OrgSelectorList from './components/OrgSelectorList';
import useToggleMenu from '../../useToggleMenu';

const OrgSelector = ({
  organization,
  organizations,
  selectOrganization,
}) => {
  const theme = useTheme();

  const {
    menuOpen,
    closeMenu,
    toggleMenu,
    menuRef,
  } = useToggleMenu({
    label: 'org selector',
    text: organization.name,
  });

  return (
    <div>
      {Object.keys(organizations).length > 1 ? (
        <Selector
          id="org-selector-wrapper"
          ref={menuRef}
          onClick={e => e.stopPropagation()}
          open={menuOpen}
        >
          <AppNameButton id="org-selector-button" onClick={toggleMenu}>
            <Typography variant="bold">{organization.name}</Typography>
            <SelectorArrow listOpen={menuOpen}>
              <ExpandMoreRoundedIcon htmlColor={theme.palette.primary.main} />
            </SelectorArrow>
          </AppNameButton>
          {menuOpen &&
          <AppListContainer>
            <OrgSelectorList
              orgId={organization.id}
              orgName={organization.name}
              organizations={organizations}
              selectOrganization={selectOrganization}
              closeList={closeMenu}
            />
          </AppListContainer>
        }
        </Selector>
      ) : (
        <AppName id="org-name">
          <Typography variant="bold">{organization.name}</Typography>
        </AppName>
      )}
    </div>
  );
};

export default OrgSelector;

OrgSelector.propTypes = {
  organizations: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  organization: shape({
    id: string.isRequired,
    name: string.isRequired,
    featureSetId: string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectOrganization: func.isRequired,
};
