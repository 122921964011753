import { Box } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { DemoLink } from '../../../../components';
import { topbarNav } from '../../../../localization/Application/nav';
import { capitalizeFirstLetter } from '../../utils';
import { HoverText } from '../SidebarNav/styles';
import { AccountMenu, AppSelector, OrgSelector } from './components';
import { Divider, Logo, Nav, TopNavLinks, WarningDot } from './styles';

const TopbarNav = ({
  actions: {
    logoutRequest,
    selectOrganization,
  },
  organizations,
  organization,
  pathname,
  orgApps,
  selectedApplication,
  userContext,
  billingInfo,
  view,
}) => {
  const application = orgApps[selectedApplication];

  const hideAppSelector = view !== 'apps';
  const { settings, help, search } = topbarNav;

  const userDisplayName = `${userContext.firstName} ${userContext.lastName}`;

  const handleLinkClick = (e, path, text) => {
    reportToSegment(types.TRACK, eventNames.linkClicked, { text, path });
  };

  const billingWarning = Boolean(billingInfo.vehiclesOverLimit) ||
    Boolean(billingInfo.numOfApiViolationVehicles) ||
    Boolean(billingInfo.delinquent);

  const actionMap = {
    openHelp: () => window.CommandBar.openHelpHub(),
    openSearch: () => window.CommandBar.open(),
  };

  return (
    <React.Fragment>
      <Nav aria-label="primary">
        <Box display="flex">
          <Logo id="home-link" href="/" onClick={e => handleLinkClick(e, '/', 'dashboard home')}>
            <img src="/assets/icons/nav/steeringWheel.svg" alt="Smartcar Dashboard" />
          </Logo>
          <Divider />
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <OrgSelector
                organization={organization}
                organizations={organizations}
                selectOrganization={selectOrganization}
              />
            </Box>
            {!hideAppSelector && (
              <React.Fragment>
                <Divider />
                <AppSelector
                  organization={organization}
                  pathname={pathname}
                  orgApps={orgApps}
                  applicationName={application.name}
                  applicationId={application.id}
                  applicationLogoUrl={application.logoUrl}
                />
              </React.Fragment>
            )}
          </Box>
        </Box>
        <TopNavLinks>
          <DemoLink
            dashboardUserId={userContext.dashboardUserId}
            organizationId={organization.id}
          />
          {window.CommandBar && [search, help].map(cta => (
            <li>
              <button id={`nav-${cta.text}`} type="button" onClick={actionMap[cta.action]}>
                <img src={cta.iconSrc} alt={cta.text} />
                <HoverText className="hover-text" location="topbar">{capitalizeFirstLetter(cta.text)}</HoverText>
              </button>
            </li>
          ))}
          <li>
            <Link
              id="team-settings"
              to={settings.path}
              onClick={e => handleLinkClick(e, settings.path, settings.text)}
            >
              <img src={settings.iconSrc} alt={settings.text} />
              <HoverText className="hover-text" location="topbar">
                {capitalizeFirstLetter(settings.text)}
              </HoverText>
              {billingWarning && view === 'apps' && <WarningDot />}
            </Link>
          </li>
          <Divider />
          <AccountMenu
            userDisplayName={userDisplayName}
            userEmail={userContext.email}
            logoutRequest={logoutRequest}
          />
        </TopNavLinks>
      </Nav>
    </React.Fragment>
  );
};

export default TopbarNav;

TopbarNav.propTypes = {
  actions: shape({
    logoutRequest: func.isRequired,
  }).isRequired,
  organization: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: arrayOf(object).isRequired,
  selectedApplication: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orgApps: object.isRequired,
  pathname: string.isRequired,
  userContext: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
  }).isRequired,
  billingInfo: shape({
    vehiclesOverLimit: number.isRequired,
    numOfApiViolationVehicles: number,
  }).isRequired,
  view: string.isRequired,
};
