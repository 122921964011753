import { styled } from '@mui/material';
import { node, oneOf } from 'prop-types';
import React, { useState, useEffect } from 'react';

const SlideIn = styled('div', {
  shouldForwardProp: prop => prop !== 'offset',
})(({ offset }) => ({
  transform: offset,
  transition: 'transform 0.25s ease',
  display: 'flex',

  '&.visible': {
    transform: 'translate(0)',
  },
}));

function SlideInWrapper({ slideInFrom, children }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  let offset = '';
  switch (slideInFrom) {
    case 'right':
      offset = 'translateX(100%)';
      break;
    case 'left':
      offset = 'translateX(-100%)';
      break;
    case 'top':
      offset = 'translateY(100%)';
      break;
    case 'bottom':
      offset = 'translateY(-100%)';
      break;
    default:
      offset = 'translateX(100%)';
  }

  return (
    <SlideIn id="slide-in-right" offset={offset} className={`${isVisible ? 'visible' : ''}`}>
      {children}
    </SlideIn>
  );
}

export default SlideInWrapper;

SlideInWrapper.propTypes = {
  slideInFrom: oneOf(['right', 'left', 'top', 'bottom']),
  children: node.isRequired,
};

SlideInWrapper.defaultProps = {
  slideInFrom: 'right',
};
