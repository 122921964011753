import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Configuration from './Configuration';
import { actions as applicationsActions } from '../../../../services/applications/reducers';
import { actions as secretActions } from '../../../../components/Credentials/components/Secret/reducers';

function mapStateToProps(state) {
  return {
    ...state.secret,
    ...state.applications,
    selectedOrganization: state.organizations.selectedOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    updateApplication,
    deleteApplication,
    regenerateManagementToken,
    hideManagementToken,
  } = applicationsActions;
  const { hideSecret } = secretActions;

  return {
    actions: bindActionCreators({
      updateApplication,
      deleteApplication,
      hideSecret,
      regenerateManagementToken,
      hideManagementToken,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
