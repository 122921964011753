import { get } from 'lodash';
import appConfig from 'appConfig';

const baseUrl = get(appConfig, 'connectBaseURL');
const urlBase = `${baseUrl}/oauth/authorize?`;

// Note: the 'country' param will need to be updated to remove the 'flags'
// prefix when Connect params are updated
const getConnectUrl = (urlValues) => {
  const {
    clientId, redirectUri, scope, mode, state, user, make, country, singleSelect,
  } = urlValues;
  return `${urlBase}response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope.join('%20')}&mode=${mode}${state ? `&state=${state}` : ''}${user ? `&user=${user}` : ''}${(make && make !== 'none') ? `&make=${make}` : ''}${(country && country !== 'none') ? `&flags=country:${country}` : ''}${singleSelect ? '&single_select=true' : ''}`;
};

export { urlBase, getConnectUrl };
